import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    permisos: []
  },
  mutations: {
    set_accion_permisos (state, permisos) {
      state.permisos = permisos
    }
  },
  actions: {
    async getPermisos ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.hostAws}/abac-service/api/abac/permission/app/user?usuario=${usuario}&idApp=2`
        })
        if (response.status === 200) {
          console.log('success stuff api getPermisos')
          console.log(response.data)
          commit('set_accion_permisos', response.data)
          return true
        } else {
          console.log('por else getPermisos')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getPermisos')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
