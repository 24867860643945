import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EditarCamiones',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {},
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: 'Conductor' }
      ],
      selectedCliente: null,
      optionsCliente: [
        { value: null, text: 'Cliente' }
        // { value: 'Andina', text: 'Andina' },
        // { value: 'Sopraval', text: 'Sopraval' },
        // { value: 'Soprole', text: 'Soprole' },
        // { value: 'Andina Acarreo', text: 'Andina Acarreo' }
      ],
      selectedAdministrador: null,
      optionsAdministrador: [
        { value: null, text: 'Administrador' }
      ],
      emitEditCamion: false
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    console.log(this.dataJSON)
    for (const conductor in this.conductores) {
      this.optionsConductor.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
    }
    this.optionsConductor.sort((a, b) => a.text.localeCompare(b.text))
    for (const administrador in this.administradores) {
      this.optionsAdministrador.push({ value: this.administradores[administrador].nombre, text: this.administradores[administrador].nombre })
    }
    this.optionsAdministrador.sort((a, b) => a.text.localeCompare(b.text))
    this.getClientes().then((res) => {
      // console.log(this.clientes)
      this.clientes.forEach(element => this.optionsCliente.push({
        value: element.nombre,
        text: element.nombre
      }))
    })
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones', 'updateAdminCamion'
    ]),
    ...mapActions('Cliente', [
      'insertClientes', 'deleteClientesById', 'getClientes'
    ]),
    Editar () {
      let flagActualizacion = true
      const datos = {}
      datos.conductor = this.selectedConductor
      datos.cliente = this.dataJSON.cliente
      datos.camion = this.dataJSON.camion
      if (this.selectedConductor === null || this.selectedConductor === this.dataJSON.conductor) {
        console.log('conductor es null o ya esta asignado')
      } else {
        flagActualizacion = false
        console.log(this.selectedConductor)
        const datosCamion = {}
        datosCamion.camion = datos.camion
        datosCamion.conductor = this.selectedConductor
        console.log(datosCamion)
        this.updateConductorByCamion(datosCamion).then((res) => {
          console.log('cargo updateConductorByCamion')
          console.log(res)
          this.$emit('emitEditCamion', datos)
        })
      }
      if (this.selectedCliente === null || this.selectedCliente === this.dataJSON.cliente) {
        console.log('cliente es null o ya esta asignado')
      } else {
        const datosCamion = {}
        datosCamion.camion = datos.camion
        datosCamion.cliente = this.selectedCliente
        if (flagActualizacion) {
          if (this.selectedConductor !== null) {
            datosCamion.conductor = this.selectedConductor
          } else {
            if (this.dataJSON.conductor !== 'zz_SinConductor') {
              datosCamion.conductor = this.dataJSON.conductor
            }
          }
        }
        console.log(datosCamion)
        this.updateClienteByCamion(datosCamion).then((res) => {
          console.log('cargo updateClienteByCamion')
          console.log(res)
          this.$emit('emitEditCamion', datos)
        })
      }
      if (this.selectedAdministrador !== null) {
        const data = {}
        data.id_camion = this.dataJSON.id
        data.administrador = this.selectedAdministrador
        console.log(data)
        this.updateAdminCamion(data).then((res) => {
          console.log('cargo updateAdminCamion')
          console.log(res)
          this.$emit('emitEditCamion', datos)
        })
      }
    },
    async updateClienteByCamion (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateClienteByCamion`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateClienteByCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateClienteByCamion')
        router.push({ path: '/' })
        return false
      }
    },
    async updateClienteByConductor (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateClienteByConductor`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateClienteByConductor')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateClienteByConductor')
        router.push({ path: '/' })
        return false
      }
    },
    async updateConductorByCamion (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateConductorByCamion`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateConductorByCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateConductorByCamion')
        router.push({ path: '/' })
        return false
      }
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Administrador', ['administradores']),
    ...mapState('Cliente', ['clientes'])
  }
}
