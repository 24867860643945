import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
import FiltroReportes from '@/components/Layout/Menu/Filtros/Filtro_Reportes/FiltroReportes.vue'
import ReportesQrDocumentos from '@/components/Layout/Menu/Reportes/Reportes_acarreo_qr/Reportes_qr_documentos/ReportesQrDocumentos.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ReportesAcarreoQr',
  components: {
    FiltroReportes,
    ReportesQrDocumentos
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        {
          key: 'fecha_transporte',
          label: 'Fecha Transporte',
          sortable: false
        },
        {
          key: 'fecha_agenda',
          label: 'Fecha Agenda',
          sortable: false
        },
        {
          key: 'fecha_actividad',
          label: 'Fecha Envio',
          sortable: false
        },
        {
          key: 'folio',
          label: 'Folio',
          sortable: false
        },
        {
          key: 'hora_emision_guia',
          label: 'Hora Emision Guia',
          sortable: false
        },
        // {
        //   key: 'materiales_cantidad',
        //   label: 'Materiales Cantidad',
        //   sortable: true
        // },
        {
          key: 'monto',
          label: 'Monto',
          sortable: true
        },
        {
          key: 'patente',
          label: 'Patente',
          sortable: false
        },
        {
          key: 'nro_entrega',
          label: 'Nro Entrega',
          sortable: true
        },
        {
          key: 'qr_origen_destino',
          label: 'QR Origen Destino',
          sortable: true
        },
        {
          key: 'conductor_origen',
          label: 'Conductor Origen',
          sortable: false
        },
        {
          key: 'conductor_destino',
          label: 'Conductor Destino',
          sortable: false
        },
        {
          key: 'rut_cliente',
          label: 'Rut Cliente',
          sortable: false
        },
        {
          key: 'tipo_documento',
          label: 'Tipo Documento',
          sortable: false
        },
        {
          key: 'retorno',
          label: 'Es viaje con Retorno',
          sortable: false
        },
        {
          key: 'trae_envases',
          label: 'Trae Envase',
          sortable: false
        },
        {
          key: 'trae_pallet',
          label: 'Trae Pallet',
          sortable: false
        },
        {
          key: 'trae_derrame',
          label: 'Trae Derrame',
          sortable: false
        },
        {
          key: 'trae_producto',
          label: 'Trae Producto',
          sortable: false
        },
        {
          key: 'estado_entrega',
          label: 'Estado Entrega',
          sortable: false
        },
        {
          key: 'comentario_conductor',
          label: 'Comentario Conductor',
          sortable: false
        },
        {
          key: 'patente_app',
          label: 'Patente App',
          sortable: false
        },
        {
          key: 'conductor',
          label: 'Conductor',
          sortable: false
        },
        {
          key: 'materiales01',
          label: 'materiales01',
          sortable: false
        },
        {
          key: 'cantidad01',
          label: 'cantidad01',
          sortable: false
        },
        {
          key: 'materiales02',
          label: 'materiales02',
          sortable: false
        },
        {
          key: 'cantidad02',
          label: 'cantidad02',
          sortable: false
        },
        {
          key: 'materiales03',
          label: 'materiales03',
          sortable: false
        },
        {
          key: 'cantidad03',
          label: 'cantidad03',
          sortable: false
        },
        {
          key: 'materiales04',
          label: 'materiales04',
          sortable: false
        },
        {
          key: 'cantidad04',
          label: 'cantidad04',
          sortable: false
        },
        {
          key: 'materiales05',
          label: 'materiales05',
          sortable: false
        },
        {
          key: 'cantidad05',
          label: 'cantidad05',
          sortable: false
        },
        {
          key: 'materiales06',
          label: 'materiales06',
          sortable: false
        },
        {
          key: 'cantidad06',
          label: 'cantidad06',
          sortable: false
        },
        {
          key: 'materiales07',
          label: 'materiales07',
          sortable: false
        },
        {
          key: 'cantidad07',
          label: 'cantidad07',
          sortable: false
        },
        {
          key: 'materiales08',
          label: 'materiales08',
          sortable: false
        },
        {
          key: 'cantidad08',
          label: 'cantidad08',
          sortable: false
        },
        {
          key: 'materiales09',
          label: 'materiales09',
          sortable: false
        },
        {
          key: 'cantidad09',
          label: 'cantidad09',
          sortable: false
        },
        {
          key: 'materiales10',
          label: 'materiales10',
          sortable: false
        },
        {
          key: 'cantidad10',
          label: 'cantidad10',
          sortable: false
        },
        {
          key: 'responsable_cambio',
          label: 'Responsable Cambio',
          sortable: false
        },
        {
          key: 'fecha_cambio',
          label: 'Fecha Cambio',
          sortable: false
        },
        {
          key: 'qr_patente',
          label: 'QR Patente',
          sortable: false
        },
        {
          key: 'estado_revision',
          label: 'Estado de Revision',
          sortable: false
        },
        {
          key: 'id_buk',
          label: 'ID Buk',
          sortable: false
        },
        {
          key: 'documentos',
          label: 'Documentos',
          sortable: false
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      dataFiltroReporte: {},
      filtroEstadoEnable: true,
      filtroCamionEnable: true,
      filtroConductorEnable: true,
      filtroClienteEnable: true,
      filtroRevisionEnable: true,
      downloadButtonaEnable: true,
      documentosQrModal: {
        id: 'documentosQR-modal',
        title: '',
        content: ''
      },
      columnOrder: [
        'folio',
        'tipo_documento',
        'rut_cliente',
        'monto',
        'patente',
        'origen_destino',
        'materiales_cantidad',
        'hora_emision_guia',
        'nro_entrega',
        'origen',
        'destino',
        'materiales_cantidades',
        'fecha_agenda',
        'fecha_actividad',
        'retorno',
        'trae_envases',
        'trae_pallet',
        'trae_derrame',
        'trae_producto',
        'estado_entrega',
        'comentario_conductor',
        'patente_app',
        'responsable_cambio',
        'fecha_cambio',
        'fecha_transporte',
        'nro_documento',
        'v06_coment',
        'v06_est_entr',
        'qr_patente',
        'estado_revision',
        'v06_image',
        'v06_nro_doc',
        'v06_tip_doc',
        'materiales01',
        'cantidad01',
        'materiales02',
        'cantidad02',
        'materiales03',
        'cantidad03',
        'materiales04',
        'cantidad04',
        'materiales05',
        'cantidad05',
        'materiales06',
        'cantidad06',
        'materiales07',
        'cantidad07',
        'materiales08',
        'cantidad08',
        'materiales09',
        'cantidad09',
        'materiales10',
        'cantidad10',
        'materiales11',
        'cantidad11',
        'materiales12',
        'cantidad12',
        'materiales13',
        'cantidad13',
        'materiales14',
        'cantidad14',
        'materiales15',
        'cantidad15',
        'materiales16',
        'cantidad16',
        'materiales17',
        'cantidad17',
        'materiales18',
        'cantidad18',
        'materiales19',
        'cantidad19',
        'materiales20',
        'cantidad20',
        'materiales21',
        'cantidad21',
        'materiales22',
        'cantidad22',
        'materiales23',
        'cantidad23',
        'materiales24',
        'cantidad24',
        'materiales25',
        'cantidad25',
        'materiales26',
        'cantidad26',
        'materiales27',
        'cantidad27',
        'materiales28',
        'cantidad28',
        'materiales29',
        'cantidad29',
        'materiales30',
        'cantidad30',
        // 'id_buk',
        'conductor',
        'rut_conductor',
        'id_buk_conductor'
      ]
    }
  },
  watch: {
    dataFiltroReporte: function (val) {
      if (this.dataFiltroReporte === null || this.dataFiltroReporte === undefined) {
        return
      }
      console.log('observador dataFiltroReporte')
      console.log(this.dataFiltroReporte)
      const periodos = {}
      console.log(this.dataFiltroReporte !== null)
      periodos.fechaDesde = this.dataFiltroReporte.fechaDesde
      periodos.fechaHasta = this.dataFiltroReporte.fechaHasta
      if (this.dataFiltroReporte.cliente !== undefined && this.dataFiltroReporte.cliente !== null) periodos.cliente = this.dataFiltroReporte.cliente
      if (this.dataFiltroReporte.revision !== undefined && this.dataFiltroReporte.revision !== null) periodos.revision = this.dataFiltroReporte.revision
      console.log(periodos)
      if (this.dataFiltroReporte.forDownload) { // solicita la descarga del reporte
        this.exportarExcelQr()
        return
      }
      this.getReportePorCargaAcarreoQR(this.dataFiltroReporte).then((res) => {
        console.log('cargo reporte cargas')
        this.items = []
        this.items = this.reporteAcarreoQr
        for (let i = 0; i < this.items.length; i++) {
          const materialesCantidades = this.items[i].materiales_cantidades
          if (materialesCantidades !== null) {
            for (let j = 0; j < materialesCantidades.length; j++) {
              const [materiales, cantidad] = materialesCantidades[j].split('-')
              const prefix = (j + 1 < 10) ? '0' : ''
              this.items[i][`materiales${prefix}${j + 1}`] = materiales
              this.items[i][`cantidad${prefix}${j + 1}`] = cantidad
            }
          }
        }
        console.log(this.items)
        this.totalRows = this.items.length
      })
      this.totalRows = this.items.length
    }
  },
  mounted () {
    const periodos = this.PeriodoFechaActual()
    this.getReportePorCargaAcarreoQR(periodos).then((res) => {
      this.items = this.reporteAcarreoQr
      for (let i = 0; i < this.items.length; i++) {
        const materialesCantidades = this.items[i].materiales_cantidades
        if (materialesCantidades !== null) {
          for (let j = 0; j < materialesCantidades.length; j++) {
            const [materiales, cantidad] = materialesCantidades[j].split('-')
            this.items[i][`materiales${j + 1}`] = materiales
            this.items[i][`cantidad${j + 1}`] = cantidad
          }
        }
      }
      // console.log(this.items)
      console.log('cargo reporte acarreo qr')
      this.totalRows = this.items.length
    })
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    ...mapActions('Reportes', [
      'getReportePorCargaAcarreoQR', 'getReportePorCargaAcarreoQRV2'
    ]),
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    exportarExcelQr () {
      console.log('exportar excel QR')
      console.log(this.dataFiltroReporte)
      this.getReportePorCargaAcarreoQRV2(this.dataFiltroReporte).then((res) => {
        console.log('cargo reporte QR')
        const itemsAux = []
        let items = {}
        const reporteQr = this.reporteAcarreoQrV2
        console.log('1')
        console.log(reporteQr)
        const sortedArray = reporteQr.map(obj => {
          const sortedObj = {}
          this.columnOrder.forEach(key => {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              sortedObj[key] = obj[key]
            }
          })
          return sortedObj
        })
        console.log('2')
        console.log(sortedArray)
        // Si es necesario, reasignar el objeto ordenado a la variable original
        for (const item in sortedArray) {
          items = {}
          for (const [key, value] of Object.entries(sortedArray[item])) {
            if (!key.includes('_cellVariants')) {
              items[key] = value
            }
          }
          itemsAux.push(items)
        }
        console.log('3')
        console.log(itemsAux)
        const ws = XLSX.utils.json_to_sheet(itemsAux)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'reporte')
        XLSX.writeFile(wb, 'reporte_qr_v2.xlsx')
      })
    },
    exportarExcel () {
      console.log('exportar excel')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          if (!key.includes('_cellVariants')) {
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'reporte')
      XLSX.writeFile(wb, 'reporte_qr.xlsx')
    },
    exportarDetalleExcel () {
      console.log('exportar excel detalle')
      const itemsAux = []
      let items = {}
      console.log(this.columnOrder)
      for (const item in this.items) {
        items = {}
        if (this.items[item].docs !== null && this.items[item].docs.length > 0) {
          for (let i = 0; this.items[item].docs.length > i; i++) {
            for (const [key, value] of Object.entries(this.items[item])) {
              if (!key.includes('_cellVariants') && key !== 'docs') {
                items[key] = value
              }
            }
            if (this.items[item].docs[i] !== null && this.items[item].docs[i].v06_image) {
              items.nro_documento = this.items[item].docs[i].nro_documento
              items.v06_coment = this.items[item].docs[i].v06_coment
              items.v06_est_entr = this.items[item].docs[i].v06_est_entr
              items.v06_image = this.items[item].docs[i].v06_image
              items.v06_nro_doc = this.items[item].docs[i].v06_nro_doc
              items.v06_tip_doc = this.items[item].docs[i].v06_tip_doc
              const orderedObject = {}
              for (const key of this.columnOrder) {
                if (key in items) {
                  orderedObject[key] = items[key]
                }
              }
              itemsAux.push(orderedObject)
              items = {}
            }
          }
        } else {
          for (const [key, value] of Object.entries(this.items[item])) {
            if (!key.includes('_cellVariants') && key !== 'docs') {
              items[key] = value
            }
          }
          const orderedObject = {}
          for (const key of this.columnOrder) {
            if (key in items) {
              orderedObject[key] = items[key]
            }
          }
          itemsAux.push(items)
        }
      }
      // Encontrar el objeto con más elementos
      let objetoConMasElementos = itemsAux[0]
      for (const objeto of itemsAux) {
        if (Object.keys(objeto).length > Object.keys(objetoConMasElementos).length) {
          objetoConMasElementos = objeto
        }
      }
      // Obtener el índice del objeto con más elementos
      const indiceDelObjetoConMasElementos = itemsAux.indexOf(objetoConMasElementos)
      // Intercambiar el objeto con más elementos con el objeto en la posición 0
      const temp = itemsAux[0]
      itemsAux[0] = itemsAux[indiceDelObjetoConMasElementos]
      itemsAux[indiceDelObjetoConMasElementos] = temp
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'reporte')
      XLSX.writeFile(wb, 'reporte_qr_detalle.xlsx')
      // console.log(itemsAux)
      // console.log(this.items)
    },
    async getCargas (periodo) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/vueltas/getReportePorCarga`,
          data: {
            // fechaDesde: '2022-08-18 00:00:00',
            // fechaHasta: '2022-08-19 23:00:00'
            fechaDesde: periodo.fechaDesde,
            fechaHasta: periodo.fechaHasta
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log(response.data)
          return response.data
        } else {
          console.log('por else getCargas')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getCargas')
        router.push({ path: '/' })
        return false
      }
    },
    PeriodoFechaActual () {
      const date = new Date()
      // const fechaActual = `${
      //   date.getFullYear().toString().padStart(4, '0')}-${
      //     (date.getMonth() + 1).toString().padStart(2, '0')}-${
      //       date.getDate().toString().padStart(2, '0')}T${
      //         date.getHours().toString().padStart(2, '0')}:${
      //           date.getMinutes().toString().padStart(2, '0')}:${
      //             date.getSeconds().toString().padStart(2, '0')}.000+00:00`
      const fechaActualPrimeraHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualUltimaHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '23'}:${
                '59'}:${
                  '59'}`
      console.log(fechaActualPrimeraHora)
      console.log(fechaActualUltimaHora)
      const periodo = {}
      periodo.fechaDesde = fechaActualPrimeraHora
      periodo.fechaHasta = fechaActualUltimaHora
      return periodo
    },
    DocumentosQrModal (item, index, button) {
      this.documentosQrModal.title = 'Documentos'
      this.documentosQrModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.documentosQrModal.id, button)
    },
    hideDocumentosQrModal () {
      this.$refs[this.documentosQrModal.id].hide()
    },
    test () {
      this.getCargas().then((res) => {
        this.items = res
        console.log('cargo')
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Reportes', ['reporteAcarreoQr']),
    ...mapState('Reportes', ['reporteAcarreoQrV2']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
