<script>
import { Bar } from 'vue-chartjs'
// Exporting this so it can be used in other components
export default {
  extends: Bar,
  props: {
    render: null,
    renderRange: null,
    Labels: null,
    Datasets: null,
    valueRange: null
  },
  data () {
    return {
      datacollection: {
        // Data to be represented on x-axis
        labels: [],
        datasets: [
          {
            label: [],
            backgroundColor: [
              'rgb(19, 255, 0)',
              '#FF0000'
              // 'rgb(128, 128, 0)'
            ],
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#FF0000',
            // Data to be represented on y-axis
            data: []
          }
          // ,
          // {
          //   // label: ['Sopraval'],
          //   backgroundColor: ['rgb(255, 255, 0)'],
          //   pointBackgroundColor: 'white',
          //   borderWidth: 1,
          //   pointBorderColor: 'rgb(255, 255, 0)',
          //   // Data to be represented on y-axis
          //   data: [0, 2, 0]
          // },
          // {
          //   // label: ['Soprole'],
          //   backgroundColor: ['rgb(125, 125, 125)'],
          //   pointBackgroundColor: 'white',
          //   borderWidth: 1,
          //   pointBorderColor: 'rgb(255, 255, 0)',
          //   // Data to be represented on y-axis
          //   data: [0, 0, 3]
          // }
          // {
          //   label: 'Soprole',
          //   backgroundColor: ['#FF0000'],
          //   pointBackgroundColor: 'white',
          //   borderWidth: 1,
          //   pointBorderColor: 'rgb(255, 255, 0)',
          //   // Data to be represented on y-axis
          //   data: []
          // }
        ]
      },
      // Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    // renderChart function renders the chart with the datacollection and options object.
    this.datacollection.labels = this.Labels
    // this.datacollection.datasets[0].label = this.Labels
    this.datacollection.datasets[0].data = this.Datasets
    this.options.scales.yAxes[0].ticks.max = parseInt(this.valueRange)
    // this.datacollection.datasets[0].backgroundColor = this.BackgroundColor
    this.renderChart(this.datacollection, this.options)
    this.$emit('emitRender', true)
  },
  watch: {
    render: {
      handler: function (val, oldVal) {
        console.log('render ')
        // console.log(this.render)
        // console.log(this.Datasets)
        this.datacollection.labels = this.Labels
        this.datacollection.datasets[0].data = this.Datasets
        this.options.scales.yAxes[0].ticks.max = parseInt(this.valueRange)
        // this.datacollection.datasets[0].backgroundColor = this.BackgroundColor
        this.renderChart(this.datacollection, this.options)
      }
    },
    valueRange: {
      handler: function (val, oldVal) {
        console.log('valueRange ')
        // console.log(this.render)
        // console.log(this.Datasets)
        this.datacollection.labels = this.Labels
        this.datacollection.datasets[0].data = this.Datasets
        this.options.scales.yAxes[0].ticks.max = parseInt(this.valueRange)
        // this.datacollection.datasets[0].backgroundColor = this.BackgroundColor
        this.renderChart(this.datacollection, this.options)
      }
    },
    Labels: {
      handler: function (val, oldVal) {
        console.log('render labels')
        this.datacollection.labels = this.Labels
        this.datacollection.datasets = this.Datasets
        this.options.scales.yAxes[0].ticks.max = parseInt(this.valueRange)
        this.renderChart(this.datacollection, this.options)
      }
    }
  }
}
</script>
