import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import store from '@/store'

export default {
  name: 'AdministracionCargaArchivosView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      show: false,
      file: null,
      arrayBuffer: null,
      filelist: null,
      fileEntregaDiaria: null,
      fileMaestroClientes: null,
      clientesExcel: [],
      entregaDiaria: [],
      maestroClientes: []
    }
  },
  watch: {
    // updateClientes: function (val) {
    // }
  },
  mounted () {
    // this.getAllClientes().then((res) => {
    // })
  },
  methods: {
    ...mapActions('Archivos', [
      'setTransportes'
    ]),
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    },
    addfile (event, tipo) {
      this.show = true
      console.log(event)
      console.log(tipo)
      if (tipo === 'entregaDiaria') {
        this.fileEntregaDiaria = event.target.files[0]
      } else if (tipo === 'maestroClientes') {
        this.fileMaestroClientes = event.target.files[0]
      } else {
        this.file = event.target.files[0]
      }
      const fileReader = new FileReader()
      try {
        fileReader.readAsArrayBuffer(event.target.files[0])
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
        // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
        const fileUpload = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        this.filelist = []
        console.log(fileUpload)
        if (tipo === 'entregaDiaria') {
          this.entregaDiaria = fileUpload
        } else if (tipo === 'maestroClientes') {
          this.maestroClientes = fileUpload
        }
        this.show = false
      }
    },
    cargarExcel () {
      // this.insertClientes(this.clientesExcel).then((res) => {
      //   console.log('cargarExcel')
      //   const toast = {}
      //   toast.body = 'Se ha cargado el archivo con exito'
      //   toast.title = 'Carga Exitosa'
      //   toast.variant = 'success'
      //   this.makeToast(toast)
      //   this.clientesExcel = []
      //   this.$emit('updateClientes', this.clientes)
      // })
    },
    cargarEntregaDiaria () {
      console.log('cargarEntregaDiaria')
      console.log(this.entregaDiaria)
      const transportes = []
      let fecha = 0
      const date = new Date(1900, 0, 1, 0, 0, 0)
      let fechaFormat = ''
      for (const entrega in this.entregaDiaria) {
        if (fecha === 0) {
          fecha = this.entregaDiaria[entrega].Fecha
          // console.log(fecha)
          const milisegundos = fecha * 24 * 60 * 60 * 1000
          // console.log(milisegundos)
          const milisegundosActual = date.getMilliseconds()
          // console.log(milisegundosActual)
          date.setMilliseconds(milisegundos + milisegundosActual - (24 * 60 * 60 * 1000))
          date.setHours(0)
          date.setMinutes(0)
          date.setSeconds(0)
          // console.log(date)
          fechaFormat = `${
            date.getFullYear().toString().padStart(4, '0')}-${
              (date.getMonth() + 1).toString().padStart(2, '0')}-${
                date.getDate().toString().padStart(2, '0')} ${
                  '00'}:${
                    '00'}:${
                      '00'}`
          console.log(fechaFormat)
        }
        const entregaFilter = {
          transporte: 0,
          vuelta: '',
          cam_basis: 0
        }
        const transporte = this.entregaDiaria[entrega]['CÃ³digo']
        if (typeof transporte === 'string') {
          const index = transporte.indexOf('_')
          entregaFilter.transporte = parseInt(transporte.substring(0, index))
          entregaFilter.vuelta = parseInt(transporte.substring(index + 1, transporte.length))
          if (typeof this.entregaDiaria[entrega]['Equipo Principal'] === 'number') entregaFilter.cam_basis = this.entregaDiaria[entrega]['Equipo Principal']
          if (entregaFilter.cam_basis !== 0) transportes.push(entregaFilter)
        } else {
          entregaFilter.transporte = transporte
          entregaFilter.vuelta = transporte
        }
      }
      const data = {
        fecha: fechaFormat,
        transporteDTO: transportes
      }
      console.log(data)
      console.log(transportes)
      this.setTransportes(data).then((res) => {
        console.log('cargarExcel')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
      })
    },
    cargarMaestroClientes () {
      console.log('cargarMaestroClientes')
    },
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.createInput(files[0])
    },
    createInput (file) {
      var reader = new FileReader()
      var vm = this
      reader.onload = (e) => {
        vm.fileinput = reader.result
      }
      reader.readAsText(file)
      console.log(this.fileinput)
      this.entregaDiaria = this.fileinput
    }
  },
  computed: {
    ...mapState('Archivos', ['archivos']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
