import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CrearAdministradores',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      administrador: ''
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    ...mapActions('Administrador', [
      'getAdministradores', 'createAdministradores'
    ]),
    crearAdministrador () {
      if (this.administrador !== '' && this.administrador !== null) {
        const administradorJSON = {}
        administradorJSON.nombre = this.administrador
        this.createAdministradores(administradorJSON).then((res) => {
          this.$emit('emitEdit', this.administradores)
        })
      }
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Administrador', ['administradores'])
  }
}
