import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'TransformaQr',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {},
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: 'Conductor' }
      ],
      selectedCliente: null,
      optionsCliente: [
        { value: null, text: 'Cliente' },
        { value: 'Andina', text: 'Andina' },
        { value: 'Sopraval', text: 'Sopraval' },
        { value: 'Soprole', text: 'Soprole' }
      ],
      emitEditCamion: false,
      parametros: {
        folio: null,
        tipo_documento: '52',
        rut_cliente: '91144000-8',
        monto: null,
        patente: null,
        origen_destino: null,
        hora_emision: null,
        nro_entrega: null,
        materiales_cantidad: []
      },
      materiales: null,
      cantidad: null
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    console.log(this.dataJSON)
    for (const conductor in this.conductores) {
      this.optionsConductor.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
    }
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    addMaterialCantidad () {
      if ((this.materiales === null || this.materiales === '') || (this.cantidad === null || this.cantidad === '')) {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Faltan campos por llenar.'
        this.makeToast(toast)
        return
      }
      const data = this.materiales + '-' + this.cantidad
      this.parametros.materiales_cantidad.push(data)
    },
    eliminarMaterial (index) {
      this.parametros.materiales_cantidad.splice(index, 1)
    },
    validarParametros () {
      let validador = true
      for (const key in this.parametros) {
        if (Object.prototype.hasOwnProperty.call(this.parametros, key)) {
          const valor = this.parametros[key]
          if (valor === null || valor === '' || /^\s*$/.test(valor)) {
            validador = false // Devuelve false si encuentra un valor no válido
          }
          if (Array.isArray(valor) && valor.length === 0) {
            validador = false // Devuelve false si el array está vacío
          }
        }
      }
      return validador
    },
    editarField () {
      console.log(this.validarParametros())
      if (!this.validarParametros()) {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Faltan campos por llenar.'
        this.makeToast(toast)
        return
      }
      console.log('valido')
      let qr = ''
      const folio = 'Folio: ' + this.parametros.folio + '\n'
      const tipoDocumento = 'Tipo Documento: ' + this.parametros.tipo_documento + '\n'
      const rutCliente = 'Rut Cliente: ' + this.parametros.rut_cliente + '\n'
      const monto = 'Monto: ' + this.parametros.monto + '\n'
      const patente = 'Patente: ' + this.parametros.patente + '\n'
      const origenDestino = 'Origen - Destino (Código): ' + this.parametros.origen_destino + '\n'
      qr = folio + tipoDocumento + rutCliente + monto + patente + origenDestino
      qr = qr + 'Materiales - Cantidad\n'
      this.parametros.materiales_cantidad.forEach(element => {
        qr = qr + element + '\n'
      })
      const horaEmision = 'Hora Emisión Guía: ' + this.parametros.hora_emision + '\n'
      const nroEntrega = 'Número entrega: ' + this.parametros.nro_entrega
      qr = qr + horaEmision + nroEntrega
      this.dataJSON.field_value = qr
      this.$emit('emitEditQR', this.dataJSON)
    },
    Editar () {
      const datos = {}
      datos.id = this.dataJSON.id
      datos.rut = this.dataJSON.rut
      // datos.dv = this.dataJSON.dv
      datos.cliente = this.dataJSON.cliente
      datos.direccion = this.dataJSON.direccion
      datos.destino = this.dataJSON.destino
      datos.c_costo = this.dataJSON.c_costo
      datos.km_defin = this.dataJSON.km_defin
      datos.id = this.dataJSON.id
      // console.log(datos)
      this.updateDestinos(datos).then((res) => {
        console.log('cargo updateDestinos')
        // console.log(res)
        this.$emit('emitEdit', datos)
      })
      this.$bvToast.toast('Destino Actualizado', {
        title: 'Estado',
        variant: 'success',
        autoHideDelay: 5000,
        solid: true
      })
    },
    async updateDestinos (datosDestino) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/vueltas/updateDestinos`,
          data: datosDestino,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateDestinos')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateDestinos')
        router.push({ path: '/' })
        return false
      }
    },
    async updateClienteByConductor (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateClienteByConductor`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateClienteByConductor')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateClienteByConductor')
        router.push({ path: '/' })
        return false
      }
    },
    async updateConductorByCamion (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateConductorByCamion`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateConductorByCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateConductorByCamion')
        router.push({ path: '/' })
        return false
      }
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi'])
  }
}
