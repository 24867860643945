import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
import EliminarCamiones from '@/components/Layout/Menu/Administracion/Administracion_camiones/Eliminar_camiones/EliminarCamiones.vue'
import EditarCamiones from '@/components/Layout/Menu/Administracion/Administracion_camiones/Editar_camiones/EditarCamiones.vue'
import CrearCamiones from '@/components/Layout/Menu/Administracion/Administracion_camiones/Crear_camiones/CrearCamiones.vue'
import AsignarCamionesView from '@/components/Layout/Menu/Administracion/Administracion_camiones/Asignar_camiones/AsignarCamionesView.vue'
import AsignarTurnoView from '@/components/Layout/Menu/Administracion/Administracion_camiones/Asignar_turno/AsignarTurnoView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AdministracionCamiones',
  components: {
    EditarCamiones,
    CrearCamiones,
    AsignarCamionesView,
    EliminarCamiones,
    AsignarTurnoView
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        {
          key: 'camion',
          label: 'Camion',
          sortable: false
        },
        {
          key: 'conductor',
          label: 'Conductor',
          sortable: false
        },
        {
          key: 'matricula',
          label: 'Patente',
          sortable: true
        },
        {
          key: 'cliente',
          label: 'Cliente',
          sortable: true
        },
        {
          key: 'administrador',
          label: 'Admin Flota',
          sortable: true
        },
        {
          key: 'turno',
          label: 'Turno',
          sortable: true
        },
        {
          key: 'editar',
          label: 'Editar',
          sortable: false
        },
        {
          key: 'eliminar',
          label: 'Eliminar',
          sortable: false
        }
      ],
      itemsAux: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      emitEditCamion: '',
      emitEliminaCamion: '',
      editarCamion: {
        id: 'editarCamion-modal',
        title: 'Editar',
        content: ''
      },
      eliminarCamion: {
        id: 'eliminarCamion-modal',
        title: 'Eliminar',
        content: ''
      },
      CrearCamion: {
        id: 'crearCamion-modal',
        title: 'Crear',
        content: ''
      },
      AsignarCamionConductor: {
        id: 'asignarCamionConductor-modal',
        title: 'Asignar Conductor/Camion',
        content: ''
      },
      AsignarTurnoConductor: {
        id: 'asignarTurnoConductor-modal',
        title: 'Asignar Conductor/Turno',
        content: ''
      },
      patentes: [
        {
          value: null,
          text: '- Seleccionar Patente'
        }
      ],
      selectedPatente: null,
      camBasis: [
        {
          value: null,
          text: 'Seleccionar NroInterno/Nro Basis'
        }
      ],
      selectedCamBasis: null,
      file: null,
      arrayBuffer: null,
      filelist: null,
      arrayRespuesta: []
    }
  },
  watch: {
    emitEliminaCamion: function (val) {
      this.hideModaleliminarCamion()
      this.obtenerCamionesConductores()
    },
    emitEditCamion: function (val) {
      console.log('observador emitEditCamion')
      this.hideModaleliminarCamion()
      this.hideModalcrearCamion()
      this.hideModalAsignarCamionConductor()
      this.hideModaleditarCamion()
      this.obtenerCamionesConductores()
      this.hideModalAsignarTurnoConductor()
      this.totalRows = this.items.length
    }
  },
  mounted () {
    this.getConductores().then((res) => {
    })
    this.getCamiones().then((res) => {
    })
    this.getCamionesConductores().then((res) => {
      const newItems = []
      // console.log(res)
      for (const item in res) {
        const newItem = {}
        newItem.id_relacion = res[item].id
        newItem.id = res[item].camion.id
        newItem.camion = res[item].camion.cam_basis
        newItem.matricula = res[item].camion.matricula
        newItem.conductor = res[item].conductor.device_alias
        newItem.cliente = res[item].cliente
        newItem.administrador = res[item].camion.administrador.nombre
        if (res[item].conductor.turno !== undefined && res[item].conductor.turno !== null) {
          newItem.turno = res[item].conductor.turno.clasificacion
        }
        const patente = {
          value: newItem.matricula,
          text: newItem.matricula
        }
        this.patentes.push(patente)
        const camBasis = {
          value: newItem.camion,
          text: newItem.camion
        }
        this.camBasis.push(camBasis)
        newItems.push(newItem)
      }
      this.items = newItems
      this.itemsAux = this.items
      this.patentes.sort((a, b) => a.text.localeCompare(b.text))
      console.log('cargo reporte cargas')
      this.totalRows = this.items.length
    })
    this.getAdministradores().then((res) => {
    })
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones', 'createCamiones'
    ]),
    ...mapActions('Administrador', [
      'getAdministradores'
    ]),
    obtenerCamionesConductores () {
      this.getCamionesConductores().then((res) => {
        const newItems = []
        for (const item in res) {
          const newItem = {}
          newItem.id_relacion = res[item].id
          newItem.id = res[item].camion.id
          newItem.camion = res[item].camion.cam_basis
          newItem.matricula = res[item].camion.matricula
          newItem.conductor = res[item].conductor.device_alias
          newItem.cliente = res[item].cliente
          newItem.administrador = res[item].camion.administrador.nombre
          newItems.push(newItem)
        }
        this.items = newItems
        this.itemsAux = this.items
        console.log('cargo reporte cargas')
        this.totalRows = this.items.length
      })
    },
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    editarCamionModal (item, index, button) {
      this.editarCamion.title = 'Editar Camion: ' + item.matricula
      this.editarCamion.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editarCamion.id, button)
    },
    eliminarCamionModal (item, index, button) {
      this.eliminarCamion.title = 'Eliminar Camion: ' + item.matricula + ' Conductor: ' + item.conductor
      this.eliminarCamion.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.eliminarCamion.id, button)
    },
    crearCamionModal (item, index, button) {
      // this.CrearCamion.title = `Row index: ${index}`
      this.CrearCamion.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.CrearCamion.id, button)
    },
    asignarCamionConductorModal (item, index, button) {
      // this.CrearCamion.title = `Row index: ${index}`
      this.AsignarCamionConductor.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.AsignarCamionConductor.id, button)
    },
    hideModalAsignarCamionConductor () {
      this.$refs[this.AsignarCamionConductor.id].hide()
    },
    asignarTurnoConductorModal (item, index, button) {
      // this.CrearCamion.title = `Row index: ${index}`
      this.AsignarTurnoConductor.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.AsignarTurnoConductor.id, button)
    },
    hideModalAsignarTurnoConductor () {
      this.$refs[this.AsignarTurnoConductor.id].hide()
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filtroitems () {
      this.items = this.itemsAux
      if (this.selectedCamBasis !== undefined && this.selectedCamBasis !== null) {
        // let itemsHaFlitrar = this.items
        // this.items = []
        console.log('tiene filtro de cam basis')
        // console.log(this.selectedCamBasis)
        this.items = this.items.filter(camBasis => camBasis.camion === this.selectedCamBasis)
        // this.items = itemsHaFlitrar
      }
      if (this.selectedPatente !== undefined && this.selectedPatente !== null) {
        // let itemsHaFlitrar = this.items
        // this.items = []
        console.log('tiene filtro de patente')
        // console.log(this.selectedPatente)
        this.items = this.items.filter(camBasis => camBasis.matricula === this.selectedPatente)
        // this.items = itemsHaFlitrar
      }
    },
    hideModaleditarCamion () {
      this.$refs[this.editarCamion.id].hide()
    },
    hideModaleliminarCamion () {
      this.$refs[this.eliminarCamion.id].hide()
    },
    hideModalcrearCamion () {
      this.$refs[this.CrearCamion.id].hide()
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants')) {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'transportes')
      XLSX.writeFile(wb, 'transportes.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    addfile (event) {
      console.log(event)
      this.file = event.target.files[0]
      const fileReader = new FileReader()
      try {
        fileReader.readAsArrayBuffer(this.file)
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        console.log(workbook)
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        this.arrayRespuesta = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        console.log(this.arrayRespuesta)
        this.filelist = []
        console.log(this.filelist)
      }
    },
    cargarExcel () {
      console.log('cargar excel')
      this.createCamiones(this.arrayRespuesta).then((res) => {
        this.getCamionesConductores().then((res) => {
          const newItems = []
          for (const item in res) {
            const newItem = {}
            newItem.id = res[item].camion.id
            newItem.camion = res[item].camion.cam_basis
            newItem.matricula = res[item].camion.matricula
            newItem.conductor = res[item].conductor.device_alias
            newItem.cliente = res[item].cliente
            newItem.administrador = res[item].camion.administrador.nombre
            newItems.push(newItem)
          }
          this.items = newItems
          this.itemsAux = this.items
          console.log('cargo reporte cargas')
          this.totalRows = this.items.length
          const toast = {}
          toast.title = 'Exito'
          toast.variant = 'success'
          toast.body = 'Se han cargado exitosamente los Camiones'
          this.makeToast(toast)
        })
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    },
    // makeToast (variant = null) {
    //   this.$bvToast.toast('Toast body content', {
    //     title: `Variant ${variant || 'default'}`,
    //     variant: variant,
    //     solid: true
    //   })
    // },
    testToast () {
      const toast = {}
      toast.title = 'Carga Exitosa'
      toast.variant = 'success'
      toast.body = ''
      this.makeToast(toast)
    },
    async getCamionesConductores () {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/getCamionConductor`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else getCamionesConductores')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getCamionesConductores')
        router.push({ path: '/' })
        return false
      }
    },
    test () {
      this.getCamionesConductores().then((res) => {
        this.items = res
        console.log('cargo getCamionesConductores')
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Administrador', ['administradores']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
