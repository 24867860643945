import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    imagenes: []
  },
  mutations: {
    set_accion_imagenes (state, imagenes) {
      state.imagenes = imagenes
    }
  },
  actions: {
    async getImagenes ({ commit, dispatch, state }, name) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/images/listar?name=${name}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getImagenes')
          console.log(response.data)
          commit('set_accion_imagenes', response.data)
          return true
        } else {
          console.log('por else getImagenes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getImagenes')
        router.push({ path: '/' })
        return false
      }
    },
    async obtenerImagen ({ commit, dispatch, state }, imagen) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/images/documento/${imagen}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api obtenerImagen')
          console.log(response.data)
          return true
        } else {
          console.log('por else obtenerImagen')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch obtenerImagen')
        router.push({ path: '/' })
        return false
      }
    },
    async deleteClientesById ({ commit, dispatch, state }, idCliente) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/vps/clientes/delete/${idCliente}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api deleteClientesById')
          console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else deleteClientesById')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch deleteClientesById')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
