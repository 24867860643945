import { mapActions, mapState } from 'vuex'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  name: 'FiltroAdminFlota',
  components: {
  },
  props: {
    filtroClienteEnable: null,
    filtroEstadoEnable: null,
    filtroCamionEnable: null,
    filtroConductorEnable: null,
    filtroPatenteEnable: null,
    filtroConfirmacionAdminEnable: null,
    filtroAdministradorFlotaEnable: null
  },
  data: function () {
    return {
      fechaDesde: '',
      fechaHasta: '',
      selectedCliente: null,
      optionsCliente: [
        { value: null, text: '- Cliente' }
      ],
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: '- Conductor' }
      ],
      selectedCamion: null,
      optionsCamion: [
        { value: null, text: '- Camion' }
      ],
      selectedPatente: '',
      selectedCentroCosto: null,
      optionsCentroCosto: [
        { value: null, text: '- Administrador Flota' }
      ],
      selectedAdministradorFlota: null,
      optionsAdministradorFlota: [
        { value: null, text: '- Administrador Flota' }
      ],
      selectedConfirmacionAdmin: null,
      optionsConfirmacionAdmin: [
        { value: null, text: '- Estado Confirmacion' },
        { value: 'P', text: 'Por Revisar' },
        { value: 'R', text: 'Revisada' },
        { value: 'E', text: 'Eliminada' }
      ],
      selectedEstado: null,
      optionsEstado: [
        { value: null, text: '- Estado' },
        { value: 'entregada', text: 'Entregada' },
        { value: 'pendiente a salir', text: 'Pendiente a salir' },
        { value: 'en ruta', text: 'En Ruta' },
        { value: 'no realizado', text: 'No realizado' }
      ]
    }
  },
  watch: {
    conductores: function (val) {
      this.optionsConductor = []
      this.optionsConductor.push({ value: null, text: '- Conductor' })
      for (const conductor in this.conductores) {
        this.optionsConductor.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
      }
      this.optionsConductor.sort((a, b) => a.text.localeCompare(b.text))
    },
    camiones: function (val) {
      for (const camion in this.camiones) {
        const data = {}
        data.text = this.camiones[camion].cam_basis
        data.value = this.camiones[camion].cam_basis
        this.optionsCamion.push(data)
      }
    }
  },
  mounted () {
    this.optionsConductor = []
    this.optionsConductor.push({ value: null, text: '- Conductor' })
    for (const conductor in this.conductores) {
      this.optionsConductor.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
    }
    this.optionsConductor.sort((a, b) => a.text.localeCompare(b.text))
    for (const camion in this.camiones) {
      const data = {}
      data.text = this.camiones[camion].cam_basis
      data.value = this.camiones[camion].cam_basis
      this.optionsCamion.push(data)
    }
    this.getAdministradores().then((res) => {
      // console.log(this.administradores)
      this.administradores.forEach(element => this.optionsAdministradorFlota.push({
        value: element.nombre,
        text: element.nombre
      }))
    })
    this.getClientes().then((res) => {
      this.clientes.forEach(element => {
        if (this.permisos.some(objeto => objeto.nombre === element.nombre)) {
          this.optionsCliente.push({
            value: element.nombre,
            text: element.nombre
          })
        }
      })
    })
  },
  methods: {
    ...mapActions([
    ]),
    ...mapActions('Administrador', [
      'getAdministradores', 'createAdministradores', 'deleteAdministradores'
    ]),
    ...mapActions('Cliente', [
      'getClientes'
    ]),
    test () {
      console.log('test')
    },
    Buscar () {
      let fechaDesde = null
      let fechaHasta = null
      if (this.fechaDesde !== '' && this.fechaDesde !== null && this.fechaDesde !== undefined) {
        fechaDesde = this.fechaDesde + ' 00:00:00'
      }
      if (this.fechaDesde !== '' && this.fechaDesde !== null && this.fechaDesde !== undefined) {
        fechaHasta = this.fechaDesde + ' 23:59:00'
      }
      // const fechaDesde = this.fechaDesde + ' 00:00:00'
      // const fechaHasta = this.fechaDesde + ' 23:59:00'
      const periodo = {}
      periodo.fechaDesde = fechaDesde
      periodo.fechaHasta = fechaHasta
      if (this.selectedCliente != null) {
        periodo.cliente = this.selectedCliente
      }
      if (this.selectedCamion != null) {
        periodo.camion = this.selectedCamion
      }
      if (this.selectedConductor != null) {
        periodo.conductor = this.selectedConductor
      }
      if (this.selectedPatente != null) {
        periodo.patente = this.selectedPatente
      }
      if (this.selectedAdministradorFlota != null) {
        periodo.administrador = this.selectedAdministradorFlota
      }
      if (this.selectedConfirmacionAdmin != null) {
        periodo.confirmacionAdmin = this.selectedConfirmacionAdmin
      }
      if (this.selectedEstado != null) {
        periodo.estado = this.selectedEstado
      }
      // console.log(periodo)
      this.$emit('dataFiltroReporte', periodo)
    },
    async getTransportes (periodo) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getTransportes`,
          data: {
            // fechaDesde: '2022-08-19T00:00:00.000+00:00',
            fechaDesde: periodo.fechaDesde,
            fechaHasta: periodo.fechaHasta
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api transporte')
          console.log(response.data)
          return true
        } else {
          // commit('set_token', 'clave o usuario invalido')
          console.log('por else transporte')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch transporte')
        router.push({ path: '/' })
        // dispatch('GetActividadesCoordinador')
        // this.dispatch.GetActividadesCoordinador()
        // commit('set_token', 'clave o usuario invalido')
        return false
      }
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Administrador', ['administradores']),
    ...mapState('Cliente', ['clientes']),
    ...mapState('Permisos', ['permisos'])
  }
}
