import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    administradores: []
  },
  mutations: {
    set_accion_administradores (state, administradores) {
      state.administradores = administradores
    }
  },
  actions: {
    async getAdministradores ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/users/getAdminFlota`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAdministradores')
          // console.log(response.data)
          commit('set_accion_administradores', response.data)
          return true
        } else {
          console.log('por else getAdministradores')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAdministradores')
        router.push({ path: '/' })
        return false
      }
    },
    async createAdministradores ({ commit, dispatch, state }, admin) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/users/createAdminFlota`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: admin
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api createAdminFlota')
          console.log(response.data)
          commit('set_accion_administradores', response.data)
          return true
        } else {
          console.log('por else createAdministradores')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch createAdministradores')
        router.push({ path: '/' })
        return false
      }
    },
    async deleteAdministradores ({ commit, dispatch, state }, admin) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/vps/users/deleteAdminFlota/${admin.id}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api createAdminFlota')
          console.log(response.data)
          commit('set_accion_administradores', response.data)
          return true
        } else {
          console.log('por else createAdministradores')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch createAdministradores')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
    getterTransportes (state) {
      return state.transportes
    }
  }
}
