import { mapActions } from 'vuex'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'KPIStatusTransporte',
  components: {
    // BarChart
  },
  props: {
    KPIStatusObject: null
  },
  data: function () {
    return {
      KPIStatus: {
        en_ruta: 1,
        entregadas: 2,
        pendientes_salir: 3,
        anuladas: 4,
        sin_conductor: 5,
        sin_carga: 6,
        carga_no_asignada: 0
      }
      // en_ruta: 1,
      // entregadas: 2,
      // pendientes_salir: 3,
      // anuladas: 4,
      // sin_conductor: 5,
      // sin_carga: 6
    }
  },
  watch: {
    KPIStatusObject: function (val) {
      console.log('entra en observador KPIStatusObject')
      this.KPIStatus = this.KPIStatusObject
      if (this.KPIStatus !== '') {
        console.log(this.KPIStatus)
        this.updateKPI()
      }
      this.updateKPI()
      this.KPIStatus = ''
    }
  },
  mounted () {
    this.updateKPI()
  },
  methods: {
    ...mapActions([
    ]),
    updateKPI () {
      console.log('update')
      // this.en_ruta = this.KPIStatusObject.en_ruta
      // this.entregadas = this.KPIStatusObject.entregadas
      // this.pendientes_salir = this.KPIStatusObject.pendientes_salir
      // this.anuladas = this.KPIStatusObject.anuladas
      // this.sin_conductor = this.KPIStatusObject.sin_conductor
      // this.sin_carga = this.KPIStatusObject.sin_carga
    }
  },
  computed: {
  }
}
