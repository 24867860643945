import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
import FiltroReportes from '@/components/Layout/Menu/Filtros/Filtro_Reportes/FiltroReportes.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ReportesVeralia',
  components: {
    FiltroReportes
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      dataFiltroReporte: {},
      filtroEstadoEnable: false,
      filtroCamionEnable: false,
      filtroConductorEnable: false,
      filtroClienteEnable: false,
      filtroRevisionEnable: false
    }
  },
  watch: {
    dataFiltroReporte: function (val) {
      if (this.dataFiltroReporte === null || this.dataFiltroReporte === undefined) {
        return
      }
      console.log('observador dataFiltroReporte')
      console.log(this.dataFiltroReporte)
      const periodos = {}
      console.log(this.dataFiltroReporte !== null)
      periodos.fechaDesde = this.dataFiltroReporte.fechaDesde
      periodos.fechaHasta = this.dataFiltroReporte.fechaHasta
      if (this.dataFiltroReporte.cliente !== undefined && this.dataFiltroReporte.cliente !== null) periodos.cliente = this.dataFiltroReporte.cliente
      console.log(periodos)
      this.getReporteGenericoVeralia(this.dataFiltroReporte).then((res) => {
        console.log('cargo reporte veralia')
        this.items = []
        this.items = this.reporteGenericoVeralia
        console.log(this.items)
        this.totalRows = this.items.length
        this.setFields(this.items)
      })
      this.totalRows = this.items.length
    }
  },
  mounted () {
    const periodos = this.PeriodoFechaActual()
    this.getReporteGenericoVeralia(periodos).then((res) => {
      this.items = this.reporteGenericoVeralia
      console.log(this.items)
      console.log('cargo reporte verilia')
      this.totalRows = this.items.length
      this.setFields(this.items)
    })
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    ...mapActions('Reportes', [
      'getReporteEquiposSiniestros', 'getReporteGenericoVeralia'
    ]),
    setFields (data) {
      this.fields = []
      for (const d in data) {
        this.fields.push({
          key: data[d],
          label: data[d],
          sortable: false
        })
      }
    },
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants')) {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'reporte')
      XLSX.writeFile(wb, 'reporte_verilia.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    async getCargas (periodo) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/vueltas/getReportePorCarga`,
          data: {
            // fechaDesde: '2022-08-18 00:00:00',
            // fechaHasta: '2022-08-19 23:00:00'
            fechaDesde: periodo.fechaDesde,
            fechaHasta: periodo.fechaHasta
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log(response.data)
          return response.data
        } else {
          console.log('por else getCargas')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getCargas')
        router.push({ path: '/' })
        return false
      }
    },
    PeriodoFechaActual () {
      const date = new Date()
      const fechaActualPrimeraHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualUltimaHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '23'}:${
                '59'}:${
                  '59'}`
      console.log(fechaActualPrimeraHora)
      console.log(fechaActualUltimaHora)
      const periodo = {}
      periodo.fechaDesde = fechaActualPrimeraHora
      periodo.fechaHasta = fechaActualUltimaHora
      return periodo
    },
    test () {
      this.getCargas().then((res) => {
        this.items = res
        console.log('cargo')
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Reportes', ['reporteEquiposSiniestros']),
    ...mapState('Reportes', ['reporteGenericoVeralia']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
