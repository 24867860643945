import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    turnos: []
  },
  mutations: {
    set_accion_turnos (state, turnos) {
      state.turnos = turnos
    }
  },
  actions: {
    async getTurnos ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/turno`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getTurnos')
          // console.log(response.data)
          commit('set_accion_turnos', response.data)
          return true
        } else {
          console.log('por else getTurnos')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getTurnos')
        // router.push({ path: '/' })
        return false
      }
    },
    async setTurno ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/turno`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setTurno')
          // console.log(response)
          return response
        } else {
          console.log('por else setTurno')
          return response
        }
      } catch (err) {
        // console.error(err)
        console.log('por catch getTurnos')
        // router.push({ path: '/' })
        return err
      }
    },
    async deleteTurno ({ commit, dispatch, state }, idTurno) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/vps/turno?id_turno=${idTurno}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) {
          console.log('success stuff api deleteTurno')
          return response
        } else {
          console.log('por else deleteTurno')
          return response
        }
      } catch (err) {
        console.log('por catch deleteTurno')
        return err
      }
    },
    async setTurnoToDevice ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'put',
          url: `${store.state.host}/api/vps/users/${data.id_device}/turno/${data.id_turno}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) {
          console.log('success stuff api setTurnoToDevice')
          return response
        } else {
          console.log('por else setTurnoToDevice')
          return response
        }
      } catch (err) {
        console.log('por catch setTurnoToDevice')
        return err
      }
    }
  },
  getters: {
  }
}
