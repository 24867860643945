import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EliminarCamiones',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {},
      emitEliminaCamion: false
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    console.log(this.dataJSON)
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones', 'updateAdminCamion', 'deleteCamionConductor'
    ]),
    Eliminar () {
      // console.log('eliminar ' + this.dataJSON.id)
      this.deleteCamionConductor(this.dataJSON.id_relacion).then((res) => {
        console.log('cargo deleteCamionConductor')
        this.emitEliminaCamion = new Date()
        this.$emit('emitEliminaCamion', this.emitEliminaCamion)
      })
      // this.emitEliminaCamion = new Date()
      // this.$emit('emitEliminaCamion', this.emitEliminaCamion)
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Administrador', ['administradores'])
  }
}
