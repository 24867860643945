import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CrearCamiones',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      cam_basis: 0,
      descripcion: '',
      driver: 0,
      empresa: '',
      matricula: '',
      pallet: 0,
      proveedor: 0,
      administrador: '',
      selectedAdministrador: null,
      optionsAdministrador: [
        { value: null, text: '- Administrador' }
      ],
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: '- Conductor' }
      ]
    }
  },
  watch: {
  },
  mounted () {
    for (const administrador in this.administradores) {
      this.optionsAdministrador.push({ value: this.administradores[administrador].nombre, text: this.administradores[administrador].nombre })
    }
    this.optionsAdministrador.sort((a, b) => a.text.localeCompare(b.text))
    for (const conductor in this.conductores) {
      this.optionsConductor.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
    }
    this.optionsConductor.sort((a, b) => a.text.localeCompare(b.text))
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones', 'createCamion'
    ]),
    async updateClienteByCamion (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateClienteByCamion`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateClienteByCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateClienteByCamion')
        router.push({ path: '/' })
        return false
      }
    },
    async updateClienteByConductor (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateClienteByConductor`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateClienteByConductor')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateClienteByConductor')
        router.push({ path: '/' })
        return false
      }
    },
    async updateConductorByCamion (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateConductorByCamion`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateConductorByCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateConductorByCamion')
        router.push({ path: '/' })
        return false
      }
    },
    crearCamion () {
      const camion = {}
      camion.cam_basis = parseInt(this.cam_basis)
      camion.descripcion = this.descripcion
      camion.driver = parseInt(this.driver)
      camion.empresa = this.empresa
      camion.matricula = this.matricula
      camion.pallet = parseInt(this.pallet)
      camion.proveedor = parseInt(this.proveedor)
      camion.administrador = this.selectedAdministrador
      camion.conductor = this.selectedConductor
      console.log(camion)
      this.createCamion(camion).then((res) => {
        this.$emit('emitEditCamion', camion)
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Administrador', ['administradores'])
  }
}
