import { render, staticRenderFns } from "@/components/Layout/Menu/Common/Imagenes/Imagenes.html?vue&type=template&id=2b786748&scoped=true&"
import script from "@/components/Layout/Menu/Common/Imagenes/Imagenes.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/Common/Imagenes/Imagenes.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/Common/Imagenes/Imagenes.css?vue&type=style&index=0&id=2b786748&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b786748",
  null
  
)

export default component.exports