import { render, staticRenderFns } from "@/components/Layout/Menu/Administracion/Administracion_administradores/Crear_administrador/Crear_administrador.html?vue&type=template&id=2827053b&scoped=true&"
import script from "@/components/Layout/Menu/Administracion/Administracion_administradores/Crear_administrador/Crear_administrador.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/Administracion/Administracion_administradores/Crear_administrador/Crear_administrador.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/Administracion/Administracion_administradores/Crear_administrador/Crear_administrador.css?vue&type=style&index=0&id=2827053b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2827053b",
  null
  
)

export default component.exports