import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    ayudantes: [],
    ayudantesBuk: []
  },
  mutations: {
    set_accion_ayudantes (state, ayudantes) {
      state.ayudantes = ayudantes
    },
    set_accion_ayudantesBuk (state, ayudantes) {
      state.ayudantesBuk = ayudantes
    }
  },
  actions: {
    async getAyudantes ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/ayudantes/getAll`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAyudantes')
          console.log(response.data)
          commit('set_accion_ayudantes', response.data)
          return true
        } else {
          console.log('por else getAyudantes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAyudantes')
        router.push({ path: '/' })
        return false
      }
    },
    async insertAyudantes ({ commit, dispatch, state }, ayudantes) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/ayudantes/insertAll`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: ayudantes
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api insertAyudantes')
          console.log(response.data)
          commit('set_accion_ayudantes', response.data)
          return true
        } else {
          console.log('por else insertAyudantes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch insertAyudantes')
        router.push({ path: '/' })
        return false
      }
    },
    async deleteAyudanteById ({ commit, dispatch, state }, idAyudante) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/vps/ayudantes/delete/${idAyudante}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api deleteAyudanteById')
          console.log(response.data)
          commit('set_accion_ayudantes', response.data)
          return true
        } else {
          console.log('por else deleteAyudanteById')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch deleteAyudanteById')
        router.push({ path: '/' })
        return false
      }
    },
    async getAyudantesBuk ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/buk/ayudantesBuk?role_code=ayudante`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAyudantesBuk')
          console.log(response.data)
          commit('set_accion_ayudantesBuk', response.data)
          return true
        } else {
          console.log('por else getAyudantesBuk')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAyudantesBuk')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
