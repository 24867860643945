import { mapActions } from 'vuex'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  name: 'Imagenes',
  components: {
  },
  props: {
    transportes: null
  },
  data: function () {
    return {
      listaLinksTransporte1: [],
      listaLinksTransporte2: [],
      listaLinksTransporte3: [],
      listaLinksTransporte4: [],
      listaLinksTransporte5: [],
      imageBytes1: ''
    }
  },
  watch: {
  },
  mounted () {
    console.log(this.transportesJSON)
    if (this.transportesJSON.estado1 !== undefined && this.transportesJSON.estado1 !== null && this.transportesJSON.estado1 !== '') {
      switch (this.transportesJSON.estado1) {
        case 'entregada':
          console.log('1 entregada')
          this.getListaImagenes(this.transportesJSON.trans1).then((res) => {
            console.log('cargado lista imagenes transporte 1')
            this.listaLinksTransporte1 = res
            console.log(res)
          })
          break
      }
    }
    if (this.transportesJSON.estado2 !== undefined && this.transportesJSON.estado2 !== null && this.transportesJSON.estado2 !== '') {
      switch (this.transportesJSON.estado2) {
        case 'entregada':
          console.log('2 entregada')
          this.getListaImagenes(this.transportesJSON.trans2).then((res) => {
            console.log('cargado lista imagenes transporte 2')
            this.listaLinksTransporte2 = res
            console.log(res)
          })
          break
      }
    }
    if (this.transportesJSON.estado3 !== undefined && this.transportesJSON.estado3 !== null && this.transportesJSON.estado3 !== '') {
      switch (this.transportesJSON.estado3) {
        case 'entregada':
          console.log('3 entregada')
          this.getListaImagenes(this.transportesJSON.trans3).then((res) => {
            console.log('cargado lista imagenes transporte 3')
            this.listaLinksTransporte3 = res
            console.log(res)
          })
          break
      }
    }
    if (this.transportesJSON.estado4 !== undefined && this.transportesJSON.estado4 !== null && this.transportesJSON.estado4 !== '') {
      switch (this.transportesJSON.estado4) {
        case 'entregada':
          console.log('4 entregada')
          this.getListaImagenes(this.transportesJSON.trans4).then((res) => {
            console.log('cargado lista imagenes transporte 4')
            this.listaLinksTransporte4 = res
            console.log(res)
          })
          break
      }
    }
    if (this.transportesJSON.estado5 !== undefined && this.transportesJSON.estado5 !== null && this.transportesJSON.estado5 !== '') {
      switch (this.transportesJSON.estado5) {
        case 'entregada':
          console.log('5 entregada')
          this.getListaImagenes(this.transportesJSON.trans5).then((res) => {
            console.log('cargado lista imagenes transporte 5')
            this.listaLinksTransporte5 = res
            console.log(res)
          })
          break
      }
    }
  },
  methods: {
    ...mapActions([
    ]),
    async getListaImagenes (transporteId) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/files/listarImagenes?transporte=` + transporteId,
          data: {
            transporte: transporteId
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log(response.data)
          return response.data
        } else {
          console.log('por else getListaImagenes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getListaImagenes')
        router.push({ path: '/' })
        return false
      }
    },
    async getImageneBase64 (transporteId) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/files/imagenToBase64?transporte=` + transporteId,
          data: {
            transporte: transporteId
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log(response.data)
          this.imageBytes1 = response.data
          return response.data
        } else {
          console.log('por else getImageneBase64')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getImageneBase64')
        router.push({ path: '/' })
        return false
      }
    },
    descargaImagen (transporte) {
      transporte = transporte.replace('.png', '')
      console.log(transporte)
      const win = window.open(`${store.state.host}/api/vps/files/descargarImages?transporte=${transporte}`, '_blank')
      // Cambiar el foco al nuevo tab (punto opcional)
      win.focus()
    },
    test () {
      console.log('test imagenes')
    }
  },
  computed: {
    transportesJSON () {
      const transporteJSON = JSON.parse(this.transportes)
      return transporteJSON
    }
  }
}
