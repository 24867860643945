import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'

export default {
  name: 'AsignarTurno',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      cam_basis: 0,
      descripcion: '',
      driver: 0,
      empresa: '',
      matricula: '',
      pallet: 0,
      proveedor: 0,
      administrador: '',
      selectedAdministrador: null,
      optionsAdministrador: [
        { value: null, text: '- Administrador' }
      ],
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: '- Conductor' }
      ],
      selectedTurno: null,
      optionsTurno: [
        { value: null, text: '- Turno' }
      ]
    }
  },
  watch: {
  },
  mounted () {
    this.optionsAdministrador.sort((a, b) => a.text.localeCompare(b.text))
    for (const conductor in this.conductores) {
      this.optionsConductor.push({ value: this.conductores[conductor].id, text: this.conductores[conductor].device_alias })
    }
    this.optionsConductor.sort((a, b) => a.text.localeCompare(b.text))
    this.getTurnos().then((res) => {
      this.optionsTurno.sort((a, b) => a.text.localeCompare(b.text))
      for (const turno in this.turnos) {
        this.optionsTurno.push({ value: this.turnos[turno].id, text: this.turnos[turno].clasificacion })
      }
      this.optionsTurno.sort((a, b) => a.text.localeCompare(b.text))
    })
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getConductores'
    ]),
    ...mapActions('Turno', [
      'getTurnos', 'setTurnoToDevice'
    ]),
    asignar () {
      console.log(this.selectedConductor)
      console.log(this.selectedTurno)
      const data = {
        id_turno: this.selectedTurno,
        id_device: this.selectedConductor
      }
      this.setTurnoToDevice(data).then((res) => {
        console.log(res)
        if (res.data !== undefined && res.status !== undefined && res.status === 200) {
          this.$emit('emitEditCamion', new Date())
        } else if (res.response !== undefined && res.response.status === 403) {
          const toast = {}
          toast.body = 'No tienes los permisos para ejecutar esta accion.'
          toast.title = this.usuariosUpdate.response.statusText
          toast.variant = 'danger'
          this.makeToast(toast)
        }
        if (res.response !== undefined && res.response.status === 400) {
          const toast = {}
          toast.body = res.response.data
          toast.title = 'Error'
          toast.variant = 'danger'
          this.makeToast(toast)
        }
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Turno', ['turnos']),
    ...mapState('AdministradorFlota', ['conductores'])
  }
}
