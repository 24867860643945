import { mapActions, mapState } from 'vuex'
import VisitasClienteView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Visitas_cliente/VisitasClienteView.vue'
import FiltroAdminFlota from '@/components/Layout/Menu/Filtros/Filtro_AdminFlota/FiltroAdminFlota.vue'
import EditarTransporteView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/EditarTransporteView.vue'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AdministradorFlotaNoProgramado',
  components: {
    VisitasClienteView,
    FiltroAdminFlota,
    EditarTransporteView
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        { key: 'conductor', label: 'Conductor', sortable: true, sortDirection: 'desc' },
        { key: 'cam_basis', label: 'Patente', sortable: true, sortDirection: 'desc' },
        // { key: 'fecha', label: 'Fecha', sortable: true, sortDirection: 'desc' },
        { key: 'hora_update', label: 'Hora', sortable: true, sortDirection: 'desc' },
        { key: 'cliente', label: 'Cliente', sortable: true, sortDirection: 'desc' },
        { key: 'estado', label: 'Estado', sortable: true, sortDirection: 'desc' },
        { key: 'transporte', label: 'Transporte', sortable: true, sortDirection: 'desc' },
        { key: 'vuelta', label: 'Vuelta', sortable: true, sortDirection: 'desc' },
        { key: 'confirmacion_admin', label: 'Confirmacion Admin', sortable: true, sortDirection: 'desc' },
        { key: 'editar', label: 'Editar', sortable: true, sortDirection: 'desc' }
      ],
      totalRows: 1,
      currentPage: 1,
      page: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Muchos Registros' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      totalTransportesPage: 0,
      totalPaginasTransportesPage: 0,
      totalEntregadas: 0,
      totalEnRuta: 0,
      totalNoRealizado: 0,
      totalPendienteSalir: 0,
      visitasModal: {
        id: 'visitar-modal',
        title: 'Visitas Cliente',
        content: ''
      },
      editarModal: {
        id: 'editar-modal',
        title: 'Editar Transporte',
        content: ''
      },
      dataFiltroReporte: {},
      filtroPatenteEnable: true,
      filtroAdministradorFlotaEnable: true,
      filtroClienteEnable: true,
      filtroConductorEnable: true,
      filtroConfirmacionAdminEnable: true,
      filtroEstadoEnable: true,
      selectedConfirmAdmin: null,
      optionsConfirmAdmin: [
        { value: null, text: '- Estado Confirmacion' },
        { value: 'P', text: 'Por Revisar' },
        { value: 'R', text: 'Revisada' },
        { value: 'E', text: 'Eliminada' }
      ],
      clientes: []
    }
  },
  watch: {
    dataFiltroReporte: function (val) {
      // console.log(this.dataFiltroReporte)
      if (this.dataFiltroReporte !== null) {
        if (this.dataFiltroReporte.fechaDesde === null || this.dataFiltroReporte.fechaHasta === null) {
          const periodo = this.periodoDiaActual()
          const filter = {
            administrador: this.dataFiltroReporte.administrador,
            patente: this.dataFiltroReporte.patente,
            fechaDesde: periodo.fechaDesde,
            fechaHasta: periodo.fechaHasta,
            cliente: periodo.cliente,
            conductor: periodo.conductor,
            confirmacion_admin: periodo.confirmacionAdmin,
            estado: periodo.estado
          }
          const data = this.nextFilterPage(filter)
          this.initFilter(data)
        } else {
          const data = this.nextFilterPage(this.dataFiltroReporte)
          this.initFilter(data)
        }
      }
    }
  },
  mounted () {
    const data = this.nextPageV2(this.periodoDiaActual())
    this.init(data)
    this.getAdministradores().then((res) => {
      // console.log(this.administradores)
    })
    this.getConductores().then((res) => {
      // console.log('cargado conductores')
    })
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones', 'getTransportesPage', 'getTransportesFilterPage', 'confirmacionAdmin', 'countByEstadoTransportes', 'getTransportesV2FilterPage'
    ]),
    ...mapActions('Administrador', [
      'getAdministradores', 'createAdministradores', 'deleteAdministradores'
    ]),
    nextPage (fecha) {
      const data = {
        page: (this.page - 1),
        size: this.perPage,
        periodos: fecha,
        form_code: 'V06'
      }
      return data
    },
    nextPageV2 (fecha) {
      this.permisos.forEach(element => {
        if (element.id_tipo_permiso === 2) {
          this.clientes.push(element.nombre)
        }
      })
      console.log(this.clientes)
      const data = {
        page: (this.page - 1),
        size: this.perPage,
        fechaDesde: fecha.fechaDesde,
        fechaHasta: fecha.fechaHasta,
        form_code: 'V06',
        clientes: this.clientes
      }
      return data
    },
    nextFilterPage (filter) {
      if ((filter.conductor !== null && filter.conductor !== undefined) ||
      (filter.confirmacionAdmin !== null && filter.confirmacionAdmin !== undefined)) {
        this.page = 1
      }
      const data = {
        page: (this.page - 1),
        size: this.perPage,
        filter: {
          fechaDesde: filter.fechaDesde,
          fechaHasta: filter.fechaHasta,
          form_code: 'V06',
          patente: filter.patente,
          administrador: filter.administrador,
          cliente: filter.cliente,
          conductor: filter.conductor,
          confirmacion_admin: filter.confirmacionAdmin,
          estado: filter.estado
        }
      }
      return data
    },
    getContadorTransportes (fecha) {
      this.countByEstadoTransportes(fecha).then((res) => {
        this.totalEntregadas = this.contadorTransportes.entregada
        this.totalEnRuta = this.contadorTransportes.en_ruta
        this.totalNoRealizado = this.contadorTransportes.no_realizado
        this.totalPendienteSalir = this.contadorTransportes.pendiente_salir
      })
    },
    init (data) {
      const fecha = this.periodoDiaActual()
      this.getContadorTransportes(fecha)
      console.log(data)
      this.getTransportesV2FilterPage(data).then((res) => {
        // console.log(this.transportesPage)
        // this.totalTransportesPage = this.transportesPage.totalElements
        this.totalTransportesPage = this.totalEntregadas + this.totalEnRuta + this.totalNoRealizado + this.totalPendienteSalir
        this.totalPaginasTransportesPage = this.transportesPage.totalPages
        this.items = []
        this.items = this.transportesPage.content
        // console.log(this.items)
        for (const item in this.items) {
          // this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
          if (this.items[item].estado === 'entregada') {
            this.items[item]._cellVariants = {
              estado: 'success'
            }
          } else if (this.items[item].estado === 'no realizado') {
            this.items[item]._cellVariants = {
              estado: 'danger'
            }
          } else if (this.items[item].estado === 'en ruta') {
            this.items[item]._cellVariants = {
              estado: 'warning'
            }
          } else {
            this.items[item]._cellVariants = {
              estado: 'info'
            }
          }
        }
        // console.log(this.items)
      })
    },
    // si existe filtro de cliente lo agrega a clientes como lista, si existe filtro se agrega los clientes del permiso
    addClientesFilter (data) {
      if (data.cliente === null || data.cliente === undefined) {
        data.clientes = this.clientes
      } else {
        data.clientes = [data.cliente]
      }
      return data
    },
    initFilter (data) {
      this.getContadorTransportes(this.dataFiltroReporte)
      this.getTransportesFilterPage(this.addClientesFilter(data)).then((res) => {
        // console.log(this.transportesPage)
        // this.totalTransportesPage = this.transportesPage.totalElements
        this.totalTransportesPage = this.totalEntregadas + this.totalEnRuta + this.totalNoRealizado + this.totalPendienteSalir
        this.totalPaginasTransportesPage = this.transportesPage.totalPages
        this.items = []
        this.items = this.transportesPage.content
        for (const item in this.items) {
          // this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
          if (this.items[item].estado === 'entregada') {
            this.items[item]._cellVariants = {
              estado: 'success'
            }
          } else if (this.items[item].estado === 'no realizado') {
            this.items[item]._cellVariants = {
              estado: 'danger'
            }
          } else if (this.items[item].estado === 'en ruta') {
            this.items[item]._cellVariants = {
              estado: 'warning'
            }
          } else {
            this.items[item]._cellVariants = {
              estado: 'info'
            }
          }
        }
      })
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handlePageChange (value) {
      this.page = value
      if (this.dataFiltroReporte.fechaDesde !== undefined || this.dataFiltroReporte.patente !== undefined) {
        // const filter = this.dataFiltroReporte
        // filter.form_code = 'V06'
        const data = this.nextFilterPage(this.dataFiltroReporte)
        this.initFilter(data)
      } else {
        const data = this.nextPageV2(this.periodoDiaActual())
        this.init(data)
      }
    },
    modalVisitasEnable (item, index, button) {
      // this.visitasModal.title = `Row index: ${index}`
      this.visitasModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.visitasModal.id, button)
    },
    hideModalmodalVisitas () {
      this.$refs[this.visitasModal.id].hide()
    },
    modalEditarsEnable (item, index, button) {
      // this.visitasModal.title = `Row index: ${index}`
      this.editarModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editarModal.id, button)
    },
    hideModalEditar () {
      this.$refs[this.editarModal.id].hide()
    },
    emitEditarTransporte (data) {
      if (this.dataFiltroReporte !== null) {
        const data = this.nextFilterPage(this.dataFiltroReporte)
        this.initFilter(data)
      } else {
        const data = this.nextPageV2(this.periodoDiaActual())
        this.init(data)
      }
      this.hideModalEditar()
    },
    periodoDiaActual () {
      const date = new Date()
      const fechaActualInicio = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualFin = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '23'}:${
                '59'}:${
                  '59'}`
      const periodo = {
        fechaDesde: fechaActualInicio,
        fechaHasta: fechaActualFin
      }
      // console.log(periodo)
      return periodo
    },
    checkConfirmAdmin (item, index, button) {
      if (item.confirmacion_admin === null) {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Seleccionar algun valor'
        this.makeToast(toast)
        return
      }
      const data = {
        transporte_id: item.id,
        confirmacion_admin: item.confirmacion_admin
      }
      this.confirmacionAdmin(data).then((res) => {
        const toast = {}
        toast.title = 'Exito'
        toast.variant = 'success'
        toast.body = 'Estado Actualizado'
        this.makeToast(toast)
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('AdministradorFlota', ['contadorTransportes']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('AdministradorFlota', ['transportesPage']),
    ...mapState('Administrador', ['administradores']),
    ...mapState('Permisos', ['permisos']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
