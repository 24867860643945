import { render, staticRenderFns } from "@/components/Layout/Menu/Gestores/Siniestros/Siniestros.html?vue&type=template&id=07dce61c&scoped=true&"
import script from "@/components/Layout/Menu/Gestores/Siniestros/Siniestros.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/Gestores/Siniestros/Siniestros.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/Gestores/Siniestros/Siniestros.css?vue&type=style&index=0&id=07dce61c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07dce61c",
  null
  
)

export default component.exports