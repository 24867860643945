import { mapActions, mapState } from 'vuex'

export default {
  name: 'EditarPatentes',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {},
      cam_basis: null,
      descripcion: null,
      driver: null,
      empresa: null,
      vigente: null,
      id: null
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    this.cam_basis = this.dataJSON.cam_basis
    this.descripcion = this.dataJSON.descripcion
    this.driver = this.dataJSON.driver
    this.empresa = this.dataJSON.empresa
    this.vigente = this.dataJSON.vigente !== null && this.dataJSON.vigente !== undefined ? this.dataJSON.vigente : false
    this.id = this.dataJSON.id
    // console.log(this.dataJSON)
  },
  methods: {
    ...mapActions('Cliente', [
      'insertClientes', 'deleteClientesById', 'getClientes', 'updateById'
    ]),
    editar () {
      console.log(this.cam_basis)
      if (this.cam_basis === null || this.cam_basis === '' || this.cam_basis === undefined) {
        const toast = {}
        toast.title = 'Error'
        toast.body = 'Se debe ingresar Nro Cam'
        toast.variant = 'danger'
        this.makeToast(toast)
        // return
      }
      const data = {
        cam_basis: this.cam_basis,
        descripcion: this.descripcion,
        driver: this.driver,
        empresa: this.empresa,
        vigente: this.vigente,
        id: this.id
      }
      console.log(data)
      // if (this.nombre === 'Andina' && this.dataJSON.nombre !== 'Andina') {
      //   const toast = {}
      //   toast.title = 'Error'
      //   toast.body = 'El nombre no puede ser Andina (nombre reservado)'
      //   toast.variant = 'danger'
      //   this.makeToast(toast)
      //   this.nombre = null
      // }
      // const camion = {}
      // if (this.nombre !== null) cliente.nombre = this.nombre
      // if (this.descripcion !== null) cliente.descripcion = this.descripcion
      // cliente.id = this.dataJSON.id
      // this.updateById(camion).then((res) => {
      //   this.$emit('emitEditCliente', new Date())
      // })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Cliente', ['clientes'])
  }
}
