import { mapActions, mapState } from 'vuex'
import ImagenesView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Visitas_cliente/Imagenes/ImagenesView.vue'
import EditarFieldView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/Editar_field/EditarFieldView.vue'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EditarAndinaSupermercadoView',
  components: {
    ImagenesView,
    EditarFieldView
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: [],
      activityid: null,
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: '- Conductor' }
      ],
      selectedCamion: null,
      optionsCamion: [
        { value: null, text: '- Camion' }
      ],
      selectedEstado: null,
      optionsEstado: [
        { value: null, text: '- Estado' },
        { value: 'entregada', text: 'entregada' },
        { value: 'no realizado', text: 'no realizado' }
      ],
      conductorId: null,
      camionId: null,
      andinaSupermercado_id: null,
      editarModal: {
        id: 'editar-andinaSupermercado-modal',
        title: 'Editar Campos',
        content: ''
      },
      fieldView: true,
      fecha: null,
      hora: null,
      fechaHora: null,
      patente: null,
      conductor: null
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    this.andinaSupermercado_id = this.dataJSON.id
    this.activityid = this.dataJSON.activity.activityid
    this.getFechaHora(this.dataJSON.fecha)
    this.conductor = this.dataJSON.conductor
    this.patente = this.dataJSON.patente
    this.getCamiones().then((res) => {
      for (const camion in this.camiones) {
        this.optionsCamion.push({ value: this.camiones[camion].matricula, text: this.camiones[camion].matricula + ' / ' + this.camiones[camion].cam_basis })
      }
      this.optionsCamion.sort((a, b) => a.text.localeCompare(b.text))
    })
    this.getConductores().then((res) => {
      for (const conductor in this.conductores) {
        if (this.conductores[conductor].device_alias === this.dataJSON.conductor) {
          this.selectedConductor = this.conductores[conductor].id
          this.conductorId = this.conductores[conductor].id
        }
        this.optionsConductor.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
      }
      this.optionsConductor.sort((a, b) => a.text.localeCompare(b.text))
    })
    this.EditarEnable()
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getCamiones', 'getConductores'
    ]),
    ...mapActions('AndinaSupermercado', [
      'updateAndinaSupermercado'
    ]),
    modalEditarsEnable (item, index, button) {
      // this.visitasModal.title = `Row index: ${index}`
      this.editarModal.id = 'editar-modal' + Date.now()
      const data = {
        activity: {
          activityid: this.activityid
        }
      }
      this.editarModal.content = JSON.stringify(data)
      this.$root.$emit('bv::show::modal', this.editarModal.id, button)
    },
    EditarEnable (item, index, button) {
      // this.visitasModal.title = `Row index: ${index}`
      const data = {
        activity: {
          activityid: this.activityid
        }
      }
      // console.log(data)
      this.editarModal.content = JSON.stringify(data)
    },
    hideModalEditar () {
      this.$refs[this.editarModal.id].hide()
    },
    emitEditarField (data) {
      console.log('emitEditarAndinaSupermercadoField')
      this.hideModalEditar()
    },
    editar () {
      const data = {}
      data.id = this.andinaSupermercado_id
      const updateFecha = this.fecha + ' ' + this.hora
      if (this.fechaHora !== updateFecha) {
        // console.log('el cliente no es el mismo')
        data.fecha = updateFecha
      }
      if (this.patente !== this.dataJSON.patente) {
        // console.log('el cliente no es el mismo')
        data.patente = this.patente
      }
      if (this.conductor !== this.dataJSON.conductor) {
        // console.log('el cliente no es el mismo')
        data.conductor = this.conductor
      }
      console.log(data)
      this.updateAndinaSupermercado(data).then((res) => {
        const toast = {}
        toast.title = 'Andina Supermercado Actualizado Con Exito.'
        toast.variant = 'success'
        toast.body = 'Exito.'
        this.makeToast(toast)
        this.$emit('emitEditarAndinaSupermercado', data)
      })
    },
    getFechaHora (data) {
      // data = '2023-10-14T03:06:35.000+00:00'
      // Crear un objeto Date a partir de la cadena
      const dateObj = new Date(data)
      // Obtener la fecha en el formato "yyyy-mm-dd"
      const year = dateObj.getFullYear()
      const month = String(dateObj.getMonth() + 1).padStart(2, '0') // Agregar ceros a la izquierda si es necesario
      const day = String(dateObj.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}`
      // Obtener la hora en el formato "hh:mm:ss"
      const hours = String(dateObj.getUTCHours()).padStart(2, '0')
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0')
      const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0')
      const formattedTime = `${hours}:${minutes}:${seconds}`
      // console.log('Fecha:', formattedDate)
      // console.log('Hora:', formattedTime)
      this.fecha = formattedDate
      this.hora = formattedTime
      this.fechaHora = formattedDate + ' ' + formattedTime
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores'])
  }
}
