import { mapActions } from 'vuex'

export default {
  name: 'CrearTurno',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      hour_in: '',
      hour_out: '',
      clasificacion: ''
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    ...mapActions('Turno', [
      'setTurno'
    ]),
    crearTurno () {
      if (this.hour_in === null || this.hour_in === '') {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Se debe seleccionar hora de entrada'
        this.makeToast(toast)
        return
      }
      if (this.hour_out === null || this.hour_out === '') {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Se debe ingresar hora de salida'
        this.makeToast(toast)
        return
      }
      if (this.clasificacion === null || this.clasificacion === '') {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Se debe ingresar clasificacion'
        this.makeToast(toast)
        return
      }
      const turno = {}
      turno.hour_in = this.hour_in
      turno.hour_out = this.hour_out
      turno.clasificacion = this.clasificacion
      // console.log(turno)
      this.setTurno(turno).then((res) => {
        // console.log(res)
        if (res.data !== undefined && res.status !== undefined && res.status === 200) {
          this.$emit('emitCreateTurno', new Date())
        } else if (res.response !== undefined && res.response.status === 403) {
          const toast = {}
          toast.body = 'No tienes los permisos para ejecutar esta accion.'
          toast.title = this.usuariosUpdate.response.statusText
          toast.variant = 'danger'
          this.makeToast(toast)
        }
        if (res.response !== undefined && res.response.status === 400) {
          const toast = {}
          toast.body = res.response.data
          toast.title = 'Error'
          toast.variant = 'danger'
          this.makeToast(toast)
        }
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
  }
}
