import { render, staticRenderFns } from "@/components/Layout/Menu/Reasignacion_transporte/Reasignacion_transporte.html?vue&type=template&id=dee78738&scoped=true&"
import script from "@/components/Layout/Menu/Reasignacion_transporte/Reasignacion_transporte.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/Reasignacion_transporte/Reasignacion_transporte.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/Reasignacion_transporte/Reasignacion_transporte.css?vue&type=style&index=0&id=dee78738&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dee78738",
  null
  
)

export default component.exports