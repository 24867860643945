import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    clientes: [],
    conductoresAsignadosCliente: [],
    patentesAsignadosCliente: []
  },
  mutations: {
    set_accion_clientes (state, clientes) {
      state.clientes = clientes
    },
    set_conductores_asignados_cliente (state, conductoresAsignadosCliente) {
      state.conductoresAsignadosCliente = conductoresAsignadosCliente
    },
    set_patentes_asignados_cliente (state, patentesAsignadosCliente) {
      state.patentesAsignadosCliente = patentesAsignadosCliente
    }
  },
  actions: {
    async getClientes ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/clientes/getAll`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getClientes')
          // console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else getClientes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getClientes')
        router.push({ path: '/' })
        return false
      }
    },
    async insertClientes ({ commit, dispatch, state }, cliente) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/clientes/insert`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: cliente
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api insertClientes')
          console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else insertClientes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch insertClientes')
        router.push({ path: '/' })
        return false
      }
    },
    async deleteClientesById ({ commit, dispatch, state }, idCliente) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/vps/clientes/delete/${idCliente}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api deleteClientesById')
          console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else deleteClientesById')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch deleteClientesById')
        router.push({ path: '/' })
        return false
      }
    },
    async updateById ({ commit, dispatch, state }, cliente) {
      try {
        const response = await axios({
          method: 'put',
          url: `${store.state.host}/api/vps/clientes/updateById`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: cliente
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateById')
          console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else updateById')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateById')
        router.push({ path: '/' })
        return false
      }
    },
    async getConductoresByCliente ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/clientes/conductores`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getClientes')
          // console.log(response.data)
          commit('set_conductores_asignados_cliente', response.data)
          return true
        } else {
          console.log('por else getConductoresByCliente')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getConductoresByCliente')
        router.push({ path: '/' })
        return false
      }
    },
    async getPatentesByCliente ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/clientes/patentes`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getClientes')
          // console.log(response.data)
          commit('set_patentes_asignados_cliente', response.data)
          return true
        } else {
          console.log('por else getPatentesByCliente')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getPatentesByCliente')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
