import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'
import axios from 'axios'
import AdministradorFlota from '@/modules/AdministradorFlota.js'
import KPIStatusTransporte from '@/modules/KPIStatusTransporte.js'
import Administrador from '@/modules/Administrador.js'
import Cliente from '@/modules/Cliente.js'
import Patentes from '@/modules/Patentes.js'
import Archivos from '@/modules/Archivos.js'
import Ayudantes from '@/modules/Ayudantes.js'
import Imagenes from '@/modules/Imagenes.js'
import Usuario from '@/modules/Usuario.js'
import Reportes from '@/modules/Reportes.js'
import Turno from '@/modules/Turno.js'
import UsuariosBuk from '@/modules/UsuariosBuk.js'
import Permisos from '@/modules/Permisos.js'
import Siniestros from '@/modules/Siniestros.js'
import AndinaSupermercado from '@/modules/AndinaSupermercado.js'
import router from '@/router/'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: '',
    password: '',
    token: 'token',
    // host: 'localhost:8095'
    host: 'https://app.vps.dmetrix.cl',
    hostAws: 'https://api.appdmetrix.com'
  },
  getters: {
    Gettertoken (state) {
      return state.token
    }
  },
  mutations: {
    set_token (state, autorizacion) {
      state.token = autorizacion.token
      state.user = autorizacion.user
      state.password = autorizacion.password
    }
  },
  actions: {
    async GetToken ({ commit, dispatch, state }, credenciales) {
      const autorizacion = {}
      try {
        const response = await axios.post(`${store.state.host}/api/login/auth/iniciarSesion`, credenciales)
        // const response = await axios.post(`http://${store.state.host}/api/auth/iniciarSesion`, credenciales)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff')
          autorizacion.token = response.data.tokenDeAcceso
          autorizacion.user = credenciales.usernameOrEmail
          autorizacion.password = credenciales.password
          commit('set_token', autorizacion)
          // dispatch('GetRol', credenciales.usernameOrEmail).then((res) => {
          //   console.log('cargado GetRol index')
          //   router.push({ path: 'menu' })
          // })
          // window.location.href = 'http://www.google.com'
          router.push({ path: 'Menu' })
          return true
        } else {
          autorizacion.user = ''
          autorizacion.password = ''
          autorizacion.token = 'clave o usuario invalido'
          commit('set_token', autorizacion)
          console.log('por else')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch')
        autorizacion.user = ''
        autorizacion.password = ''
        autorizacion.token = 'clave o usuario invalido'
        commit('set_token', autorizacion)
        return false
      }
    }
  },
  modules: {
    AdministradorFlota,
    KPIStatusTransporte,
    Administrador,
    Cliente,
    Patentes,
    Archivos,
    Ayudantes,
    Imagenes,
    Usuario,
    Reportes,
    Turno,
    UsuariosBuk,
    Permisos,
    Siniestros,
    AndinaSupermercado
  }
})
