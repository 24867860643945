import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    andinaSupermercados: [],
    estadosAndinaSupermercado: [],
    andinaSupermercadoReports: []
  },
  mutations: {
    set_accion_andinaSupermercados (state, andinaSupermercados) {
      state.andinaSupermercados = andinaSupermercados
    },
    set_accion_andinaSupermercadoReports (state, andinaSupermercadoReports) {
      state.andinaSupermercadoReports = andinaSupermercadoReports
    },
    set_accion_estados (state, estadosAndinaSupermercado) {
      state.estadosAndinaSupermercado = estadosAndinaSupermercado
    }
  },
  actions: {
    async getaAndinaSupermercados ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/andinaSupermercado`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getaAndinaSupermercados')
          // console.log(response.data)
          commit('set_accion_andinaSupermercados', response.data)
          return true
        } else {
          console.log('por else getaAndinaSupermercados')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getaAndinaSupermercados')
        // router.push({ path: '/' })
        return false
      }
    },
    async getAndinaSupermercadoFilter ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/andinaSupermercado/filter?page=${data.page}&size=${data.size}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAndinaSupermercadoFilter')
          // console.log(response.data)
          commit('set_accion_andinaSupermercados', response.data)
          return true
        } else {
          console.log('por else getAndinaSupermercadoFilter')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAndinaSupermercadoFilter')
        // router.push({ path: '/' })
        return false
      }
    },
    async getAndinaSupermercadoReports ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/andinaSupermercado/report`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAndinaSupermercadoReports')
          // console.log(response.data)
          commit('set_accion_andinaSupermercadoReports', response.data)
          return true
        } else {
          console.log('por else getAndinaSupermercadoReports')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAndinaSupermercadoReports')
        // router.push({ path: '/' })
        return false
      }
    },
    async updateEstadoAndinaSupermercado ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/andinaSupermercado/updateEstado?estado=${data.estado}&id_andinaSupermercado=${data.id_andinaSupermercado}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateEstadoAndinaSupermercado')
          // console.log(response)
          return response
        } else {
          console.log('por else updateEstadoAndinaSupermercado')
          return response
        }
      } catch (err) {
        // console.error(err)
        console.log('por catch updateEstadoAndinaSupermercado')
        // router.push({ path: '/' })
        return err
      }
    },
    async updateAndinaSupermercado ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/andinaSupermercado/update`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateAndinaSupermercado')
          // console.log(response)
          return response
        } else {
          console.log('por else updateAndinaSupermercado')
          return response
        }
      } catch (err) {
        // console.error(err)
        console.log('por catch updateAndinaSupermercado')
        // router.push({ path: '/' })
        return err
      }
    },
    async deleteAndinaSupermercado ({ commit, dispatch, state }, idAndinaSupermercado) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/vps/andinaSupermercado?id_andinaSupermercado=${idAndinaSupermercado}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) {
          console.log('success stuff api deleteAndinaSupermercado')
          return response
        } else {
          console.log('por else deleteAndinaSupermercado')
          return response
        }
      } catch (err) {
        console.log('por catch deleteAndinaSupermercado')
        return err
      }
    },
    async getEstadosAndinaSupermercado ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/andinaSupermercado/estados`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) {
          console.log('success stuff api getEstadosAndinaSupermercado')
          commit('set_accion_estados', response.data)
          return response
        } else {
          console.log('por else getEstadosAndinaSupermercado')
          return response
        }
      } catch (err) {
        console.log('por catch getEstadosAndinaSupermercado')
        return err
      }
    }
  },
  getters: {
  }
}
