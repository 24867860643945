import { mapActions, mapState } from 'vuex'
import ImagenesView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Visitas_cliente/Imagenes/ImagenesView.vue'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'VisitasClienteView',
  components: {
    ImagenesView
  },
  props: {
    data: null
  },
  data: function () {
    return {
      arrayDataJSON: [],
      imagenData: {
        nombreImagen: 'test'
      },
      imagenesModal: {
        id: 'imagen-modal',
        title: 'Imagenes Cliente',
        content: ''
      }
    }
  },
  watch: {
  },
  mounted () {
    this.arrayDataJSON.push(JSON.parse(this.data))
    // console.log(this.arrayDataJSON)
    this.getVueltasCliente({ id_vuelta_principal: this.arrayDataJSON[0].id }).then((res) => {
      // console.log(this.vueltasClientes)
      this.vueltasClientes.forEach(v => this.arrayDataJSON.push(v))
      // console.log(this.arrayDataJSON)
    })
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones', 'getTransportesPage', 'getVueltasCliente'
    ]),
    modalImagenesEnable (item) {
      // this.visitasModal.title = `Row index: ${index}`
      this.imagenesModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.imagenesModal.id)
    },
    hideModalmodalVisitas () {
      this.$refs[this.imagenesModal.id].hide()
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['vueltasClientes']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
