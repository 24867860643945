import { render, staticRenderFns } from "@/components/Layout/Menu/KPI_status_transporte/KPI_status_transporte.html?vue&type=template&id=ca21cb7a&scoped=true&"
import script from "@/components/Layout/Menu/KPI_status_transporte/KPI_status_transporte.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/KPI_status_transporte/KPI_status_transporte.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/KPI_status_transporte/KPI_status_transporte.css?vue&type=style&index=0&id=ca21cb7a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca21cb7a",
  null
  
)

export default component.exports