import { mapActions, mapState } from 'vuex'
import EditarSiniestrosView from '@/components/Layout/Menu/Gestores/Siniestros/Editar_siniestros/EditarSiniestrosView.vue'
import FiltroReportes from '@/components/Layout/Menu/Filtros/Filtro_Reportes/FiltroReportes.vue'

export default {
  name: 'SiniestroView',
  components: {
    EditarSiniestrosView,
    FiltroReportes
  },
  data: function () {
    return {
      optionEstadosSiniestros: [
        { value: null, text: '- Estados' }
      ],
      items: [],
      fields: [
        { key: 'id', label: 'Siniestro id', sortable: true, sortDirection: 'desc' },
        { key: 'fecha', label: 'Fecha', sortable: true, sortDirection: 'desc' },
        { key: 'estado', label: 'Estado', sortable: true, sortDirection: 'desc' },
        { key: 'editar', label: 'Editar', sortable: true, sortDirection: 'desc' }
      ],
      totalRows: 1,
      currentPage: 1,
      page: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Muchos Registros' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      editarModal: {
        id: 'modal-editar-siniestros-filds',
        title: 'Editar Siniestro',
        content: ''
      },
      estadosSiniestrosRegistros: {},
      dataFiltroReporte: null,
      filterSiniestro: {}
    }
  },
  props: {
  },
  watch: {
    dataFiltroReporte: function (val) {
      console.log('observador dataFiltroReporte')
      console.log(this.dataFiltroReporte)
      console.log(this.PeriodoFechaActual())
      const data = this.nextPage()
      this.findSiniestros(data)
    }
  },
  mounted () {
    console.log('Siniestros')
    this.filterSiniestro = this.PeriodoFechaActual()
    const data = this.nextPage()
    this.findSiniestros(data)
    this.getEstadosSiniestros().then((res) => {
      console.log(this.estados)
      for (const e in this.estados) {
        this.optionEstadosSiniestros.push({ value: this.estados[e], text: this.estados[e] })
      }
      console.log(this.optionEstadosSiniestros)
    })
  },
  methods: {
    ...mapActions('Siniestros', [
      'getSiniestros', 'updateEstadoSiniestro', 'deleteSiniestro', 'getEstadosSiniestros', 'getSiniestrosFilter'
    ]),
    findSiniestros (data) {
      if (this.dataFiltroReporte !== null) {
        data.fechaDesde = this.dataFiltroReporte.fechaDesde
        data.fechaHasta = this.dataFiltroReporte.fechaHasta
        data.estado = this.dataFiltroReporte.estadoSiniestro
      } else {
        const date = this.PeriodoFechaActual()
        data.fechaDesde = date.fechaDesde
        data.fechaHasta = date.fechaHasta
      }
      this.getSiniestrosFilter(data).then((res) => {
        console.log(this.siniestros)
        this.items = []
        this.items = this.siniestros.content
        this.creaListaEstadosSiniestros()
      })
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handlePageChange (value) {
      this.page = value
      const data = this.nextPage()
      this.findSiniestros(data)
    },
    nextPage () {
      const data = {
        page: (this.page - 1),
        size: this.perPage
      }
      return data
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    },
    modalEditarsEnable (item, index, button) {
      // this.visitasModal.title = `Row index: ${index}`
      this.editarModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editarModal.id, button)
    },
    hideModalEditar () {
      this.$refs[this.editarModal.id].hide()
    },
    creaListaEstadosSiniestros () {
      console.log('creaListaEstadosSiniestros')
      console.log(this.items)
      this.estadosSiniestrosRegistros = {}
      for (const estado in this.items) {
        this.estadosSiniestrosRegistros[this.items[estado].id] = this.items[estado].estado
      }
      console.log(this.estadosSiniestrosRegistros)
    },
    actualizaEstadoSiniestro (item) {
      console.log('actualizaEstadoSiniestro')
      console.log(item)
      console.log(this.estadosSiniestrosRegistros[item.id])
      const data = {
        id_siniestro: item.id,
        estado: this.estadosSiniestrosRegistros[item.id]
      }
      this.updateEstadoSiniestro(data).then((res) => {
      })
    },
    emitEditarSiniestro () {
      const data = this.nextPage()
      this.findSiniestros(data)
      this.getEstadosSiniestros().then((res) => {
        console.log(this.estados)
        for (const e in this.estados) {
          this.optionEstadosSiniestros.push({ value: this.estados[e], text: this.estados[e] })
        }
        console.log(this.optionEstadosSiniestros)
      })
      // this.hideModalEditar()
    },
    PeriodoFechaActual () {
      const date = new Date()
      const fechaActualPrimeraHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualUltimaHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '23'}:${
                '59'}:${
                  '59'}`
      console.log(fechaActualPrimeraHora)
      console.log(fechaActualUltimaHora)
      const periodo = {}
      periodo.fechaDesde = fechaActualPrimeraHora
      periodo.fechaHasta = fechaActualUltimaHora
      return periodo
    }
  },
  computed: {
    ...mapState('Siniestros', ['siniestros']),
    ...mapState('Siniestros', ['estados']),
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
