import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import TransformaQr from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/Editar_field/Transforma_qr/TransformaQr.vue'
import EditarMaterialesView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/Editar_field/Editar_materiales/EditarMaterialesView.vue'

export default {
  name: 'EditarFieldView',
  components: {
    TransformaQr,
    EditarMaterialesView
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: [],
      transporte: 0,
      transporte_id: null,
      activity_id: null,
      optionsV06CentroCosto: [
        { value: null, text: '- C. Costo' },
        { value: '101', text: 'BoF' },
        { value: '102', text: 'BoP' },
        { value: '103', text: 'Sopraval' },
        { value: '104', text: 'Andina Acarreo' }
      ],
      optionsA06CondBajCarga: [
        { value: null, text: '- CondBajCarga' },
        { value: '10', text: 'No' },
        { value: '20', text: 'Solo el Conductor' },
        { value: '30', text: 'Conductor y 1 Ayudante' },
        { value: '40', text: 'Conductor y 2 Ayudantes' },
        { value: '50', text: 'Solo Ayudante(s)' }
      ],
      optionsV06LlevaCarro: [
        { value: null, text: '- Lleva Carro' },
        { value: '10', text: 'No' },
        { value: '20', text: 'Sí' }
      ],
      optionsV06DevBOP: [
        { value: null, text: '- DevBOP' },
        { value: '10', text: 'No' },
        { value: '20', text: 'Sí' }
      ],
      optionsV06RegresoCD: [
        { value: null, text: '- Regreso CD' },
        { value: '20', text: 'No' },
        { value: '10', text: 'Sí' }
      ],
      optionsV06OrigenDestino: [
        { value: null, text: '- Origen Destino' },
        { value: 'T947', text: 'Antofagasta' },
        { value: 'T951', text: 'Calama' },
        { value: 'T952', text: 'Tocopilla' },
        { value: 'T938', text: 'T938' },
        { value: 'T940', text: 'T940' }
      ],
      V06NroDocu: [],
      transformarAQR: {
        id: 'transformarAQR-modal',
        title: 'Editar',
        content: ''
      },
      editarMateriales: {
        id: 'editarMateriales-modal',
        title: 'Editar',
        content: ''
      },
      materiales: [],
      emitEditQR: null,
      emitEditMat: null,
      materialesRender: [],
      docRender: [],
      contador: 0,
      fieldsEnable: false,
      optionsTipoLugar: [
        { value: null, text: '- Lugar incidente' },
        { value: 10, text: 'Calle' },
        { value: 20, text: 'CD' },
        { value: 30, text: 'Bodega/SPMK' }
      ],
      optionsTipInci: [
        { value: null, text: '- Indique incidente' },
        { value: 10, text: 'Parabrisas' },
        { value: 20, text: 'Tapabarros' },
        { value: 30, text: 'Parachoques' },
        { value: 40, text: 'Daño lateral derecho' },
        { value: 50, text: 'Daño lateral izquierdo' },
        { value: 60, text: 'Espejos' },
        { value: 70, text: 'Puertas' },
        { value: 80, text: 'Carrocería' }
      ],
      optionsExsTercero: [
        { value: null, text: '- ¿Existen Terceros involucrados?' },
        { value: 10, text: 'No' },
        { value: 20, text: 'Si' }
      ],
      optionsTerTieSeg: [
        { value: null, text: '- Tercero, ¿tiene seguro?' },
        { value: 10, text: 'No' },
        { value: 20, text: 'Si' }
      ],
      optionsCulTerc: [
        { value: null, text: '- ¿Es Terceros culpable?' },
        { value: 10, text: 'No' },
        { value: 20, text: 'Si' }
      ],
      optionsTerHa: [
        { value: null, text: '- Hará reclamo en compañía seguros?' },
        { value: 10, text: 'No' },
        { value: 20, text: 'Si' }
      ],
      optionsTipDTer: [
        { value: null, text: '- Tipo de daños ocasionados' },
        { value: 10, text: 'Daños materiales' },
        { value: 20, text: 'Robo del vehiculo' },
        { value: 30, text: 'Robo de accesorios' },
        { value: 40, text: 'Robo de partes y/o piezas' }
      ],
      optionsMagDTer: [
        { value: null, text: '- Magnitud del daño' },
        { value: 10, text: 'Leve' },
        { value: 20, text: 'Mediano' },
        { value: 30, text: 'Grave' }
      ],
      optionsDanOca: [
        { value: null, text: '- Daños ocasionados' },
        { value: 10, text: 'Abolladura lateral' },
        { value: 20, text: 'Abolladura frontal' },
        { value: 30, text: 'Abolladura trasera' },
        { value: 40, text: 'Parabrisas' },
        { value: 50, text: 'Parachoques' }
      ],
      optionsConductoresBuk: [
        { value: null, text: '- Conductores Buk' }
      ]
    }
  },
  watch: {
    emitEditQR: function (val) {
      console.log(this.emitEditQR)
      if (this.emitEditQR !== null) {
        this.hideModalTransformarAQR()
        const field = {
          id: this.emitEditQR.id,
          field_value: this.emitEditQR.field_value
        }
        this.editar(field)
        this.dataJSON = JSON.parse(this.data)
        this.transporte = this.dataJSON.transporte
        this.transporte_id = this.dataJSON.transporte_id
        if (this.dataJSON.activity !== null && this.dataJSON.activity !== undefined) {
          this.activity_id = this.dataJSON.activity.activityid
        }
        if (this.transporte_id !== null && this.transporte_id !== undefined) {
          console.log('entra transporte id')
          console.log(this.transporte_id)
          this.getFields(this.transporte_id).then((res) => {
          })
        } else if (this.activity_id !== null && this.activity_id !== undefined) {
          console.log('entra activity id')
          console.log(this.activity_id)
          this.getFieldsByActivityId(this.activity_id).then((res) => {
          })
        }
      }
    },
    emitEditMat: function (val) {
      console.log('emitEditMat')
      if (this.emitEditMat !== null && this.emitEditMat !== undefined) {
        const field = this.emitEditMat.field
        const posicion = field.field_name.substring(field.field_name.length - 2, field.field_name.length)
        this.V06NroDocu[this.emitEditMat.posicion]['Materiales - Cantidad'] = this.emitEditMat.materiales
        this.editarQR(posicion, field)
      }
    }
  },
  mounted () {
    this.getConductoresTypesBuk().then((res) => {
      console.log(this.conductoresBuk)
      for (const conductor in this.conductoresBuk) {
        this.optionsConductoresBuk.push({ value: this.conductoresBuk[conductor].rut, text: this.conductoresBuk[conductor].full_name + ' / ' + this.conductoresBuk[conductor].role_code })
      }
      this.optionsConductoresBuk.sort((a, b) => a.text.localeCompare(b.text))
      console.log(this.optionsConductoresBuk)
    })
    this.V06NroDocu = []
    this.editarMateriales = {
      id: 'editarMateriales-modal' + Date.now(),
      title: 'Editar',
      content: ''
    }
    this.dataJSON = JSON.parse(this.data)
    this.transporte = this.dataJSON.transporte
    this.transporte_id = this.dataJSON.transporte_id
    this.set_accion_fields([])
    if (this.dataJSON.activity !== null && this.dataJSON.activity !== undefined) {
      this.activity_id = this.dataJSON.activity.activityid
    }
    if (this.transporte_id !== null && this.transporte_id !== undefined) {
      console.log('entra transporte id')
      console.log(this.transporte_id)
      this.getFields(this.transporte_id).then((res) => {
        this.fieldsEnable = true
      })
    } else if (this.activity_id !== null && this.activity_id !== undefined) {
      console.log('entra activity id')
      console.log(this.activity_id)
      this.getFieldsByActivityId(this.activity_id).then((res) => {
        this.fieldsEnable = true
      })
    }
    // this.getFields(this.transporte_id).then((res) => {
    //   console.log(this.fields)
    //   this.fieldsEnable = true
    // })
  },
  methods: {
    ...mapMutations('AdministradorFlota', ['set_accion_fields']),
    ...mapActions('AdministradorFlota', [
      'getFields', 'updateField', 'getFieldsByActivityId'
    ]),
    ...mapActions('Cliente', [
      'getClientes'
    ]),
    ...mapActions('UsuariosBuk', [
      'getConductoresBuk', 'getConductoresTypesBuk'
    ]),
    transformarAQRModal (item) {
      this.transformarAQR.id = 'transformarAQR-modal'
      this.transformarAQR.title = 'Transformar a QR'
      this.transformarAQR.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.transformarAQR.id)
    },
    hideModalTransformarAQR () {
      this.$refs[this.transformarAQR.id].hide()
    },
    editarMaterialesModal (dato, posicion, entry, field) {
      const data = {
        dato: dato,
        posicion: posicion,
        entry: entry,
        field: field
      }
      this.materiales[posicion] = {
        id: 'editarMateriales-modal',
        title: 'Materiales',
        content: JSON.stringify(data, null, 2)
      }
      // this.editarMateriales.title = 'Materiales'
      // this.editarMateriales.content = JSON.stringify(data, null, 2)
      // console.log(data)
      console.log('termina editarMaterialesModal')
      return true
    },
    hideModaleditarMateriales () {
      this.$refs[this.editarMateriales.id].hide()
    },
    editar (field) {
      const data = {
        field_id: field.id,
        value: field.field_value
      }
      this.updateField(data).then((res) => {
        const toast = {}
        toast.title = 'Success'
        toast.variant = 'success'
        toast.body = 'Campo Actualizado Con Exito.'
        this.makeToast(toast)
      })
    },
    editarQR (posicionSTR, field) {
      const posicion = parseInt(posicionSTR)
      const doc = this.V06NroDocu[posicion]
      let valid = false
      for (var key in doc) {
        if (doc[key] === '' || doc[key] === null) {
          console.log('Elemento vacío o null encontrado en la clave:', key)
          const toast = {}
          toast.title = 'Error'
          toast.variant = 'danger'
          toast.body = 'Campo ' + key + ' invalido'
          this.makeToast(toast)
          valid = true
        }
      }
      if (valid) return
      // El string original
      var miString = field.field_value
      console.log(miString)
      var valoresActualizar = this.V06NroDocu[posicion]
      // Convertir el string a un objeto
      var miObjeto = {}
      miString.split('\n').forEach(function (line) {
        var keyValue = line.split(': ')
        if (keyValue.length === 2) {
          miObjeto[keyValue[0].trim()] = keyValue[1].trim()
        }
      })
      // Actualizar claves y valores según el objeto proporcionado
      for (var clave in valoresActualizar) {
        // console.log(clave + ' ' + valoresActualizar)
        if (Object.prototype.hasOwnProperty.call(valoresActualizar, clave) && Object.prototype.hasOwnProperty.call(miObjeto, clave)) {
          miObjeto[clave] = valoresActualizar[clave]
          if (clave === 'Origen - Destino (Código)') {
            miObjeto['Materiales - Cantidad'] = this.V06NroDocu[posicion]['Materiales - Cantidad']
          }
        }
      }
      // Mostrar el objeto actualizado
      console.log('Objeto actualizado:', miObjeto)
      console.log('Objeto antiguo:', field.field_value)
      // Convertir el objeto actualizado a un nuevo string
      var entradas = Object.entries(miObjeto)
      // Mover "Materiales - Cantidad" antes de "Hora Emisión Guía"
      var indexMateriales = entradas.findIndex(entry => entry[0] === 'Materiales - Cantidad')
      if (indexMateriales !== -1) {
        entradas.splice(indexMateriales, 1)
        entradas.splice(entradas.findIndex(entry => entry[0] === 'Hora Emisión Guía'), 0, ['Materiales - Cantidad', miObjeto['Materiales - Cantidad']])
      }
      var nuevoString = entradas.map(function (entry) {
        if (entry[1]) {
          if (entry[0] === 'Materiales - Cantidad') {
            return entry[0] + '\n' + entry[1].replace(/\n/g, '\n')
          } else {
            return entry[0] + ': ' + entry[1]
          }
        } else {
          return entry[0]
        }
      }).join('\n\n')
      nuevoString = nuevoString.replace(/^\s*\n/gm, '')
      console.log('Nuevo string:', nuevoString)
      const data = {
        field_id: field.id,
        value: nuevoString
      }
      this.updateField(data).then((res) => {
        const toast = {}
        toast.title = 'Success'
        toast.variant = 'success'
        toast.body = 'Campo Actualizado Con Exito.'
        this.makeToast(toast)
      })
    },
    editV06NroDocu (data) {
      // this.V06NroDocu = []
      const fieldName = data.field_name
      // this.docRender.push(fieldName)
      const posicion = fieldName.substring(fieldName.length - 2, fieldName.length)
      // console.log(parseInt(posicion, 10))
      const fieldValue = data.field_value.split('\n')
      // console.log(fieldValue)
      const dataDoc = {}
      let isMateriales = false
      let materiales = ''
      fieldValue.forEach(f => {
        const value = f.split(': ')
        // console.log(value)
        if (value.length > 1) {
          if (value[0] === 'Hora Emisión Guía') {
            isMateriales = false
            dataDoc['Materiales - Cantidad'] = materiales
            // console.log(materiales)
          }
          dataDoc[value[0]] = value[1]
        } else if (value.length === 1) {
          switch (value[0]) {
            case 'Materiales - Cantidad':
              isMateriales = true
              break
            default:
              if (isMateriales) materiales = materiales + value[0] + '\n'
              break
          }
        }
      })
      this.V06NroDocu[parseInt(posicion, 10)] = dataDoc
      console.log(this.V06NroDocu)
      return true
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['fields']),
    ...mapGetters('AdministradorFlota', ['getterFields']),
    ...mapState('UsuariosBuk', ['conductoresBuk'])
  }
}
