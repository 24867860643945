import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import CrearTurnoView from '@/components/Layout/Menu/Administracion/Administracion_turnos/Crear_turno/CrearTurnoView.vue'
import EliminarTurnoView from '@/components/Layout/Menu/Administracion/Administracion_turnos/Eliminar_turno/EliminarTurnoView.vue'

export default {
  name: 'AdministracionTurnosView',
  components: {
    CrearTurnoView,
    EliminarTurnoView
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        {
          key: 'clasificacion',
          label: 'Clasificacion',
          sortable: false
        },
        {
          key: 'hour_in',
          label: 'Entrada',
          sortable: false
        },
        {
          key: 'hour_out',
          label: 'Salida',
          sortable: false
        },
        {
          key: 'eliminar',
          label: 'Eliminar',
          sortable: false
        }
      ],
      itemsAux: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      emitCreateTurno: '',
      emitEliminaTurno: '',
      CrearTurno: {
        id: 'crearTurno-modal',
        title: 'Crear Turno',
        content: ''
      },
      eliminarTurno: {
        id: 'eliminarTurno-modal',
        title: 'Eliminar',
        content: ''
      },
      patentes: [
        {
          value: null,
          text: '- Seleccionar Patente'
        }
      ],
      selectedPatente: null,
      camBasis: [
        {
          value: null,
          text: 'Seleccionar NroInterno/Nro Basis'
        }
      ],
      selectedCamBasis: null,
      file: null,
      arrayBuffer: null,
      filelist: null,
      arrayRespuesta: []
    }
  },
  watch: {
    emitEliminaTurno: function (val) {
      this.hideModalEliminarTurno()
      this.init()
    },
    emitCreateTurno: function (val) {
      this.hideModalcrearTurno()
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions('Turno', [
      'getTurnos'
    ]),
    init () {
      this.getTurnos().then((res) => {
        this.items = this.turnos
        this.totalRows = this.items.length
      })
    },
    Actualizar () {
      this.init()
    },
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    eliminarTurnoModal (item, index, button) {
      this.eliminarTurno.title = 'Eliminar Turno: ' + item.id
      this.eliminarTurno.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.eliminarTurno.id, button)
    },
    crearTurnoModal (item, index, button) {
      // this.CrearCamion.title = `Row index: ${index}`
      this.CrearTurno.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.CrearTurno.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filtroitems () {
      this.items = this.itemsAux
      if (this.selectedCamBasis !== undefined && this.selectedCamBasis !== null) {
        console.log('tiene filtro de cam basis')
        this.items = this.items.filter(camBasis => camBasis.camion === this.selectedCamBasis)
      }
      if (this.selectedPatente !== undefined && this.selectedPatente !== null) {
        console.log('tiene filtro de patente')
        this.items = this.items.filter(camBasis => camBasis.matricula === this.selectedPatente)
      }
    },
    hideModalEliminarTurno () {
      this.$refs[this.eliminarTurno.id].hide()
    },
    hideModalcrearTurno () {
      this.$refs[this.CrearTurno.id].hide()
    },
    exportarExcel () {
      console.log('exportar excel')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          if (!key.includes('_cellVariants')) {
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'turnos')
      XLSX.writeFile(wb, 'turnos.xlsx')
    },
    addfile (event) {
      console.log(event)
      this.file = event.target.files[0]
      const fileReader = new FileReader()
      try {
        fileReader.readAsArrayBuffer(this.file)
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        console.log(workbook)
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        this.arrayRespuesta = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        console.log(this.arrayRespuesta)
        this.filelist = []
        console.log(this.filelist)
      }
    },
    cargarExcel () {
      console.log('cargar excel')
      this.createCamiones(this.arrayRespuesta).then((res) => {
        this.getCamionesConductores().then((res) => {
          const newItems = []
          for (const item in res) {
            const newItem = {}
            newItem.id = res[item].camion.id
            newItem.camion = res[item].camion.cam_basis
            newItem.matricula = res[item].camion.matricula
            newItem.conductor = res[item].conductor.device_alias
            newItem.cliente = res[item].cliente
            newItem.administrador = res[item].camion.administrador.nombre
            newItems.push(newItem)
          }
          this.items = newItems
          this.itemsAux = this.items
          console.log('cargo reporte cargas')
          this.totalRows = this.items.length
          const toast = {}
          toast.title = 'Exito'
          toast.variant = 'success'
          toast.body = 'Se han cargado exitosamente los Camiones'
          this.makeToast(toast)
        })
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    },
    testToast () {
      const toast = {}
      toast.title = 'Carga Exitosa'
      toast.variant = 'success'
      toast.body = ''
      this.makeToast(toast)
    }
  },
  computed: {
    ...mapState('Turno', ['turnos']),
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
