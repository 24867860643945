import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AsignarCamiones',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      cam_basis: 0,
      descripcion: '',
      driver: 0,
      empresa: '',
      matricula: '',
      pallet: 0,
      proveedor: 0,
      administrador: '',
      selectedAdministrador: null,
      optionsAdministrador: [
        { value: null, text: '- Administrador' }
      ],
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: '- Conductor' }
      ],
      selectedCamion: null,
      optionsCamion: [
        { value: null, text: '- Camion' }
      ],
      selectedCliente: null,
      optionsCliente: [
        { value: null, text: '- Cliente' }
        // { value: 'Andina', text: 'Andina' },
        // { value: 'Sopraval', text: 'Sopraval' },
        // { value: 'Soprole', text: 'Soprole' },
        // { value: 'Andina Acarreo', text: 'Andina Acarreo' }
      ]
    }
  },
  watch: {
  },
  mounted () {
    for (const administrador in this.administradores) {
      this.optionsAdministrador.push({ value: this.administradores[administrador].nombre, text: this.administradores[administrador].nombre })
    }
    this.optionsAdministrador.sort((a, b) => a.text.localeCompare(b.text))
    for (const conductor in this.conductores) {
      this.optionsConductor.push({ value: this.conductores[conductor].id, text: this.conductores[conductor].device_alias })
    }
    this.optionsConductor.sort((a, b) => a.text.localeCompare(b.text))
    // console.log(this.camiones)
    for (const camion in this.camiones) {
      this.optionsCamion.push({ value: this.camiones[camion].id, text: this.camiones[camion].matricula + ' / ' + this.camiones[camion].cam_basis })
    }
    this.optionsCamion.sort((a, b) => a.text.localeCompare(b.text))
    this.getClientes().then((res) => {
      // console.log(this.clientes)
      this.clientes.forEach(element => this.optionsCliente.push({
        value: element.nombre,
        text: element.nombre
      }))
    })
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones', 'createCamion'
    ]),
    ...mapActions('Cliente', [
      'getClientes'
    ]),
    async updateClienteByCamion (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateClienteByCamion`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateClienteByCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateClienteByCamion')
        router.push({ path: '/' })
        return false
      }
    },
    async updateClienteByConductor (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateClienteByConductor`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateClienteByConductor')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateClienteByConductor')
        router.push({ path: '/' })
        return false
      }
    },
    async updateConductorByCamion (datosCamion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateConductorByCamion`,
          data: datosCamion,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else updateConductorByCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateConductorByCamion')
        router.push({ path: '/' })
        return false
      }
    },
    async createCamionConductor (data) {
      try {
        const response = await axios({
          method: 'put',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/createCamionConductor?id_camion=${data.id_camion}&id_conductor=${data.id_conductor}&cliente=${data.cliente}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response
        } else {
          console.log('por else createCamionConductor')
          return response
        }
        // return false
      } catch (err) {
        console.error(err)
        console.log('por catch createCamionConductor')
        const toast = {}
        toast.title = 'Error'
        toast.body = err.response.data
        toast.variant = 'danger'
        this.makeToast(toast)
        return false
        // router.push({ path: '/' })
        // return false
      }
    },
    asignar () {
      const data = {}
      data.id_camion = this.selectedCamion
      data.id_conductor = this.selectedConductor
      data.cliente = this.selectedCliente
      // console.log(data)
      this.createCamionConductor(data).then((res) => {
        const toast = {}
        if (res.status === 200) {
          toast.title = 'Exito'
          toast.body = 'Relacion Creada'
          toast.variant = 'success'
          this.makeToast(toast)
          this.$emit('emitEditCamion', data)
        } else {
          toast.title = 'Error'
          toast.body = res.data
          toast.variant = 'danger'
          this.makeToast(toast)
        }
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Administrador', ['administradores']),
    ...mapState('Cliente', ['clientes'])
  }
}
