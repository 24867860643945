import { render, staticRenderFns } from "@/components/Layout/Menu/Reportes/Reportes_clientes/Reportes_clientes.html?vue&type=template&id=2dca4d18&scoped=true&"
import script from "@/components/Layout/Menu/Reportes/Reportes_clientes/Reportes_clientes.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/Reportes/Reportes_clientes/Reportes_clientes.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/Reportes/Reportes_clientes/Reportes_clientes.css?vue&type=style&index=0&id=2dca4d18&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dca4d18",
  null
  
)

export default component.exports