import { mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EditarMaterialesView',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {},
      parametros: {
        folio: null,
        tipo_documento: null,
        rut_cliente: null,
        monto: null,
        patente: null,
        origen_destino: null,
        hora_emision: null,
        nro_entrega: null,
        materiales_cantidad: []
      },
      materiales: null,
      cantidad: null
    }
  },
  watch: {
  },
  mounted () {
    console.log('editar materiales')
    console.log(this.parametros.materiales_cantidad.length)
    this.dataJSON = JSON.parse(this.data)
    console.log(this.dataJSON)
    const materiales = this.dataJSON.entry
    this.parametros.materiales_cantidad = materiales.substring(0, materiales.length - 1).split('\n')
    console.log(this.parametros.materiales_cantidad.length)
  },
  methods: {
    addMaterialCantidad () {
      const data = this.materiales + '-' + this.cantidad
      this.parametros.materiales_cantidad.push(data)
    },
    eliminarMaterial (index) {
      this.parametros.materiales_cantidad.splice(index, 1)
    },
    validarParametros () {
      let validador = true
      for (const key in this.parametros) {
        if (Object.prototype.hasOwnProperty.call(this.parametros, key)) {
          const valor = this.parametros[key]
          if (valor === null || valor === '' || /^\s*$/.test(valor)) {
            validador = false // Devuelve false si encuentra un valor no válido
          }
          if (Array.isArray(valor) && valor.length === 0) {
            validador = false // Devuelve false si el array está vacío
          }
        }
      }
      return validador
    },
    editarField () {
      // console.log(this.validarParametros())
      // if (!this.validarParametros()) {
      //   const toast = {}
      //   toast.title = 'Error'
      //   toast.variant = 'danger'
      //   toast.body = 'Faltan campos por llenar.'
      //   this.makeToast(toast)
      //   return
      // }
      console.log('valido')
      let materialesSTR = ''
      this.parametros.materiales_cantidad.forEach(element => {
        materialesSTR = materialesSTR + element + '\n'
      })
      console.log(materialesSTR)
      const data = {
        materiales: materialesSTR,
        posicion: this.dataJSON.posicion,
        field: this.dataJSON.field
      }
      console.log(data)
      this.$emit('emitEditMat', data)
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes'])
  }
}
