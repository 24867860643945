import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import FiltroReportes from '@/components/Layout/Menu/Filtros/Filtro_Reportes/FiltroReportes.vue'

export default {
  name: 'ReporteTransportesRepetidos',
  components: {
    FiltroReportes
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        {
          key: 'cliente',
          label: 'Cliente',
          sortable: false
        },
        {
          key: 'transporte',
          label: 'Transporte',
          sortable: true
        },
        {
          key: 'fecha',
          label: 'Fecha',
          sortable: true
        },
        {
          key: 'cam_basis.cam_basis',
          label: 'Nro Cam',
          sortable: false
        },
        {
          key: 'conductor',
          label: 'Conductor',
          sortable: false
        },
        {
          key: 'vuelta_cd',
          label: 'Vuelta CD',
          sortable: false
        },
        {
          key: 'vuelta',
          label: 'Vuelta',
          sortable: false
        },
        {
          key: 'estado',
          label: 'Estado',
          sortable: false
        },
        {
          key: 'confirmacion_admin',
          label: 'Confirmacion Admin',
          sortable: false
        },
        {
          key: 'hora_update',
          label: 'Hora Update',
          sortable: false
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'transporte',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      dataFiltroReporte: {}
    }
  },
  watch: {
    dataFiltroReporte: function (val) {
      console.log('observador dataFiltroReporte')
      console.log(this.dataFiltroReporte)
      const periodos = this.PeriodoFechaActual()
      console.log(periodos)
      this.getTransportesRepetidos(this.dataFiltroReporte).then((res) => {
        console.log('cargo reporte repetidos')
        this.items = []
        this.items = res.sort((a, b) => a.transporte - b.transporte)
        // console.log(res)
        this.totalRows = this.items.length
      })
      this.totalRows = this.items.length
    }
  },
  mounted () {
    const periodos = this.PeriodoFechaActual()
    this.getTransportesRepetidos(periodos).then((res) => {
      this.items = res
      console.log('cargo reporte repetidos')
      this.totalRows = this.items.length
    })
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('Reportes', [
      'getTransportesRepetidos'
    ]),
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants')) {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'transportes_repetidos')
      XLSX.writeFile(wb, 'transportes_repetidos.xlsx')
    },
    PeriodoFechaActual () {
      const date = new Date()
      const fechaActualPrimeraHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualUltimaHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '23'}:${
                '59'}:${
                  '59'}`
      console.log(fechaActualPrimeraHora)
      console.log(fechaActualUltimaHora)
      const periodo = {}
      periodo.fechaDesde = fechaActualPrimeraHora
      periodo.fechaHasta = fechaActualUltimaHora
      return periodo
    }
  },
  computed: {
    ...mapState('Reportes', ['reporteTransportesRepetidos']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
