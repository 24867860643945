import { mapActions } from 'vuex'

export default {
  name: 'EliminarTurno',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {},
      emitEliminaTurno: false
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    console.log(this.dataJSON)
  },
  methods: {
    ...mapActions('Turno', [
      'deleteTurno'
    ]),
    Eliminar () {
      // console.log('eliminar ' + this.dataJSON.id)
      this.deleteTurno(this.dataJSON.id).then((res) => {
        this.emitEliminaTurno = new Date()
        this.$emit('emitEliminaTurno', this.emitEliminaTurno)
      })
    }
  },
  computed: {
  }
}
