import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import EditarDestinos from '@/components/Layout/Menu/Administracion/Administracion_destinos/Editar_destinos/EditarDestinos.vue'
import CrearAdministradorView from '@/components/Layout/Menu/Administracion/Administracion_administradores/Crear_administrador/CrearAdministradorView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AdministracionAdministradores',
  components: {
    CrearAdministradorView
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: false
        },
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: false
        },
        {
          key: 'editar',
          label: 'Editar',
          sortable: false
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      crearAdministradorModal: {
        id: 'crear-administrador-modal',
        title: '',
        content: ''
      },
      borrarAdministradorModal: {
        id: 'borrar-administrador-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      emitEdit: ''
    }
  },
  watch: {
    emitEdit: function (val) {
      this.items = this.emitEdit
      this.totalRows = this.items.length
      this.hideModalCrearAdministrador()
      const toast = {}
      toast.title = 'Admistrador Agregado'
      toast.body = 'Tabla cargada con exito'
      toast.variant = 'success'
      this.makeToast(toast)
    }
  },
  mounted () {
    this.getAdministradores().then((res) => {
      // console.log(this.administradores)
      this.items = this.administradores
      this.totalRows = this.items.length
    })
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    ...mapActions('Administrador', [
      'getAdministradores', 'createAdministradores', 'deleteAdministradores'
    ]),
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    hideModalInfo () {
      this.$refs[this.infoModal.id].hide()
    },
    CrearAdministradorModal (item, index, button) {
      this.crearAdministradorModal.title = 'Crear nuevo administrador de flota'
      this.crearAdministradorModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.crearAdministradorModal.id, button)
    },
    resetCrearAdministradorModal () {
      this.crearAdministradorModal.title = ''
      this.crearAdministradorModal.content = ''
    },
    hideModalCrearAdministrador () {
      this.$refs[this.crearAdministradorModal.id].hide()
    },
    BorrarAdministradorModal (item, index, button) {
      this.borrarAdministradorModal.title = 'Borrar ' + item.nombre
      this.borrarAdministradorModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.borrarAdministradorModal.id, button)
    },
    resetBorrarAdministradorModal () {
      this.borrarAdministradorModal.title = ''
      this.borrarAdministradorModal.content = ''
    },
    hideModalBorrarAdministrador () {
      this.$refs[this.borrarAdministradorModal.id].hide()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    borrarAdministrador (content) {
      // console.log('borrarAdministrador')
      // console.log(content)
      const contentJSON = JSON.parse(content)
      // console.log(contentJSON.id)
      this.deleteAdministradores(contentJSON).then((res) => {
        this.getAdministradores().then((res) => {
          // console.log(this.administradores)
          this.items = this.administradores
          this.totalRows = this.items.length
          this.hideModalBorrarAdministrador()
          const toast = {}
          toast.title = 'Administrador Borrado'
          toast.body = 'Tabla cargada con exito'
          toast.variant = 'success'
          this.makeToast(toast)
        })
      })
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants')) {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'administradores')
      XLSX.writeFile(wb, 'administradores.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Administrador', ['administradores']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
