import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    usuariosVps: [],
    rolesVps: []
  },
  mutations: {
    set_accion_usuariosVps (state, usuariosVps) {
      state.usuariosVps = usuariosVps
    },
    set_accion_rolesVps (state, rolesVps) {
      state.rolesVps = rolesVps
    }
  },
  actions: {
    async getUsuariosVps ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/login/auth/usuarios/application?application=VPS`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getUsuariosVps')
          console.log(response.data)
          commit('set_accion_usuariosVps', response.data)
          return true
        } else {
          console.log('por else getUsuariosVps')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getUsuariosVps')
        router.push({ path: '/' })
        return false
      }
    },
    async getRolesVps ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/login/auth/roles/application?application=VPS`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getRolesVps')
          console.log(response.data)
          commit('set_accion_rolesVps', response.data)
          return true
        } else {
          console.log('por else getRolesVps')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getRolesVps')
        router.push({ path: '/' })
        return false
      }
    },
    async createUsuarioVps ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'POST',
          url: `${store.state.host}/api/login/auth/registrar`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: usuario
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api createUsuarioVps')
          console.log(response.data)
          // commit('set_accion_rolesVps', response.data)
          return true
        } else {
          console.log('por else createUsuarioVps')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getRolesVps')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
