import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ImagenesView',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: [],
      urlImagenes: []
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    this.getImagenes(this.dataJSON.id).then((res) => {
      console.log(this.imagenes)
      this.imagenes.forEach(v => this.urlImagenes.push({
        url: 'https://app.vps.dmetrix.cl/api/vps/images/documento/' + v,
        title: v,
        description: v
      }))
    })
  },
  methods: {
    ...mapActions('Imagenes', [
      'getImagenes'
    ])
  },
  computed: {
    ...mapState('Imagenes', ['imagenes']),
    ...mapState('AdministradorFlota', ['vueltasClientes']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
