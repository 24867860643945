import { mapActions, mapState } from 'vuex'
import BarChart from '@/components/Layout/Menu/Common/Graficos/BarChart/BarChart.vue'
import PieChart from '@/components/Layout/Menu/Common/Graficos/PieChart/PieChart.vue'
import FiltroReportes from '@/components/Layout/Menu/Filtros/Filtro_Reportes/FiltroReportes.vue'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ReportesCliente',
  components: {
    BarChart,
    PieChart,
    FiltroReportes
  },
  props: {
  },
  data: function () {
    return {
      labelUcaseCliente: ['Andina', 'Sopraval', 'Soprole'],
      labelUcaseVuelta: ['Vuelta 1', 'Vuelta 2', 'Vuelta 3', 'Vuelta 4', 'Vuelta 5'],
      labelEstadosAndina: ['Entragadas', 'En Ruta', 'Pendientes A Salir'],
      datasetsUcaseCliente: [],
      datasetsTotalCliente: [],
      datasetsTotalClientePorVuelta: [],
      datasetsUcaseVuelta: [],
      datasetsEstadosAndina: [],
      datasetsUcaseEstadosAndina: [],
      totalAndina: 0,
      totalSopraval: 0,
      totalSoprole: 0,
      ucase1: 0,
      ucase2: 0,
      ucase3: 0,
      ucase4: 0,
      ucase5: 0,
      totalVuelta1: 0,
      totalVuelta2: 0,
      totalVuelta3: 0,
      totalVuelta4: 0,
      totalVuelta5: 0,
      entregadas: 0,
      en_ruta: 0,
      en_espera: 0,
      ucaseAndinaEntregadas: 0,
      ucaseAndinaEn_ruta: 0,
      ucaseAndinaEn_espera: 0,
      ucaseAndina: 0,
      ucaseSopraval: 0,
      ucaseSoprole: 0,
      emitRender: '',
      render: '',
      dataFiltroReporte: '',
      backgroundColorAndina: [
        '#FF0000'
      ],
      backgroundColorSopraval: [
        'rgb(255, 255, 0)'
      ],
      backgroundColorSoprole: [
        'rgb(19, 255, 0)'
      ],
      backgroundArrayClientes: [
        this.backgroundColorAndina,
        this.backgroundColorSopraval,
        this.backgroundColorSoprole
      ],
      backgroundColorUcaseCliente: [
        '#FF0000',
        'rgb(255, 255, 0)',
        'rgb(19, 255, 0)'
        // 'rgb(128, 128, 0)'
      ],
      backgroundColorUcaseVuelta: [
        '#FF0000',
        'rgb(255, 255, 0)',
        'rgb(218, 247, 166)',
        'rgb(218, 247, 100)',
        'rgb(255, 143, 0)'
        // 'rgb(128, 128, 0)'
      ],
      backgroundColorEstadosAndina: [
        'rgb(19, 255, 0)',
        'rgb(255, 255, 0)',
        '#FF0000'
        // 'rgb(128, 128, 0)'
      ],
      filtroClienteEnable: true,
      fieldsClientes: [
        {
          key: 'cliente',
          label: 'Cliente',
          sortable: false
        },
        {
          key: 'value',
          label: 'Transportes',
          sortable: false
        }
      ],
      itemsClientes: [],
      fieldsVueltas: [
        {
          key: 'vuelta',
          label: 'Vuelta',
          sortable: false
        },
        {
          key: 'value',
          label: 'Transportes',
          sortable: false
        }
      ],
      itemsVueltas: [],
      fieldsEstados: [
        {
          key: 'estado',
          label: 'Estado',
          sortable: false
        },
        {
          key: 'value',
          label: 'Transportes',
          sortable: false
        }
      ],
      itemsEstados: [],
      fieldsEstadosAndinaUC: [
        {
          key: 'estado',
          label: 'Estado UC',
          sortable: false
        },
        {
          key: 'value',
          label: 'UC',
          sortable: false
        }
      ],
      itemsEstadosAndinaUC: [],
      fieldseVueltasAndinaUC: [
        {
          key: 'vuelta',
          label: 'Vuelta UC',
          sortable: false
        },
        {
          key: 'value',
          label: 'UC',
          sortable: false
        }
      ],
      itemsVueltasAndinaUC: [],
      fields: ['first_name', 'last_name', 'age'],
      items: [
        { isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney' }
      ],
      range: '',
      max: '0',
      valueRange: '0',
      renderRange: false
    }
  },
  watch: {
    emitRender: function (val) {
      console.log('observador emitRender')
      if (this.emitRender) {
        this.render = !this.render
        this.emitRender = false
        this.datasetsUcaseVuelta = []
        this.datasetsUcaseCliente = []
        this.datasetsEstadosAndina = []
        this.datasetsUcaseEstadosAndina = []
        this.datasetsTotalCliente = []
        this.datasetsTotalClientePorVuelta = []
        console.log('---total por cliente por vuelta---')
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta1)
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta2)
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta3)
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta4)
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta5)
        console.log('---ucase por estados Andina---')
        this.datasetsUcaseEstadosAndina.push(this.ucaseAndinaEntregadas)
        this.datasetsUcaseEstadosAndina.push(this.ucaseAndinaEn_ruta)
        this.datasetsUcaseEstadosAndina.push(this.ucaseAndinaEn_espera)
        // console.log('---ucase por vuelta---')
        this.datasetsUcaseVuelta.push(this.ucase1)
        this.datasetsUcaseVuelta.push(this.ucase2)
        this.datasetsUcaseVuelta.push(this.ucase3)
        this.datasetsUcaseVuelta.push(this.ucase4)
        this.datasetsUcaseVuelta.push(this.ucase5)
        // console.log('ucase1: ' + this.ucase1)
        // console.log('ucase2: ' + this.ucase2)
        // console.log('ucase3: ' + this.ucase3)
        // console.log('ucase4: ' + this.ucase4)
        // console.log('ucase5: ' + this.ucase5)
        console.log('---ucase por cliente---')
        // this.datasetsUcaseCliente.push(this.ucaseAndina)
        // this.datasetsUcaseCliente.push(this.ucaseSopraval)
        // this.datasetsUcaseCliente.push(this.ucaseSoprole)
        console.log('---total por cliente---')
        this.datasetsTotalCliente.push(this.totalAndina)
        this.datasetsTotalCliente.push(this.totalSopraval)
        this.datasetsTotalCliente.push(this.totalSoprole)
        console.log('andina ucase: ' + this.ucaseAndina)
        console.log('sopraval ucase: ' + this.ucaseSopraval)
        console.log('soprole ucase: ' + this.ucaseSoprole)
        console.log('---estado carga  sopraval---')
        this.datasetsEstadosAndina.push(this.entregadas)
        this.datasetsEstadosAndina.push(this.en_ruta)
        this.datasetsEstadosAndina.push(this.en_espera)
        console.log('entregadas: ' + this.entregadas)
        console.log('en ruta: ' + this.en_ruta)
        console.log('en espera: ' + this.en_espera)
      }
    },
    dataFiltroReporte: function (val) {
      console.log('observador dataFiltroReporte')
      console.log(this.dataFiltroReporte)
      this.getTransportes(this.dataFiltroReporte).then((res) => {
        console.log('cargo')
        console.log(res)
        console.log(this.dataFiltroReporte.cliente)
        this.inicialzarVariables()
        for (const transporte in res) {
          if (this.dataFiltroReporte.cliente !== undefined && this.dataFiltroReporte.cliente !== null) {
            if (res[transporte].cliente !== this.dataFiltroReporte.cliente) {
              continue
            }
          }
          switch (res[transporte].vuelta) {
            case 1:
              // this.ucase1 = this.ucase1 + this.transportes[transporte].ucase
              // this.ucase1 = this.ucase1 + 1
              this.totalVuelta1 = this.totalVuelta1 + 1
              break
            case 2:
              // this.ucase2 = this.ucase2 + this.transportes[transporte].ucase
              // this.ucase2 = this.ucase2 + 1
              this.totalVuelta2 = this.totalVuelta2 + 1
              break
            case 3:
              // this.ucase3 = this.ucase3 + this.transportes[transporte].ucase
              // this.ucase3 = this.ucase3 + 1
              this.totalVuelta3 = this.totalVuelta3 + 1
              break
            case 4:
              // this.ucase4 = this.ucase4 + this.transportes[transporte].ucase
              // this.ucase4 = this.ucase4 + 1
              this.totalVuelta4 = this.totalVuelta4 + 1
              break
            case 5:
              // this.ucase5 = this.ucase5 + this.transportes[transporte].ucase
              // this.ucase5 = this.ucase5 + 1
              this.totalVuelta5 = this.totalVuelta5 + 1
              break
          }
          switch (res[transporte].estado) {
            case 'entregada':
              this.entregadas = this.entregadas + 1
              break
            case 'en ruta':
              this.en_ruta = this.en_ruta + 1
              break
            default:
              this.en_espera = this.en_espera + 1
              break
          }
          if (res[transporte].cliente === 'Andina') {
            switch (res[transporte].vuelta) {
              case 1:
                this.ucase1 = Math.round(this.ucase1 + res[transporte].ucase)
                break
              case 2:
                this.ucase2 = Math.round(this.ucase2 + res[transporte].ucase)
                break
              case 3:
                this.ucase3 = Math.round(this.ucase3 + res[transporte].ucase)
                break
              case 4:
                this.ucase4 = Math.round(this.ucase4 + res[transporte].ucase)
                break
              case 5:
                this.ucase5 = Math.round(this.ucase5 + res[transporte].ucase)
                break
            }
            this.ucaseAndina = Math.round(this.ucaseAndina + res[transporte].ucase)
            this.totalAndina = this.totalAndina + 1
            if (res[transporte].estado === 'entregada') {
              this.ucaseAndinaEntregadas = Math.round(this.ucaseAndinaEntregadas + res[transporte].ucase)
              // this.entregadas = this.entregadas + 1
            } else if (res[transporte].estado === 'en ruta') {
              this.ucaseAndinaEn_ruta = Math.round(this.ucaseAndinaEn_ruta + res[transporte].ucase)
              // this.en_ruta = this.en_ruta + 1
            } else {
              this.ucaseAndinaEn_espera = Math.round(this.ucaseAndinaEn_espera + res[transporte].ucase)
              // this.en_espera = this.en_espera + 1
            }
          } else if (res[transporte].cliente === 'Sopraval') {
            this.totalSopraval = this.totalSopraval + 1
            this.ucaseSopraval = Math.round(this.ucaseSopraval + res[transporte].ucase)
          } else if (res[transporte].cliente === 'Soprole') {
            this.totalSoprole = this.totalSoprole + 1
            this.ucaseSoprole = Math.round(this.ucaseSoprole + res[transporte].ucase)
          }
        }
        console.log('---total por cliente por vuelta---')
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta1)
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta2)
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta3)
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta4)
        this.datasetsTotalClientePorVuelta.push(this.totalVuelta5)
        console.log('---ucase por estados Andina---')
        this.datasetsUcaseEstadosAndina.push(this.ucaseAndinaEntregadas)
        this.datasetsUcaseEstadosAndina.push(this.ucaseAndinaEn_ruta)
        this.datasetsUcaseEstadosAndina.push(this.ucaseAndinaEn_espera)
        console.log('---ucase por vuelta---')
        this.datasetsUcaseVuelta.push(this.ucase1)
        this.datasetsUcaseVuelta.push(this.ucase2)
        this.datasetsUcaseVuelta.push(this.ucase3)
        this.datasetsUcaseVuelta.push(this.ucase4)
        this.datasetsUcaseVuelta.push(this.ucase5)
        // console.log('ucase1: ' + this.ucase1)
        // console.log('ucase2: ' + this.ucase2)
        // console.log('ucase3: ' + this.ucase3)
        // console.log('ucase4: ' + this.ucase4)
        // console.log('ucase5: ' + this.ucase5)
        console.log('---ucase por cliente---')
        this.datasetsUcaseCliente.push(this.ucaseAndina)
        this.datasetsUcaseCliente.push(this.ucaseSopraval)
        this.datasetsUcaseCliente.push(this.ucaseSoprole)
        console.log('---total por cliente---')
        this.datasetsTotalCliente.push(this.totalAndina)
        this.datasetsTotalCliente.push(this.totalSopraval)
        this.datasetsTotalCliente.push(this.totalSoprole)
        // this.max = this.totalAndina
        // this.valueRange = String(this.totalAndina)
        if (this.totalAndina > this.totalSopraval && this.totalAndina > this.totalSoprole) {
          this.max = this.totalAndina
          this.valueRange = String(this.totalAndina)
        } else if (this.totalSopraval > this.totalAndina && this.totalSopraval > this.totalSoprole) {
          this.max = this.totalSopraval
          this.valueRange = String(this.totalSopraval)
        } else if (this.totalSoprole > this.totalAndina && this.totalSoprole > this.totalSopraval) {
          this.max = this.totalSoprole
          this.valueRange = String(this.totalSoprole)
        }
        console.log('andina ucase: ' + this.ucaseAndina)
        console.log('sopraval ucase: ' + this.ucaseSopraval)
        console.log('soprole ucase: ' + this.ucaseSoprole)
        console.log('---estado carga  sopraval---')
        this.datasetsEstadosAndina.push(this.entregadas)
        this.datasetsEstadosAndina.push(this.en_ruta)
        this.datasetsEstadosAndina.push(this.en_espera)
        console.log('entregadas: ' + this.entregadas)
        console.log('en ruta: ' + this.en_ruta)
        console.log('en espera: ' + this.en_espera)
        this.render = !this.render
        this.crearTablas()
      })
    }
  },
  mounted () {
    // console.log(this.transportes)
    this.inicialzarVariables()
    for (const transporte in this.transportes) {
      switch (this.transportes[transporte].vuelta) {
        case 1:
          // this.ucase1 = this.ucase1 + this.transportes[transporte].ucase
          // this.ucase1 = this.ucase1 + 1
          this.totalVuelta1 = this.totalVuelta1 + 1
          break
        case 2:
          // this.ucase2 = this.ucase2 + this.transportes[transporte].ucase
          // this.ucase2 = this.ucase2 + 1
          this.totalVuelta2 = this.totalVuelta2 + 1
          break
        case 3:
          // this.ucase3 = this.ucase3 + this.transportes[transporte].ucase
          // this.ucase3 = this.ucase3 + 1
          this.totalVuelta3 = this.totalVuelta3 + 1
          break
        case 4:
          // this.ucase4 = this.ucase4 + this.transportes[transporte].ucase
          // this.ucase4 = this.ucase4 + 1
          this.totalVuelta4 = this.totalVuelta4 + 1
          break
        case 5:
          // this.ucase5 = this.ucase5 + this.transportes[transporte].ucase
          // this.ucase5 = this.ucase5 + 1
          this.totalVuelta5 = this.totalVuelta5 + 1
          break
      }
      switch (this.transportes[transporte].estado) {
        case 'entregada':
          this.entregadas = this.entregadas + 1
          break
        case 'en ruta':
          this.en_ruta = this.en_ruta + 1
          break
        default:
          this.en_espera = this.en_espera + 1
          break
      }
      if (this.transportes[transporte].cliente === 'Andina') {
        switch (this.transportes[transporte].vuelta) {
          case 1:
            this.ucase1 = Math.round(this.ucase1 + this.transportes[transporte].ucase)
            break
          case 2:
            this.ucase2 = Math.round(this.ucase2 + this.transportes[transporte].ucase)
            break
          case 3:
            this.ucase3 = Math.round(this.ucase3 + this.transportes[transporte].ucase)
            break
          case 4:
            this.ucase4 = Math.round(this.ucase4 + this.transportes[transporte].ucase)
            break
          case 5:
            this.ucase5 = Math.round(this.ucase5 + this.transportes[transporte].ucase)
            break
        }
        this.ucaseAndina = this.ucaseAndina + this.transportes[transporte].ucase
        this.totalAndina = this.totalAndina + 1
        if (this.transportes[transporte].estado === 'entregada') {
          this.ucaseAndinaEntregadas = Math.round(this.ucaseAndinaEntregadas + this.transportes[transporte].ucase)
          // this.entregadas = this.entregadas + 1
        } else if (this.transportes[transporte].estado === 'en ruta') {
          this.ucaseAndinaEn_ruta = Math.round(this.ucaseAndinaEn_ruta + this.transportes[transporte].ucase)
          // this.en_ruta = this.en_ruta + 1
        } else {
          this.ucaseAndinaEn_espera = Math.round(this.ucaseAndinaEn_espera + this.transportes[transporte].ucase)
          // this.en_espera = this.en_espera + 1
        }
      } else if (this.transportes[transporte].cliente === 'Sopraval') {
        this.totalSopraval = this.totalSopraval + 1
        this.ucaseSopraval = Math.round(this.ucaseSopraval + this.transportes[transporte].ucase)
      } else if (this.transportes[transporte].cliente === 'Soprole') {
        this.totalSoprole = this.totalSoprole + 1
        this.ucaseSoprole = this.ucaseSoprole + Math.round(this.transportes[transporte].ucase)
      }
    }
    console.log('---total por cliente por vuelta---')
    this.datasetsTotalClientePorVuelta.push(this.totalVuelta1)
    this.datasetsTotalClientePorVuelta.push(this.totalVuelta2)
    this.datasetsTotalClientePorVuelta.push(this.totalVuelta3)
    this.datasetsTotalClientePorVuelta.push(this.totalVuelta4)
    this.datasetsTotalClientePorVuelta.push(this.totalVuelta5)
    console.log('---ucase por estados Andina---')
    this.datasetsUcaseEstadosAndina.push(this.ucaseAndinaEntregadas)
    this.datasetsUcaseEstadosAndina.push(this.ucaseAndinaEn_ruta)
    this.datasetsUcaseEstadosAndina.push(this.ucaseAndinaEn_espera)
    console.log('---ucase por vuelta---')
    this.datasetsUcaseVuelta.push(this.ucase1)
    this.datasetsUcaseVuelta.push(this.ucase2)
    this.datasetsUcaseVuelta.push(this.ucase3)
    this.datasetsUcaseVuelta.push(this.ucase4)
    this.datasetsUcaseVuelta.push(this.ucase5)
    // console.log('ucase1: ' + this.ucase1)
    // console.log('ucase2: ' + this.ucase2)
    // console.log('ucase3: ' + this.ucase3)
    // console.log('ucase4: ' + this.ucase4)
    // console.log('ucase5: ' + this.ucase5)
    console.log('---ucase por cliente---')
    this.datasetsUcaseCliente.push(this.ucaseAndina)
    this.datasetsUcaseCliente.push(this.ucaseSopraval)
    this.datasetsUcaseCliente.push(this.ucaseSoprole)
    console.log('---total por cliente---')
    this.datasetsTotalCliente.push(this.totalAndina)
    this.datasetsTotalCliente.push(this.totalSopraval)
    this.datasetsTotalCliente.push(this.totalSoprole)
    // this.max = this.totalAndina
    // this.valueRange = String(this.totalAndina)
    if (this.totalAndina > this.totalSopraval && this.totalAndina > this.totalSoprole) {
      this.max = this.totalAndina
      this.valueRange = String(this.totalAndina)
    } else if (this.totalSopraval > this.totalAndina && this.totalSopraval > this.totalSoprole) {
      this.max = this.totalSopraval
      this.valueRange = String(this.totalSopraval)
    } else if (this.totalSoprole > this.totalAndina && this.totalSoprole > this.totalSopraval) {
      this.max = this.totalSoprole
      this.valueRange = String(this.totalSoprole)
    }
    // this.max = this.totalAndina[0]
    // if (this.totalSopraval[0] > this.totalAndina[0]) {
    //   this.max = this.totalSopraval[0]
    // } else if (this.totalSoprole[0] > this.totalAndina[0]) {
    //   this.max = this.totalSoprole[0]
    // }
    console.log('andina ucase: ' + this.ucaseAndina)
    console.log('sopraval ucase: ' + this.ucaseSopraval)
    console.log('soprole ucase: ' + this.ucaseSoprole)
    console.log('---estado carga  sopraval---')
    this.datasetsEstadosAndina.push(this.entregadas)
    this.datasetsEstadosAndina.push(this.en_ruta)
    this.datasetsEstadosAndina.push(this.en_espera)
    console.log('entregadas: ' + this.entregadas)
    console.log('en ruta: ' + this.en_ruta)
    console.log('en espera: ' + this.en_espera)
    this.crearTablas()
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    inicialzarVariables () {
      this.datasetsUcaseVuelta = []
      this.datasetsUcaseCliente = []
      this.datasetsEstadosAndina = []
      this.datasetsUcaseEstadosAndina = []
      this.datasetsTotalCliente = []
      this.datasetsTotalClientePorVuelta = []
      this.totalAndina = 0
      this.totalSopraval = 0
      this.totalSoprole = 0
      this.totalVuelta1 = 0
      this.totalVuelta2 = 0
      this.totalVuelta3 = 0
      this.totalVuelta4 = 0
      this.totalVuelta5 = 0
      this.ucase1 = 0
      this.ucase2 = 0
      this.ucase3 = 0
      this.ucase4 = 0
      this.ucase5 = 0
      this.ucaseAndina = 0
      this.ucaseSopraval = 0
      this.ucaseSoprole = 0
      this.entregadas = 0
      this.en_ruta = 0
      this.en_espera = 0
      this.ucaseAndinaEntregadas = 0
      this.ucaseAndinaEn_ruta = 0
      this.ucaseAndinaEn_espera = 0
    },
    async getTransportes (periodo) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getTransportes`,
          data: {
            // fechaDesde: '2022-08-19T00:00:00.000+00:00',
            fechaDesde: periodo.fechaDesde,
            fechaHasta: periodo.fechaHasta
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api transporte')
          // console.log(response.data)
          return response.data
        } else {
          // commit('set_token', 'clave o usuario invalido')
          console.log('por else transporte')
        }
        return {}
      } catch (err) {
        console.error(err)
        console.log('por catch transporte')
        router.push({ path: '/' })
        // dispatch('GetActividadesCoordinador')
        // this.dispatch.GetActividadesCoordinador()
        // commit('set_token', 'clave o usuario invalido')
        return false
      }
    },
    crearTablas () {
      // datasetsTotalCliente: [],
      // datasetsTotalClientePorVuelta: [],
      // datasetsUcaseVuelta: [],
      // datasetsEstadosAndina: [],
      // datasetsUcaseEstadosAndina: [],
      this.itemsClientes = []
      this.itemsVueltas = []
      this.itemsEstados = []
      this.itemsEstadosAndinaUC = []
      this.itemsVueltasAndinaUC = []
      // console.log(this.datasetsTotalCliente)
      for (const data in this.datasetsTotalCliente) {
        const item = {}
        item.value = this.datasetsTotalCliente[data]
        switch (data) {
          case '0':
            item.cliente = 'Andina'
            break
          case '1':
            item.cliente = 'Sopraval'
            break
          case '2':
            item.cliente = 'Soprole'
            break
        }
        this.itemsClientes.push(item)
      }
      // console.log(this.datasetsTotalClientePorVuelta)
      for (const data in this.datasetsTotalClientePorVuelta) {
        const item = {}
        item.value = this.datasetsTotalClientePorVuelta[data]
        switch (data) {
          case '0':
            item.vuelta = '1'
            break
          case '1':
            item.vuelta = '2'
            break
          case '2':
            item.vuelta = '3'
            break
          case '3':
            item.vuelta = '4'
            break
          case '4':
            item.vuelta = '5'
            break
        }
        this.itemsVueltas.push(item)
      }
      // console.log(this.datasetsEstadosAndina)
      for (const data in this.datasetsEstadosAndina) {
        const item = {}
        item.value = this.datasetsEstadosAndina[data]
        switch (data) {
          case '0':
            item.estado = 'Entregadas'
            break
          case '1':
            item.estado = 'En Ruta'
            break
          case '2':
            item.estado = 'Pendiantes A Salir'
            break
        }
        this.itemsEstados.push(item)
      }
      // console.log(this.datasetsUcaseEstadosAndina)
      for (const data in this.datasetsUcaseEstadosAndina) {
        const item = {}
        item.value = this.datasetsUcaseEstadosAndina[data]
        switch (data) {
          case '0':
            item.estado = 'Entregadas'
            break
          case '1':
            item.estado = 'En Ruta'
            break
          case '2':
            item.estado = 'Pendiantes A Salir'
            break
        }
        this.itemsEstadosAndinaUC.push(item)
      }
      // console.log(this.datasetsUcaseVuelta)
      for (const data in this.datasetsUcaseVuelta) {
        const item = {}
        item.value = this.datasetsUcaseVuelta[data]
        switch (data) {
          case '0':
            item.vuelta = '1'
            break
          case '1':
            item.vuelta = '2'
            break
          case '2':
            item.vuelta = '3'
            break
          case '3':
            item.vuelta = '4'
            break
          case '4':
            item.vuelta = '5'
            break
        }
        this.itemsVueltasAndinaUC.push(item)
      }
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi'])
  }
}
