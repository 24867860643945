import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    conductoresBuk: [],
    ayudantesBuk: []
  },
  mutations: {
    set_accion_conductoresBuk (state, conductores) {
      state.conductoresBuk = conductores
    },
    set_accion_ayudantesBuk (state, ayudantes) {
      state.ayudantesBuk = ayudantes
    }
  },
  actions: {
    async getAyudantesBuk ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/buk/ayudantesBuk?role_code=ayudante`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAyudantesBuk')
          console.log(response.data)
          commit('set_accion_ayudantesBuk', response.data)
          return true
        } else {
          console.log('por else getAyudantesBuk')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAyudantesBuk')
        router.push({ path: '/' })
        return false
      }
    },
    async getConductoresBuk ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/buk/ayudantesBuk?role_code=conductor`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getConductoresBuk')
          console.log(response.data)
          commit('set_accion_conductoresBuk', response.data)
          return true
        } else {
          console.log('por else getConductoresBuk')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getConductoresBuk')
        router.push({ path: '/' })
        return false
      }
    },
    async getConductoresTypesBuk ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/buk/typesConductoresBuk`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getConductoresTypesBuk')
          console.log(response.data)
          commit('set_accion_conductoresBuk', response.data)
          return true
        } else {
          console.log('por else getConductoresTypesBuk')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getConductoresTypesBuk')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
