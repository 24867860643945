import { render, staticRenderFns } from "@/components/Layout/Menu/Administracion/Administracion_camiones/Asignar_turno/Asignar_turno.html?vue&type=template&id=feeae846&scoped=true&"
import script from "@/components/Layout/Menu/Administracion/Administracion_camiones/Asignar_turno/Asignar_turno.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/Administracion/Administracion_camiones/Asignar_turno/Asignar_turno.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/Administracion/Administracion_camiones/Asignar_turno/Asignar_turno.css?vue&type=style&index=0&id=feeae846&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "feeae846",
  null
  
)

export default component.exports