import { render, staticRenderFns } from "@/components/Layout/Menu/Menu.html?vue&type=template&id=2f8d4b9a&scoped=true&"
import script from "@/components/Layout/Menu/Menu.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/Menu.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/Menu.css?vue&type=style&index=0&id=2f8d4b9a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f8d4b9a",
  null
  
)

export default component.exports