import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CrearClietes',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      selectedCliente: null,
      optionsCliente: [{ value: null, text: '- Cliente' }],
      nombre: null,
      descripcion: null
    }
  },
  watch: {
  },
  mounted () {
    this.getClientes().then((res) => {
      // console.log(this.clientes)
      this.clientes.forEach(element => this.optionsCliente.push({
        value: element.nombre,
        text: element.nombre
      }))
    })
  },
  methods: {
    ...mapActions('Cliente', [
      'insertClientes', 'deleteClientesById', 'getClientes'
    ]),
    crear () {
      console.log(this.nombre)
      console.log(this.descripcion)
      console.log(this.selectedCliente)
      if (this.nombre === null) {
        const toast = {}
        toast.title = 'Error'
        toast.body = 'Se debe ingresar nombre'
        toast.variant = 'danger'
        this.makeToast(toast)
        return
      }
      if (this.nombre === 'Andina') {
        const toast = {}
        toast.title = 'Error'
        toast.body = 'El nombre no puede ser Andina (nombre reservado)'
        toast.variant = 'danger'
        this.makeToast(toast)
        return
      }
      const clienteNuevo = [{
        nombre: this.nombre,
        descripcion: this.descripcion,
        centro_costo: this.selectedCliente
      }]
      // console.log(clienteNuevo)
      // this.$emit('emitCreateCliente', new Date())
      this.insertClientes(clienteNuevo).then((res) => {
        this.clientes.forEach(element => this.optionsCliente.push({
          value: element.nombre,
          text: element.nombre
        }))
        this.$emit('emitCreateCliente', new Date())
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Cliente', ['clientes'])
  }
}
