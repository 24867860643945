import * as XLSX from 'xlsx/xlsx.mjs'

export default {
  name: 'ReportesQrDocumentos',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {},
      docs: [],
      items: [],
      fields: [
        {
          key: 'nro_documento',
          label: 'Nro',
          sortable: false
        },
        {
          key: 'v06_coment',
          label: 'Comentario',
          sortable: false
        },
        {
          key: 'v06_est_entr',
          label: 'Est Entr',
          sortable: true
        },
        {
          key: 'v06_image',
          label: 'Url Imagen',
          sortable: true
        },
        {
          key: 'v06_nro_doc',
          label: 'Nro Doc',
          sortable: true
        },
        {
          key: 'v06_tip_doc',
          label: 'Tipo Doc',
          sortable: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'nro_documento',
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      activeColor: 'arial',
      fontSize: 13
    }
  },
  watch: {
    // dataFiltroReporte: function (val) {
    // }
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    for (const doc in this.dataJSON.docs) {
      if (this.dataJSON.docs[doc] !== null) {
        this.docs.push(this.dataJSON.docs[doc])
      }
    }
    this.items = this.docs
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants')) {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'documentos_reporte')
      XLSX.writeFile(wb, 'documentos_reporte_qr.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    }
  },
  computed: {
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
