import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EditarClietes',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {},
      nombre: null,
      descripcion: null
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    this.nombre = this.dataJSON.nombre
    this.descripcion = this.dataJSON.descripcion
    // console.log(this.dataJSON)
  },
  methods: {
    ...mapActions('Cliente', [
      'insertClientes', 'deleteClientesById', 'getClientes', 'updateById'
    ]),
    editar () {
      if (this.nombre === null) {
        const toast = {}
        toast.title = 'Error'
        toast.body = 'Se debe ingresar nombre'
        toast.variant = 'danger'
        this.makeToast(toast)
        return
      }
      if (this.nombre === 'Andina' && this.dataJSON.nombre !== 'Andina') {
        const toast = {}
        toast.title = 'Error'
        toast.body = 'El nombre no puede ser Andina (nombre reservado)'
        toast.variant = 'danger'
        this.makeToast(toast)
        this.nombre = null
        // return
      }
      const cliente = {}
      if (this.nombre !== null) cliente.nombre = this.nombre
      if (this.descripcion !== null) cliente.descripcion = this.descripcion
      cliente.id = this.dataJSON.id
      // console.log(cliente)
      // this.$emit('emitEditCliente', new Date())
      this.updateById(cliente).then((res) => {
        this.$emit('emitEditCliente', new Date())
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Cliente', ['clientes'])
  }
}
