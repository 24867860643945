import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CrearUsuario',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      username: '',
      nombre: '',
      email: '',
      phone: '',
      active: '',
      password: '',
      grupos: 0,
      application: '',
      selectedRole: null,
      optionsRole: [
        { value: null, text: '- Roles' }
      ]
    }
  },
  watch: {
  },
  mounted () {
    this.getRolesVps().then((res) => {
      for (const rol in this.rolesVps) {
        this.optionsRole.push({ value: this.rolesVps[rol].nombre, text: this.rolesVps[rol].nombre })
      }
    })
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones', 'createCamion'
    ]),
    ...mapActions('Usuario', [
      'getUsuariosVps', 'getRolesVps', 'createUsuarioVps'
    ]),
    crearUsuario () {
      if (this.selectedRole === null) {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Se debe seleccionar rol'
        this.makeToast(toast)
        return
      }
      if (this.username === null || this.username === '') {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Se debe ingresar username'
        this.makeToast(toast)
        return
      }
      if (this.email === null || this.email === '') {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Se debe ingresar email'
        this.makeToast(toast)
        return
      }
      if (this.password === null || this.password === '') {
        const toast = {}
        toast.title = 'Error'
        toast.variant = 'danger'
        toast.body = 'Se debe ingresar password'
        this.makeToast(toast)
        return
      }
      const usuario = {}
      usuario.username = this.username
      usuario.nombre = this.nombre
      usuario.email = this.email
      usuario.phone = this.phone
      usuario.rol = this.selectedRole
      usuario.password = this.password
      usuario.application = 'VPS'
      console.log(usuario)
      this.createUsuarioVps(usuario).then((res) => {
        this.$emit('emitCreateUsuario', new Date())
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Administrador', ['administradores']),
    ...mapState('Usuario', ['rolesVps'])
  }
}
