// import { mapActions } from 'vuex'
// import { componentsPlugin } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import router from '@/router/'
import store from '@/store'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ReasignacionTransporte',
  components: {
    // BarChart
  },
  props: {
    transporte: null,
    transportes: null,
    listaTransportesPorVueltas: null
  },
  data: function () {
    return {
      file1: null,
      file2: null,
      ConfirmaConductor: null,
      nro_cam: '',
      patente: '',
      trans1: '',
      trans2: '',
      trans3: '',
      trans4: '',
      trans5: '',
      estado1: '',
      estado2: '',
      estado3: '',
      estado4: '',
      estado5: '',
      conductor: '',
      ayudantes: {},
      Trans1ayudante1: '',
      Trans1ayudante2: '',
      Trans1ayudante3: '',
      Trans2ayudante1: '',
      Trans2ayudante2: '',
      Trans2ayudante3: '',
      Trans3ayudante1: '',
      Trans3ayudante2: '',
      Trans3ayudante3: '',
      Trans4ayudante1: '',
      Trans4ayudante2: '',
      Trans4ayudante3: '',
      Trans5ayudante1: '',
      Trans5ayudante2: '',
      Trans5ayudante3: '',
      base64imageTras1: '123456789',
      base64imageTras2: '',
      base64imageTras3: '',
      base64imageTras4: '',
      base64imageTras5: '',
      IdTransportes: [],
      Conductores: [],
      Clientes: [],
      Documentos: [],
      TipoDocumento: [],
      Destinos: [],
      ConfirmarConductor: [],
      flagEditarEstadoTransporte1: false,
      flagEditarEstadoTransporte2: false,
      flagEditarEstadoTransporte3: false,
      flagEditarEstadoTransporte4: false,
      flagEditarEstadoTransporte5: false,
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: 'Conductor' }
        // { value: 'a', text: 'This is First option' },
        // { value: { C: '3PO' }, text: 'This is an option with object value' }
      ],
      selectedConductor1: null,
      optionsConductor1: [
        { value: null, text: '- Conductor' }
      ],
      selectedConductor2: null,
      optionsConductor2: [
        { value: null, text: '- Conductor' }
      ],
      selectedConductor3: null,
      optionsConductor3: [
        { value: null, text: '- Conductor' }
      ],
      selectedConductor4: null,
      optionsConductor4: [
        { value: null, text: '- Conductor' }
      ],
      selectedConductor5: null,
      optionsConductor5: [
        { value: null, text: '- Conductor' }
      ],
      selectedCamion1: null,
      optionsCamion1: [
        { value: null, text: '- Camion' }
        // { value: 'a', text: 'This is First option' },
        // { value: { C: '3PO' }, text: 'This is an option with object value' }
      ],
      selectedCamion2: null,
      optionsCamion2: [
        { value: null, text: '- Camion' }
      ],
      selectedCamion3: null,
      optionsCamion3: [
        { value: null, text: '- Camion' }
      ],
      selectedCamion4: null,
      optionsCamion4: [
        { value: null, text: '- Camion' }
      ],
      selectedCamion5: null,
      optionsCamion5: [
        { value: null, text: '- Camion' }
      ],
      // selectedTipoDocumento: null,
      optionsTipoDocumento: [
        { value: null, text: 'Seleccionar Tipo Documento' },
        { value: '10', text: 'Guia Despacho' },
        { value: '20', text: 'Factura' }
      ]
    }
  },
  watch: {
    file1: function (val) {
      console.log('observador file1')
      console.log(this.file1)
    },
    transporte: function (val) {
      console.log('entra en observador transporte')
      if (this.transporte !== '') {
        console.log(this.transporte)
        const transporteJSON = JSON.parse(this.transporte)
        this.nro_cam = transporteJSON.nro_cam
        this.patente = transporteJSON.patente
        this.trans1 = transporteJSON.trans1
        this.trans2 = transporteJSON.trans2
        this.trans3 = transporteJSON.trans3
        this.trans4 = transporteJSON.trans4
        this.trans5 = transporteJSON.trans5
        this.estado1 = transporteJSON.estado1
        this.estado2 = transporteJSON.estado2
        this.estado3 = transporteJSON.estado3
        this.estado4 = transporteJSON.estado4
        this.estado5 = transporteJSON.estado5
        this.conductor = transporteJSON.conductor
        this.ayudantes = transporteJSON.ayudantes
        for (let i = 0; i < 5; i++) {
          this.IdTransportes[i] = transporteJSON['idtrans' + (i + 1)]
        }
        for (let i = 0; i < 5; i++) {
          this.Conductores[i] = transporteJSON['conductor' + (i + 1)]
        }
        for (let i = 0; i < 5; i++) {
          this.Clientes[i] = transporteJSON['cliente' + (i + 1)]
        }
        console.log(this.IdTransportes)
      }
    },
    transportes: function (val) {
      console.log('entra en observador transportes-----------------------------')
      // if (this.transporte !== '') {
      //   console.log(this.transportes)
      //   const transportesJSON = JSON.parse(this.transportes)
      //   for (const numeroTransporte in transportesJSON) {
      //     console.log(numeroTransporte)
      //   }
      // }
    }
  },
  mounted () {
    console.log(this.transporte)
    const transporteJSON = JSON.parse(this.transporte)
    this.nro_cam = transporteJSON.nro_cam
    this.patente = transporteJSON.patente
    this.trans1 = transporteJSON.trans1
    this.trans2 = transporteJSON.trans2
    this.trans3 = transporteJSON.trans3
    this.trans4 = transporteJSON.trans4
    this.trans5 = transporteJSON.trans5
    this.estado1 = transporteJSON.estado1
    this.estado2 = transporteJSON.estado2
    this.estado3 = transporteJSON.estado3
    this.estado4 = transporteJSON.estado4
    this.estado5 = transporteJSON.estado5
    this.conductor = transporteJSON.conductor
    this.ayudantes = transporteJSON.ayudantes
    for (let i = 0; i < 5; i++) {
      this.IdTransportes[i] = transporteJSON['idtrans' + (i + 1)]
    }
    for (let i = 0; i < 5; i++) {
      this.Conductores[i] = transporteJSON['conductor' + (i + 1)]
    }
    for (let i = 0; i < 5; i++) {
      this.Clientes[i] = transporteJSON['cliente' + (i + 1)]
    }
    console.log(this.IdTransportes)
    console.log(this.Conductores)
    console.log(this.Clientes)
    for (const camion in this.camiones) {
      // let flag1 = false
      // for (const listaTransportesPorVuelta in this.listaTransportesPorVueltas[0]) {
      //   if (this.listaTransportesPorVueltas[0][listaTransportesPorVuelta].cam_basis === this.camiones[camion].cam_basis) {
      //     flag1 = true
      //     break
      //   }
      // }
      // if (!flag1) {
      //   this.optionsCamion1.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
      // }
      // let flag2 = false
      // for (const listaTransportesPorVuelta in this.listaTransportesPorVueltas[1]) {
      //   if (this.listaTransportesPorVueltas[1][listaTransportesPorVuelta].cam_basis === this.camiones[camion].cam_basis) {
      //     flag2 = true
      //     break
      //   }
      // }
      // if (!flag2) {
      //   this.optionsCamion2.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
      // }
      // let flag3 = false
      // for (const listaTransportesPorVuelta in this.listaTransportesPorVueltas[2]) {
      //   if (this.listaTransportesPorVueltas[2][listaTransportesPorVuelta].cam_basis === this.camiones[camion].cam_basis) {
      //     flag3 = true
      //     break
      //   }
      // }
      // if (!flag3) {
      //   this.optionsCamion3.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
      // }
      // let flag4 = false
      // for (const listaTransportesPorVuelta in this.listaTransportesPorVueltas[3]) {
      //   if (this.listaTransportesPorVueltas[3][listaTransportesPorVuelta].cam_basis === this.camiones[camion].cam_basis) {
      //     flag4 = true
      //     break
      //   }
      // }
      // if (!flag4) {
      //   this.optionsCamion4.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
      // }
      // let flag5 = false
      // for (const listaTransportesPorVuelta in this.listaTransportesPorVueltas[4]) {
      //   if (this.listaTransportesPorVueltas[4][listaTransportesPorVuelta].cam_basis === this.camiones[camion].cam_basis) {
      //     flag5 = true
      //   }
      // }
      // if (!flag5) {
      //   this.optionsCamion5.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
      // }
      this.optionsCamion1.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
      this.optionsCamion2.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
      this.optionsCamion3.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
      this.optionsCamion4.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
      this.optionsCamion5.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
    }
    for (const conductor in this.conductores) {
      this.optionsConductor1.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
      this.optionsConductor2.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
      this.optionsConductor3.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
      this.optionsConductor4.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
      this.optionsConductor5.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
    }
    this.optionsConductor1.sort((a, b) => a.text.localeCompare(b.text))
    this.optionsConductor2.sort((a, b) => a.text.localeCompare(b.text))
    this.optionsConductor3.sort((a, b) => a.text.localeCompare(b.text))
    this.optionsConductor4.sort((a, b) => a.text.localeCompare(b.text))
    this.optionsConductor5.sort((a, b) => a.text.localeCompare(b.text))
  },
  methods: {
    ...mapActions([
    ]),
    Editar () {
      console.log('test Editar')
      if ((this.selectedConductor1 !== null || this.selectedCamion1 !== null) && this.trans1 !== null) {
        console.log('trans1: ' + this.trans1)
        console.log('no es null 1: ' + this.selectedConductor1)
        console.log('no es null 1: ' + this.selectedCamion1)
        const datos = { conductor: this.selectedConductor1, camion: this.selectedCamion1, trans1: this.trans1 }
        console.log(datos)
        this.updateAgenda(datos).then((res) => {
          console.log('cargado conducto/camion1')
          console.log(res)
          this.$emit('flagUpdate', true)
        })
      }
      if ((this.selectedConductor2 !== null || this.selectedCamion2 !== null) && this.trans2 !== undefined) {
        console.log('trans2: ' + this.trans2)
        console.log('no es null 2: ' + this.selectedConductor2)
        console.log('no es null 2: ' + this.selectedCamion2)
        const datos = { conductor: this.selectedConductor2, camion: this.selectedCamion2, trans2: this.trans2 }
        console.log(datos)
        this.updateAgenda(datos).then((res) => {
          console.log('cargado conducto/camion2')
          console.log(res)
          this.$emit('flagUpdate', true)
        })
      }
      if ((this.selectedConductor3 !== null || this.selectedCamion3 !== null) && this.trans3 !== undefined) {
        console.log('trans3: ' + this.trans3)
        console.log('no es null 3: ' + this.selectedConductor3)
        console.log('no es null 3: ' + this.selectedCamion3)
        const datos = { conductor: this.selectedConductor3, camion: this.selectedCamion3, trans3: this.trans3 }
        console.log(datos)
        this.updateAgenda(datos).then((res) => {
          console.log('cargado conducto/camion3')
          console.log(res)
          this.$emit('flagUpdate', true)
        })
      }
      if ((this.selectedConductor4 !== null || this.selectedCamion4 !== null) && this.trans4 !== undefined) {
        console.log('trans4: ' + this.trans4)
        console.log('no es null 4: ' + this.selectedConductor4)
        console.log('no es null 4: ' + this.selectedCamion4)
        const datos = { conductor: this.selectedConductor4, camion: this.selectedCamion4, trans4: this.trans4 }
        console.log(datos)
        this.updateAgenda(datos).then((res) => {
          console.log('cargado conducto/camion4')
          console.log(res)
          this.$emit('flagUpdate', true)
        })
      }
      if ((this.selectedConductor5 !== null || this.selectedCamion5 !== null) && this.trans5 !== undefined) {
        console.log('trans5: ' + this.trans5)
        console.log('no es null 5: ' + this.selectedConductor5)
        console.log('no es null 5: ' + this.selectedCamion5)
        const datos = { conductor: this.selectedConductor5, camion: this.selectedCamion5, trans5: this.trans5 }
        console.log(datos)
        this.updateAgenda(datos).then((res) => {
          console.log('cargado conducto/camion5')
          console.log(res)
          this.$emit('flagUpdate', true)
        })
      }
    },
    async updateAgenda (datos) {
      console.log(datos)
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/inalambrik/updateAgenda`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: datos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api camiones')
          console.log(response.data)
          return true
        } else {
          console.log('por else camiones')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch camiones')
        router.push({ path: '/' })
        return false
      }
    },
    CerrarTransporte (transporte) {
      console.log('cerrarTransporte')
      console.log('transporte: ' + transporte)
      const transporteDTO = {}
      transporteDTO.transporte = transporte
      // transporteDTO.status = 'no realizado'
      console.log(transporteDTO)
      this.updateCerrarAgenda(transporteDTO).then((res) => {
        console.log('cargado updateCerrarAgenda')
        console.log(res)
        this.$emit('flagUpdate', true)
      })
    },
    async uploadImageResponse (event, vuelta) {
      this.uploadImage(event, vuelta).then((res) => {
        // console.log(res)
        if (vuelta === 1 && res !== null) {
          this.base64imageTras1 = res
        } else if (vuelta === 2 && res !== null) {
          this.base64imageTras2 = res
        } else if (vuelta === 3 && res !== null) {
          this.base64imageTras3 = res
        } else if (vuelta === 4 && res !== null) {
          this.base64imageTras4 = res
        } else if (vuelta === 5 && res !== null) {
          this.base64imageTras5 = res
        }
      })
    },
    uploadImage (event, vuelta) {
      return new Promise((resolve, reject) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          resolve(reader.result)
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      })
      // console.log(event)
      // console.log(vuelta)
      // console.log(event.target.files[0])
      // const file = event.target.files[0]
      // const reader = new FileReader()
      // reader.readAsDataURL(file)
      // reader.onloadend = function () {
      //   console.log('pasa por aca')
      //   if (vuelta === 1 && reader.result !== null) {
      //     console.log('es 1')
      //     this.base64imageTras1 = reader.result
      //     // console.log(this.base64imageTras1)
      //   } else if (vuelta === 2 && reader.result !== null) {
      //     this.base64imageTras2 = reader.result
      //   } else if (vuelta === 3 && reader.result !== null) {
      //     this.base64imageTras3 = reader.result
      //   } else if (vuelta === 4 && reader.result !== null) {
      //     this.base64imageTras4 = reader.result
      //   } else if (vuelta === 5 && reader.result !== null) {
      //     this.base64imageTras5 = reader.result
      //   }
      //   return true
      // }
      // reader.onerror = function (error) {
      //   console.log('Error: ', error)
      // }
      // console.log(reader.result)
      // console.log(this.base64imageTras1)
    },
    terminarTransporte (vuelta) {
      console.log('terminarTransporte')
      let transporte = 0
      let ayudante1 = ''
      let ayudante2 = ''
      let ayudante3 = ''
      let imagenBase64 = ''
      let id = 0
      let cliente = ''
      let conductor = ''
      let documento = ''
      let destino = ''
      let tipoDocumento = ''
      switch (vuelta) {
        case 1:
          transporte = this.trans1
          ayudante1 = this.Trans1ayudante1
          ayudante2 = this.Trans1ayudante2
          ayudante3 = this.Trans1ayudante3
          imagenBase64 = this.base64imageTras1
          id = this.IdTransportes[0]
          cliente = this.Clientes[0]
          conductor = this.Conductores[0]
          documento = this.Documentos[0]
          destino = this.Destinos[0]
          tipoDocumento = this.TipoDocumento[0]
          break
        case 2:
          transporte = this.trans2
          ayudante1 = this.Trans2ayudante1
          ayudante2 = this.Trans2ayudante2
          ayudante3 = this.Trans2ayudante3
          imagenBase64 = this.base64imageTras2
          id = this.IdTransportes[1]
          cliente = this.Clientes[1]
          conductor = this.Conductores[1]
          documento = this.Documentos[1]
          destino = this.Destinos[1]
          tipoDocumento = this.TipoDocumento[1]
          break
        case 3:
          transporte = this.trans3
          ayudante1 = this.Trans3ayudante1
          ayudante2 = this.Trans3ayudante2
          ayudante3 = this.Trans3ayudante3
          imagenBase64 = this.base64imageTras3
          id = this.IdTransportes[2]
          cliente = this.Clientes[2]
          conductor = this.Conductores[2]
          documento = this.Documentos[2]
          destino = this.Destinos[2]
          tipoDocumento = this.TipoDocumento[2]
          break
        case 4:
          transporte = this.trans4
          ayudante1 = this.Trans4ayudante1
          ayudante2 = this.Trans4ayudante2
          ayudante3 = this.Trans4ayudante3
          imagenBase64 = this.base64imageTras4
          id = this.IdTransportes[3]
          cliente = this.Clientes[3]
          conductor = this.Conductores[3]
          documento = this.Documentos[3]
          destino = this.Destinos[3]
          tipoDocumento = this.TipoDocumento[3]
          break
        case 5:
          transporte = this.trans5
          ayudante1 = this.Trans5ayudante1
          ayudante2 = this.Trans5ayudante2
          ayudante3 = this.Trans5ayudante3
          imagenBase64 = this.base64imageTras5
          id = this.IdTransportes[4]
          cliente = this.Clientes[4]
          conductor = this.Conductores[4]
          documento = this.Documentos[4]
          destino = this.Destinos[4]
          tipoDocumento = this.TipoDocumento[4]
          break
      }
      const datos = {}
      datos.transporte = transporte
      datos.vuelta = vuelta
      datos.conductor = this.ConfirmaConductor
      datos.ayudante1 = ayudante1
      datos.ayudante2 = ayudante2
      datos.ayudante3 = ayudante3
      datos.id = id
      datos.cliente = cliente
      datos.conductor = conductor
      datos.nro_documento = parseInt(documento)
      datos.destino = destino
      datos.tipo_documento = tipoDocumento
      datos.cargarImagen = {
        imagen_base64: encodeURIComponent(imagenBase64.substring(23)),
        name_imagen: id + '_0'
      }
      console.log(datos)
      this.TerminarTransporteRequest(datos).then((res) => {
        console.log('cargado TerminarTransporteRequest')
        console.log(res)
        this.$emit('flagUpdate', true)
      })
    },
    comenzarTransporte (vuelta) {
      console.log('comenzarTransporte')
      let transporte = 0
      let id = 0
      let cliente = ''
      let conductor = ''
      switch (vuelta) {
        case 1:
          transporte = this.trans1
          id = this.IdTransportes[0]
          cliente = this.Clientes[0]
          conductor = this.Conductores[0]
          break
        case 2:
          transporte = this.trans2
          id = this.IdTransportes[1]
          cliente = this.Clientes[1]
          conductor = this.Conductores[1]
          break
        case 3:
          transporte = this.trans3
          id = this.IdTransportes[2]
          cliente = this.Clientes[2]
          conductor = this.Conductores[2]
          break
        case 4:
          transporte = this.trans4
          id = this.IdTransportes[3]
          cliente = this.Clientes[3]
          conductor = this.Conductores[3]
          break
        case 5:
          transporte = this.trans5
          id = this.IdTransportes[4]
          cliente = this.Clientes[4]
          conductor = this.Conductores[4]
          break
      }
      const datos = {}
      datos.transporte = transporte
      datos.vuelta = vuelta
      datos.id = id
      datos.cliente = cliente
      datos.conductor = conductor
      this.comenzarTransporteRequest(datos).then((res) => {
        console.log('cargado comenzarTransporteRequest')
        console.log(res)
        this.$emit('flagUpdate', true)
      })
    },
    EditarEstadoTransporte (numeroVuelta) {
      switch (numeroVuelta) {
        case 1:
          console.log('EditarEstadoTransporte1')
          this.flagEditarEstadoTransporte1 = !this.flagEditarEstadoTransporte1
          console.log(this.flagEditarEstadoTransporte1)
          break
        case 2:
          console.log('EditarEstadoTransporte2')
          this.flagEditarEstadoTransporte2 = !this.flagEditarEstadoTransporte2
          console.log(this.flagEditarEstadoTransporte2)
          break
        case 3:
          console.log('EditarEstadoTransporte3')
          this.flagEditarEstadoTransporte3 = !this.flagEditarEstadoTransporte3
          console.log(this.flagEditarEstadoTransporte3)
          break
        case 4:
          console.log('EditarEstadoTransporte4')
          this.flagEditarEstadoTransporte4 = !this.flagEditarEstadoTransporte4
          console.log(this.flagEditarEstadoTransporte4)
          break
        case 5:
          console.log('EditarEstadoTransporte5')
          this.flagEditarEstadoTransporte5 = !this.flagEditarEstadoTransporte5
          console.log(this.flagEditarEstadoTransporte5)
          break
      }
    },
    AbrirTransporte (transporte, estado, id, cliente) {
      console.log('AbrirTransporte')
      console.log('transporte: ' + transporte)
      console.log('estado: ' + estado)
      const transporteDTO = {}
      transporteDTO.transporte = transporte
      transporteDTO.id = id
      transporteDTO.cliente = cliente
      if (estado === 'no realizado') {
        console.log('no realizado')
        transporteDTO.agenda_status = 'N'
      } else if (estado === 'entregada') {
        console.log('entregada')
        transporteDTO.agenda_status = 'R'
      }
      this.abrirAgenda(transporteDTO).then((res) => {
        console.log('cargado abrirAgenda')
        console.log(res)
        this.$emit('flagUpdate', true)
      })
    },
    async updateCerrarAgenda (datos) {
      console.log(datos)
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/inalambrik/setAgendasNoRealizadasWithTransporte`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: datos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateCerrarAgenda')
          console.log(response.data)
          return true
        } else {
          console.log('por else updateCerrarAgenda')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateCerrarAgenda')
        router.push({ path: '/' })
        return false
      }
    },
    async abrirAgenda (datos) {
      console.log(datos)
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/inalambrik/reaperturaTransporte`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: datos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api abrirAgenda')
          console.log(response.data)
          return true
        } else {
          console.log('por else abrirAgenda')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch abrirAgenda')
        router.push({ path: '/' })
        return false
      }
    },
    async comenzarTransporteRequest (datos) {
      console.log(datos)
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/inalambrik/comenzarVuelta`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: datos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api comenzarTransporteRequest')
          console.log(response.data)
          return true
        } else {
          console.log('por else comenzarTransporteRequest')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch comenzarTransporteRequest')
        router.push({ path: '/' })
        return false
      }
    },
    async TerminarTransporteRequest (datos) {
      console.log(datos)
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/inalambrik/finalizarVuelta`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: datos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api TerminarTransporteRequest')
          console.log(response.data)
          return true
        } else {
          console.log('por else TerminarTransporteRequest')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch TerminarTransporteRequest')
        router.push({ path: '/' })
        return false
      }
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores'])
  }
}
