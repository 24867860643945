import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
import BarChart from '@/components/Layout/Menu/Common/Graficos/BarChart/BarChart.vue'
import PieChart from '@/components/Layout/Menu/Common/Graficos/PieChart/PieChart.vue'
import FiltroReportes from '@/components/Layout/Menu/Filtros/Filtro_Reportes/FiltroReportes.vue'

export default {
  name: 'ReporteEquiposGt',
  components: {
    FiltroReportes,
    BarChart,
    PieChart
  },
  props: {
  },
  data: function () {
    return {
      dataFiltroReporte: '',
      emitRender: '',
      render: '',
      renderRange: false,
      labelGuiasTransporte: ['Pendiente a Salir', 'En Ruta', 'Entregadas (o Realizadas)', 'No Realizadas'],
      labelPatentesPorCliente: ['Asignados', 'No Asignados'],
      labelConductoresPorCliente: ['Asignados', 'No Asignados'],
      labelConductores: ['Disponible', 'No Disponible'],
      datasetsGuiasTransporte: [1, 1, 1, 1],
      datasetsConductoresPorCliente: [1, 2],
      datasetsPatentesPorCliente: [1, 2],
      datasetsConductores: [1, 2],
      backgroundColorGuiasTransporte: [
        '#00D8FF',
        'rgb(255, 255, 0)',
        'rgb(19, 255, 0)',
        '#FF0000'
        // 'rgb(255, 143, 0)'
        // 'rgb(128, 128, 0)'
      ],
      backgroundColorConductoresPorCliente: [
        'rgb(19, 255, 0)',
        '#FF0000'
        // 'rgb(19, 255, 0)'
        // 'rgb(128, 128, 0)'
      ],
      backgroundColorPatentesPorCliente: [
        'rgb(19, 255, 0)',
        '#FF0000'
        // 'rgb(19, 255, 0)'
        // 'rgb(128, 128, 0)'
      ],
      backgroundColorConductores: [
        'rgb(19, 255, 0)',
        '#FF0000'
        // 'rgb(19, 255, 0)'
        // 'rgb(128, 128, 0)'
      ],
      max: '3',
      valueRange: '90',
      maxConductores: '0',
      valueRangeConductores: '0',
      maxPatentes: '0',
      valueRangePatentes: '0',
      margenValueRange: 5,
      transportesCount: []
    }
  },
  watch: {
    dataFiltroReporte: function (val) {
      console.log('observador dataFiltroReporte')
      // console.log(this.dataFiltroReporte)
      this.transportesCount = []
      this.getClientes().then((res) => {
        // console.log(this.clientes)
        const periodo = this.dataFiltroReporte
        for (const cliente in this.clientes) {
          // this.transportesCount.push(this.clientes[cliente].nombre)
          const data = {
            fechaDesde: periodo.fechaDesde,
            fechaHasta: periodo.fechaHasta,
            cliente: this.clientes[cliente].nombre
          }
          // console.log(data)
          this.countTransportesByCliente(data).then((res) => {
            // console.log(res)
            const datasets = [res.pendiente_salir, res.en_ruta, res.entregadas, res.no_realizado]
            const data = {
              cliente: res.cliente,
              datasets: datasets
            }
            // console.log(data)
            this.transportesCount.push(data)
          })
        }
        // console.log(this.transportesCount)
        this.render = !this.render
      })
      this.render = !this.render
    },
    emitRender: function (val) {
      console.log('observador emitRender')
      if (this.emitRender) {
        this.render = !this.render
        // this.emitRender = false
        // this.datasetsGuiasTransporte = []
      }
    }
  },
  mounted () {
    this.getClientes().then((res) => {
      // console.log(this.clientes)
      const periodo = this.PeriodoFechaActual()
      for (const cliente in this.clientes) {
        // this.transportesCount.push(this.clientes[cliente].nombre)
        const data = {
          fechaDesde: periodo.fechaDesde,
          fechaHasta: periodo.fechaHasta,
          cliente: this.clientes[cliente].nombre
        }
        // console.log(data)
        this.countTransportesByCliente(data).then((res) => {
          // console.log(res)
          const datasets = [res.pendiente_salir, res.en_ruta, res.entregadas, res.no_realizado]
          const data = {
            cliente: res.cliente,
            datasets: datasets
          }
          // console.log(data)
          this.transportesCount.push(data)
        })
      }
      // console.log(this.transportesCount)
      this.render = !this.render
    })
    this.getConductoresByCliente().then((res) => {
      this.datasetsConductoresPorCliente = []
      this.datasetsConductoresPorCliente[0] = this.conductoresAsignadosCliente.asignado
      this.datasetsConductoresPorCliente[1] = this.conductoresAsignadosCliente.no_asignado
      this.maxConductores = null
      this.valueRangeConductores = null
      if (this.datasetsConductoresPorCliente[0] > this.datasetsConductoresPorCliente[1]) {
        this.maxConductores = (this.datasetsConductoresPorCliente[0] + this.margenValueRange).toString()
        this.valueRangeConductores = (this.datasetsConductoresPorCliente[0] + this.margenValueRange).toString()
      } else {
        this.maxConductores = (this.datasetsConductoresPorCliente[1] + this.margenValueRange).toString()
        this.valueRangeConductores = (this.datasetsConductoresPorCliente[1] + this.margenValueRange).toString()
      }
      this.render = !this.render
    })
    this.getPatentesByCliente().then((res) => {
      this.datasetsPatentesPorCliente = []
      this.datasetsPatentesPorCliente[0] = this.patentesAsignadosCliente.asignado
      this.datasetsPatentesPorCliente[1] = this.patentesAsignadosCliente.no_asignado
      this.maxPatentes = null
      this.valueRangePatentes = null
      if (this.datasetsPatentesPorCliente[0] > this.datasetsPatentesPorCliente[1]) {
        this.maxPatentes = (this.datasetsPatentesPorCliente[0] + this.margenValueRange).toString()
        this.valueRangePatentes = (this.datasetsPatentesPorCliente[0] + this.margenValueRange).toString()
      } else {
        this.maxPatentes = (this.datasetsPatentesPorCliente[1] + this.margenValueRange).toString()
        this.valueRangePatentes = (this.datasetsPatentesPorCliente[1] + this.margenValueRange).toString()
      }
      this.render = !this.render
      // console.log(this.patentesAsignadosCliente)
    })
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    ...mapActions('Cliente', [
      'getClientes', 'getConductoresByCliente', 'getPatentesByCliente'
    ]),
    async countTransportesByCliente (data) {
      try {
        console.log(data)
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/countTransportesByCliente`,
          data: data,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else countTransportesByCliente')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch countTransportesByCliente')
        router.push({ path: '/' })
        return false
      }
    },
    PeriodoFechaActual () {
      const date = new Date()
      // const fechaActual = `${
      //   date.getFullYear().toString().padStart(4, '0')}-${
      //     (date.getMonth() + 1).toString().padStart(2, '0')}-${
      //       date.getDate().toString().padStart(2, '0')}T${
      //         date.getHours().toString().padStart(2, '0')}:${
      //           date.getMinutes().toString().padStart(2, '0')}:${
      //             date.getSeconds().toString().padStart(2, '0')}.000+00:00`
      const fechaActualPrimeraHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualUltimaHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '23'}:${
                '59'}:${
                  '59'}`
      // console.log(fechaActualPrimeraHora)
      // console.log(fechaActualUltimaHora)
      const periodo = {}
      periodo.fechaDesde = fechaActualPrimeraHora
      periodo.fechaHasta = fechaActualUltimaHora
      return periodo
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('Cliente', ['clientes']),
    ...mapState('Cliente', ['conductoresAsignadosCliente']),
    ...mapState('Cliente', ['patentesAsignadosCliente'])
  }
}
