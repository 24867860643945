import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    patentes: [],
    camiones: []
  },
  mutations: {
    set_accion_patentes (state, patentes) {
      state.patentes = patentes
    },
    set_accion_camiones (state, camiones) {
      state.camiones = camiones
    }
  },
  actions: {
    async getPatentes ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/inalambrik/getSites`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getPatentes')
          console.log(response.data)
          commit('set_accion_patentes', response.data)
          return true
        } else {
          console.log('por else getPatentes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getPatentes')
        router.push({ path: '/' })
        return false
      }
    },
    async insertPatentes ({ commit, dispatch, state }, patente) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/inalambrik/setSites`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: patente
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api insertPatentes')
          console.log(response.data)
          commit('set_accion_patentes', response.data)
          return true
        } else {
          console.log('por else insertPatentes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch insertPatentes')
        router.push({ path: '/' })
        return false
      }
    },
    async deletePatentesById ({ commit, dispatch, state }, idPatente) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/vps/clientes/delete/${idPatente}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api deletePatentesById')
          console.log(response.data)
          commit('set_accion_patentes', response.data)
          return true
        } else {
          console.log('por else deletePatentesById')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch deletePatentesById')
        router.push({ path: '/' })
        return false
      }
    },
    async getCamiones ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/users/getCamion`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getCamiones')
          console.log(response.data)
          commit('set_accion_camiones', response.data)
          return true
        } else {
          console.log('por else getCamiones')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getCamiones')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
