import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
import EditarDestinos from '@/components/Layout/Menu/Administracion/Administracion_destinos/Editar_destinos/EditarDestinos.vue'
import CrearCamiones from '@/components/Layout/Menu/Administracion/Administracion_camiones/Crear_camiones/CrearCamiones.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AdministracionDestinos',
  components: {
    EditarDestinos,
    CrearCamiones
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        {
          key: 'cliente',
          label: 'Cliente',
          sortable: false
        },
        {
          key: 'direccion',
          label: 'Direccion',
          sortable: false
        },
        {
          key: 'destino',
          label: 'Destino',
          sortable: true
        },
        {
          key: 'km_defin',
          label: 'KM DEFIN.',
          sortable: true
        },
        {
          key: 'rut',
          label: 'Rut',
          sortable: true
        },
        {
          key: 'c_costo',
          label: 'C Costo',
          sortable: true
        },
        // {
        //   key: 'prod',
        //   label: 'Prod',
        //   sortable: true
        // },
        // {
        //   key: 'km_defin',
        //   label: 'Km defin',
        //   sortable: true
        // },
        {
          key: 'editar',
          label: 'Editar',
          sortable: false
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      emitEdit: '',
      editar: {
        id: 'editarDestino-modal',
        title: 'Editar',
        content: ''
      },
      CrearCamion: {
        id: 'crearDestino-modal',
        title: 'Crear',
        content: ''
      },
      file: null,
      arrayBuffer: null,
      filelist: null,
      arrayRespuesta: [],
      arrayFormat: []
    }
  },
  watch: {
    emitEdit: function (val) {
      this.getDestinos().then((res) => {
        // const newItems = []
        // for (const item in res) {
        //   const newItem = {}
        //   newItem.camion = res[item].camion.cam_basis
        //   newItem.conductor = res[item].conductor.device_alias
        //   newItem.cliente = res[item].cliente
        //   newItems.push(newItem)
        // }
        this.items = res
        console.log('cargo reporte cargas')
        this.hideModaleditar()
        this.totalRows = this.items.length
      })
      // console.log('observador emitEditCamion')
      // this.getDestinos().then((res) => {
      //   const newItems = []
      //   for (const item in res) {
      //     const newItem = {}
      //     newItem.camion = res[item].camion.cam_basis
      //     newItem.conductor = res[item].conductor.device_alias
      //     newItem.cliente = res[item].cliente
      //     newItems.push(newItem)
      //   }
      //   this.items = newItems
      //   console.log('cargo reporte cargas')
      //   this.totalRows = this.items.length
      // })
      // this.totalRows = this.items.length
      // this.hideModaleditarCamion()
    }
  },
  mounted () {
    this.getDestinos().then((res) => {
      // const newItems = []
      // for (const item in res) {
      //   const newItem = {}
      //   newItem.camion = res[item].camion.cam_basis
      //   newItem.conductor = res[item].conductor.device_alias
      //   newItem.cliente = res[item].cliente
      //   newItems.push(newItem)
      // }
      this.items = res
      console.log('cargo reporte cargas')
      this.totalRows = this.items.length
    })
    // this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    editarCamionModal (item, index, button) {
      this.editar.title = `Row index: ${index}`
      this.editar.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editar.id, button)
    },
    crearCamionModal (item, index, button) {
      // this.CrearCamion.title = `Row index: ${index}`
      this.CrearCamion.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.CrearCamion.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    hideModaleditar () {
      this.$refs[this.editar.id].hide()
    },
    hideModalcrearCamion () {
      this.$refs[this.CrearCamion.id].hide()
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants')) {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'transportes')
      XLSX.writeFile(wb, 'transportes.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    async getDestinos () {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/vueltas/getDestinos`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else getDestinos')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getDestinos')
        router.push({ path: '/' })
        return false
      }
    },
    test () {
      this.getDestinos().then((res) => {
        this.items = res
        console.log('cargo getDestinos')
      })
    },
    addfile (event) {
      // console.log(event)
      this.file = event.target.files[0]
      const fileReader = new FileReader()
      try {
        fileReader.readAsArrayBuffer(this.file)
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        this.arrayRespuesta = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        // console.log(this.arrayRespuesta)
        this.filelist = []
        for (const array in this.arrayRespuesta) {
          const aux = {}
          aux.nombre = this.arrayRespuesta[array].nombre.trim()
          const rut = this.arrayRespuesta[array].rut.trim()
          if (rut.length >= 2) {
            // const parte1 = rut.slice(0, -1)
            // const parte2 = rut.slice(-1)
            // const cadenaConGuion = parte1 + '-' + parte2
            aux.rut = rut + '-' + this.calculaNroVerificadorRut(rut)
          }
          this.arrayFormat.push(aux)
        }
        // console.log(this.arrayFormat)
      }
    },
    async setDestinos (data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/setDestinos`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else setDestinos')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setDestinos')
        // router.push({ path: '/' })
        return false
      }
    },
    cargarDestinos () {
      console.log(this.arrayFormat)
      this.setDestinos(this.arrayFormat).then((res) => {
        console.log('cargo setDestinos')
        console.log(res)
        const toast = {}
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        toast.body = ''
        this.makeToast(toast)
        this.getDestinos().then((res) => {
          this.items = res
          console.log('cargo reporte cargas')
          this.totalRows = this.items.length
        })
      })
    },
    calculaNroVerificadorRut (T) {
      let M = 0
      let S = 1
      for (; T; T = Math.floor(T / 10)) {
        S = (S + T % 10 * (9 - M++ % 6)) % 11
      }
      return S ? S - 1 : 'k'
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
