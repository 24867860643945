import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
import EditarCamiones from '@/components/Layout/Menu/Administracion/Administracion_patentes/Editar_patentes/EditarPatentesView.vue'
// import CrearCamiones from '@/components/Layout/Menu/Administracion/Administracion_camiones/Crear_camiones/CrearCamiones.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AdministracionCamiones',
  components: {
    EditarCamiones
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        {
          key: 'cam_basis',
          label: 'Nro Cam',
          sortable: false
        },
        {
          key: 'matricula',
          label: 'Patente',
          sortable: false
        },
        {
          key: 'administrador.nombre',
          label: 'Administrador',
          sortable: false
        },
        {
          key: 'vigente',
          label: 'Vigente',
          sortable: false
        }
        // {
        //   key: 'editar',
        //   label: 'Editar',
        //   sortable: false
        // }
      ],
      itemsAux: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      emitEditPatente: '',
      editarPatente: {
        id: 'editarPatente-modal',
        title: 'Editar',
        content: ''
      },
      CrearPatente: {
        id: 'crearPatente-modal',
        title: 'Crear',
        content: ''
      },
      patentesFilter: [
        {
          value: null,
          text: '- Seleccionar Patente'
        }
      ],
      selectedPatente: null,
      camBasis: [
        {
          value: null,
          text: 'Seleccionar NroInterno/Nro Basis'
        }
      ],
      selectedCamBasis: null,
      file: null,
      arrayBuffer: null,
      filelist: null,
      arrayRespuesta: [],
      arrayFormat: []
    }
  },
  watch: {
    emitEditPatente: function (val) {
      console.log('observador emitEditPatente')
      // if (this.emitEditCamion) {
      // }
      // this.hideModalcrearPatente()
      // this.getCamionesConductores().then((res) => {
      //   this.totalRows = this.items.length
      // })
    }
  },
  mounted () {
    this.getCamiones().then((res) => {
      this.items = this.camiones
      this.totalRows = this.items.length
      for (const p in this.items) {
        this.patentesFilter.push({
          value: this.items[p].matricula,
          text: this.items[p].matricula
        })
      }
      this.itemsAux = this.items
    })
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones', 'createCamiones'
    ]),
    ...mapActions('Patentes', [
      'getPatentes', 'insertPatentes', 'getCamiones'
    ]),
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    editarPatenteModal (item, index, button) {
      this.editarPatente.title = 'Editar Patente: ' + item.matricula
      this.editarPatente.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editarPatente.id, button)
    },
    crearPatenteModal (item, index, button) {
      // this.CrearCamion.title = `Row index: ${index}`
      this.CrearPatente.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.CrearPatente.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filtroitems () {
      this.items = this.itemsAux
      if (this.selectedCamBasis !== undefined && this.selectedCamBasis !== null) {
        // let itemsHaFlitrar = this.items
        // this.items = []
        console.log('tiene filtro de cam basis')
        // console.log(this.selectedCamBasis)
        this.items = this.items.filter(camBasis => camBasis.camion === this.selectedCamBasis)
        // this.items = itemsHaFlitrar
      }
      if (this.selectedPatente !== undefined && this.selectedPatente !== null) {
        // let itemsHaFlitrar = this.items
        // this.items = []
        console.log('tiene filtro de patente')
        // console.log(this.selectedPatente)
        // console.log(this.itemsAux)
        this.items = this.items.filter(camBasis => camBasis.site_code === this.selectedPatente)
        // this.items = itemsHaFlitrar
      }
    },
    hideModaleditarPatente () {
      this.$refs[this.editarPatente.id].hide()
    },
    hideModalcrearPatente () {
      this.$refs[this.CrearPatente.id].hide()
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants')) {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'patentes')
      XLSX.writeFile(wb, 'patentes.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    addfile (event) {
      console.log(event)
      this.file = event.target.files[0]
      const fileReader = new FileReader()
      try {
        fileReader.readAsArrayBuffer(this.file)
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        console.log(workbook)
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        this.arrayRespuesta = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        console.log(this.arrayRespuesta)
        this.filelist = []
        console.log(this.filelist)
        // const arrayFormat = []
        for (const array in this.arrayRespuesta) {
          const aux = {}
          aux.alias = this.arrayRespuesta[array].ALIAS
          aux.codigo = this.arrayRespuesta[array].CODIGO
          aux.direccion = this.arrayRespuesta[array].DIRECCION
          aux.latitud = parseFloat(this.arrayRespuesta[array].LATITUD)
          aux.logitud = parseFloat(this.arrayRespuesta[array].LONGITUD)
          aux.nombre = this.arrayRespuesta[array].NOMBRE
          aux.categoria_codigo = this.arrayRespuesta[array]['CATEGORIA CODIGO']
          aux.simbolo = this.arrayRespuesta[array]['SIMBOLO #']
          this.arrayFormat.push(aux)
        }
        console.log(this.arrayFormat)
        // this.cargarExcel(arrayFormat)
      }
    },
    cargarExcel (datos) {
      this.insertPatentes(this.arrayFormat).then((res) => {
        this.items = this.patentes
        this.totalRows = this.items.length
        this.arrayFormat = []
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    },
    // makeToast (variant = null) {
    //   this.$bvToast.toast('Toast body content', {
    //     title: `Variant ${variant || 'default'}`,
    //     variant: variant,
    //     solid: true
    //   })
    // },
    testToast () {
      const toast = {}
      toast.title = 'Carga Exitosa'
      toast.variant = 'success'
      toast.body = ''
      this.makeToast(toast)
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('Patentes', ['patentes']),
    ...mapState('Patentes', ['camiones']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
