import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CargasNoAsignadas',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      modes: ['multi', 'single', 'range'],
      // fields: ['selected', 'trans1', 'trans2', 'trans3', 'trans4', 'trans5', 'vuelta', 'conductor', 'nro_cam'],
      fields: [
        'selected',
        {
          key: 'trans1',
          label: 'Vuelta 1',
          sortable: false
        },
        {
          key: 'trans2',
          label: 'Vuelta 2',
          sortable: false
        },
        {
          key: 'trans3',
          label: 'Vuelta 3',
          sortable: false
        },
        {
          key: 'trans4',
          label: 'Vuelta 4',
          sortable: false
        },
        {
          key: 'trans5',
          label: 'Vuelta 5',
          sortable: false
        },
        {
          key: 'conductor',
          label: 'conductor',
          sortable: false
        },
        {
          key: 'nro_cam',
          label: 'Nro Cam',
          sortable: false
        }
      ],
      items: [],
      selectMode: 'multi',
      selected: [],
      selectedPersistend: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [3, 5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      stickyHeader: true,
      noCollapse: true,
      selectCamion: null,
      // optionsCamion: [
      //   { value: null, text: 'Seleccione Camion' }
      // ],
      selectConductor: null,
      // optionsConductor: [
      //   { value: null, text: 'Seleccione Conductor' }
      // ],
      selectTransporte: null,
      // optionsTransporte: [
      //   { value: null, text: 'Seleccione Transporte' }
      // ],
      selectVuelta: null,
      optionsVuelta: [
        { value: null, text: '- Seleccione Vuelta' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 0, text: 'Todas las vueltas' }
      ]
    }
  },
  watch: {
    // items: function (val) {
    //   console.log('entra en observador items')
    //   console.log(this.items)
    //   this.items = this.itemsComputed
    //   this.totalRows = this.items.length
    // },
    transportesNoAsignados: function (val) {
      console.log('entra en observador transportesNoAsignados')
      this.totalRows = this.items.length
      console.log(this.items)
    },
    selectTransporte: function (val) {
      console.log('entra en observador selectTransporte')
      this.filter = String(this.selectTransporte)
      this.totalRows = this.items.length
    }
  },
  mounted () {
    // for (const camion in this.camiones) {
    //   this.optionsCamion.push({ value: this.camiones[camion].cam_basis, text: this.camiones[camion].cam_basis })
    // }
    // for (const conductor in this.conductores) {
    //   this.optionsConductor.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
    // }
    this.items = []
    for (const transportes in this.transportesNoAsignados) {
      // console.log('registro---------------------------------------------------------')
      const registro = {}
      registro.conductor = this.transportesNoAsignados[transportes].conductor
      registro.camion = this.transportesNoAsignados[transportes].nro_cam
      // console.log(registro.camion)
      // console.log(registro)
      registro.transporte = this.transportesNoAsignados[transportes].transporte
      // if (registro.camion === 5999) {
      //   console.log('es 5999')
      //   console.log(registro.transporte)
      //   continue
      // }
      if (registro.conductor !== 'zz_SinConductor') {
        continue
      }
      this.items.push(registro)
    }
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    onRowSelected (items) {
      this.selected = items
    },
    selectAllRows () {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected () {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow () {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow () {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    Asignar () {
      console.log('Asignar test')
      for (const transporteSeleccionado in this.selected) {
        // console.log(this.selected[transporteSeleccionado].trans1)
        // console.log(this.selectCamion)
        // console.log(this.selectConductor)
        const reasignaciones = []
        const reasignacion = {}
        reasignacion.conductor = this.selectConductor
        reasignacion.camion = this.selectCamion
        switch (this.selectVuelta) {
          case 0:
            console.log('caso 0')
            if (this.selected[transporteSeleccionado].trans1 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans1)
              reasignacion.trans1 = this.selected[transporteSeleccionado].trans1
            }
            if (this.selected[transporteSeleccionado].trans2 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans2)
              reasignacion.trans2 = this.selected[transporteSeleccionado].trans2
            }
            if (this.selected[transporteSeleccionado].trans3 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans3)
              reasignacion.trans3 = this.selected[transporteSeleccionado].trans3
            }
            if (this.selected[transporteSeleccionado].trans4 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans4)
              reasignacion.trans4 = this.selected[transporteSeleccionado].trans4
            }
            if (this.selected[transporteSeleccionado].trans5 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans5)
              reasignacion.trans5 = this.selected[transporteSeleccionado].trans5
            }
            // console.log(this.selected[transporteSeleccionado].trans1)
            // console.log(this.selected[transporteSeleccionado].trans2)
            // console.log(this.selected[transporteSeleccionado].trans3)
            // console.log(this.selected[transporteSeleccionado].trans4)
            // console.log(this.selected[transporteSeleccionado].trans5)
            break
          case 1:
            console.log('caso 1')
            // console.log(this.selected[transporteSeleccionado].trans1)
            if (this.selected[transporteSeleccionado].trans1 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans1)
              reasignacion.trans1 = this.selected[transporteSeleccionado].trans1
            }
            break
          case 2:
            console.log('caso 2')
            // console.log(this.selected[transporteSeleccionado].trans2)
            if (this.selected[transporteSeleccionado].trans2 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans2)
              reasignacion.trans2 = this.selected[transporteSeleccionado].trans2
            }
            break
          case 3:
            console.log('caso 3')
            // console.log(this.selected[transporteSeleccionado].trans3)
            if (this.selected[transporteSeleccionado].trans3 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans3)
              reasignacion.trans3 = this.selected[transporteSeleccionado].trans3
            }
            break
          case 4:
            console.log('caso 4')
            // console.log(this.selected[transporteSeleccionado].trans4)
            if (this.selected[transporteSeleccionado].trans4 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans4)
              reasignacion.trans4 = this.selected[transporteSeleccionado].trans4
            }
            break
          case 5:
            console.log('caso 5')
            // console.log(this.selected[transporteSeleccionado].trans5)
            if (this.selected[transporteSeleccionado].trans5 !== undefined) {
              // console.log(this.selected[transporteSeleccionado].trans5)
              reasignacion.trans5 = this.selected[transporteSeleccionado].trans5
            }
            break
          default:
            console.log('caso 0')
            // console.log(this.selected[transporteSeleccionado].trans1)
            // console.log(this.selected[transporteSeleccionado].trans2)
            // console.log(this.selected[transporteSeleccionado].trans3)
            // console.log(this.selected[transporteSeleccionado].trans4)
            // console.log(this.selected[transporteSeleccionado].trans5)
            break
        }
        reasignaciones.push(reasignacion)
        console.log(reasignacion)
        this.setAsignacion(reasignacion)
      }
    },
    setAsignacion (reasignaciones) {
      console.log(reasignaciones.conductor)
      console.log(reasignaciones.camion)
      // console.log(reasignaciones.trans1)
      // console.log(reasignaciones.trans2)
      // console.log(reasignaciones.trans3)
      // console.log(reasignaciones.trans4)
      // console.log(reasignaciones.trans5)
      let data = {}
      // if (reasignaciones.conductor !== null) {
      //   data.conductor = reasignaciones.conductor
      // }
      // if (reasignaciones.camion !== null) {
      //   data.camion = reasignaciones.camion
      // }
      if (reasignaciones.trans1 !== undefined) {
        console.log('envia transporte1: ' + reasignaciones.trans1)
        data.trans1 = reasignaciones.trans1
        data.conductor = reasignaciones.conductor
        data.camion = reasignaciones.camion
        this.updateAgenda(data).then((res) => {
          console.log('cargado reasignacaion')
          console.log(res)
          this.$emit('flagUpdateCargasNoAsigandas', true)
          // this.$emit('flagUpdate', true)
        })
        data = {}
      }
      if (reasignaciones.trans2 !== undefined) {
        console.log('envia transporte2: ' + reasignaciones.trans2)
        data.trans2 = reasignaciones.trans2
        data.conductor = reasignaciones.conductor
        data.camion = reasignaciones.camion
        this.updateAgenda(data).then((res) => {
          console.log('cargado reasignacaion')
          console.log(res)
          this.$emit('flagUpdateCargasNoAsigandas', true)
          // this.$emit('flagUpdate', true)
        })
        data = {}
      }
      if (reasignaciones.trans3 !== undefined) {
        console.log('envia transporte3: ' + reasignaciones.trans3)
        data.trans3 = reasignaciones.trans3
        data.conductor = reasignaciones.conductor
        data.camion = reasignaciones.camion
        this.updateAgenda(data).then((res) => {
          console.log('cargado reasignacaion')
          console.log(res)
          this.$emit('flagUpdateCargasNoAsigandas', true)
          // this.$emit('flagUpdate', true)
        })
        data = {}
      }
      if (reasignaciones.trans4 !== undefined) {
        console.log('envia transporte4: ' + reasignaciones.trans4)
        data.trans4 = reasignaciones.trans4
        data.conductor = reasignaciones.conductor
        data.camion = reasignaciones.camion
        this.updateAgenda(data).then((res) => {
          console.log('cargado reasignacaion')
          console.log(res)
          this.$emit('flagUpdateCargasNoAsigandas', true)
          // this.$emit('flagUpdate', true)
        })
        data = {}
      }
      if (reasignaciones.trans5 !== undefined) {
        console.log('envia transporte5: ' + reasignaciones.trans5)
        data.trans5 = reasignaciones.trans5
        data.conductor = reasignaciones.conductor
        data.camion = reasignaciones.camion
        this.updateAgenda(data).then((res) => {
          console.log('cargado reasignacaion')
          console.log(res)
          this.$emit('flagUpdateCargasNoAsigandas', true)
          // this.$emit('flagUpdate', true)
        })
        data = {}
      }
      // console.log(data)
    },
    async updateAgenda (datos) {
      console.log(datos)
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/inalambrik/updateAgenda`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: datos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api camiones')
          console.log(response.data)
          return true
        } else {
          console.log('por else camiones')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch camiones')
        this.$bvToast.toast('Error con los datos insertados, favor contactarse con soporte.', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        // router.push({ path: '/' })
        return false
      }
    },
    Actualizar () {
      this.items = []
      for (const transportes in this.transportesNoAsignados) {
        // console.log('registro---------------------------------------------------------')
        const registro = {}
        registro.conductor = this.transportesNoAsignados[transportes].conductor
        registro.camion = this.transportesNoAsignados[transportes].nro_cam
        // console.log(registro.camion)
        // console.log(registro)
        registro.transporte = this.transportesNoAsignados[transportes].transporte
        // if (registro.camion === 5999) {
        //   console.log('es 5999')
        //   console.log(registro.transporte)
        //   continue
        // }
        if (registro.conductor !== 'zz_SinConductor') {
          continue
        }
        this.items.push(registro)
      }
      this.totalRows = this.items.length
    },
    testEmit () {
      console.log('testEmit')
      this.$emit('flagUpdateCargasNoAsigandas', true)
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    noAsignados () {
      this.totalRows = this.items.length
      // this.items = this.transportesNoAsignados
      this.items = []
      // const cams5999 = []
      for (const item in this.transportesNoAsignados) {
        // console.log(this.transportesNoAsignados[item])
        this.items.push(this.transportesNoAsignados[item])
        // if (this.transportesNoAsignados[item].nro_cam !== 5999) {
        //   this.items.push(this.transportesNoAsignados[item])
        // } else {
        //   console.log('es 5999')
        //   console.log(this.transportesNoAsignados[item])
        //   cams5999.push(this.transportesNoAsignados[item])
        // }
      }
      return this.items
    },
    optionsTransporte () {
      const transportes = []
      let data = {}
      data.text = '- Seleccione Transporte'
      data.value = null
      transportes.push(data)
      for (const transporte in this.listaTransportesStore) {
        data = {}
        data.text = this.listaTransportesStore[transporte]
        data.value = this.listaTransportesStore[transporte]
        transportes.push(data)
      }
      this.totalRows = this.items.length
      return transportes
    },
    optionsConductor () {
      let conductoreObject = []
      let data = {}
      data.text = '- Seleccione Conductor'
      data.value = null
      conductoreObject.push(data)
      // console.log(this.conductores)
      for (const conductor in this.conductores) {
        data = {}
        data.text = this.conductores[conductor].device_alias
        data.value = this.conductores[conductor].device_alias
        conductoreObject.push(data)
      }
      this.totalRows = this.items.length
      conductoreObject = conductoreObject.sort((a, b) => a.text.localeCompare(b.text))
      return conductoreObject
    },
    optionsCamion () {
      const camionObject = []
      let data = {}
      data.text = '- Seleccione Camion'
      data.value = null
      camionObject.push(data)
      // console.log(this.camiones)
      for (const camion in this.camiones) {
        data = {}
        data.text = this.camiones[camion].cam_basis
        data.value = this.camiones[camion].cam_basis
        camionObject.push(data)
      }
      this.totalRows = this.items.length
      return camionObject
    }
    // itemsComputed () {
    //   const registroObject = []
    //   for (const transportes in this.transportesNoAsignados) {
    //     const registro = {}
    //     registro.conductor = this.transportesNoAsignados[transportes].conductor
    //     registro.camion = this.transportesNoAsignados[transportes].nro_cam
    //     registro.transporte = this.transportesNoAsignados[transportes].transporte
    //     registroObject.push(registro)
    //   }
    //   this.item = []
    //   return registroObject
    // }
  }
}
