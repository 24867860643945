import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    siniestros: [],
    estados: []
  },
  mutations: {
    set_accion_siniestros (state, siniestros) {
      state.siniestros = siniestros
    },
    set_accion_estados (state, estados) {
      state.estados = estados
    }
  },
  actions: {
    async getSiniestros ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/siniestro`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getSiniestros')
          // console.log(response.data)
          commit('set_accion_siniestros', response.data)
          return true
        } else {
          console.log('por else getSiniestros')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getSiniestros')
        // router.push({ path: '/' })
        return false
      }
    },
    async getSiniestrosFilter ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/siniestro/filter`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getSiniestrosFilter')
          // console.log(response.data)
          commit('set_accion_siniestros', response.data)
          return true
        } else {
          console.log('por else getSiniestrosFilter')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getSiniestrosFilter')
        // router.push({ path: '/' })
        return false
      }
    },
    async updateEstadoSiniestro ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/siniestro/updateSiniestro?estado=${data.estado}&id_siniestro=${data.id_siniestro}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateEstadoSiniestro')
          // console.log(response)
          return response
        } else {
          console.log('por else updateEstadoSiniestro')
          return response
        }
      } catch (err) {
        // console.error(err)
        console.log('por catch updateEstadoSiniestro')
        // router.push({ path: '/' })
        return err
      }
    },
    async updateSiniestro ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/siniestro/updateSiniestro`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateSiniestro')
          // console.log(response)
          return response
        } else {
          console.log('por else updateSiniestro')
          return response
        }
      } catch (err) {
        // console.error(err)
        console.log('por catch updateSiniestro')
        // router.push({ path: '/' })
        return err
      }
    },
    async deleteSiniestro ({ commit, dispatch, state }, idSiniestro) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/vps/siniestro?id_siniestro=${idSiniestro}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) {
          console.log('success stuff api deleteSiniestro')
          return response
        } else {
          console.log('por else deleteSiniestro')
          return response
        }
      } catch (err) {
        console.log('por catch deleteSiniestro')
        return err
      }
    },
    async getEstadosSiniestros ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/siniestro/estados`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) {
          console.log('success stuff api getEstadosSiniestros')
          commit('set_accion_estados', response.data)
          return response
        } else {
          console.log('por else getEstadosSiniestros')
          return response
        }
      } catch (err) {
        console.log('por catch getEstadosSiniestros')
        return err
      }
    }
  },
  getters: {
  }
}
