import { render, staticRenderFns } from "@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/Editar_field/Editar_materiales/Editar_materiales.html?vue&type=template&id=c38e1b26&scoped=true&"
import script from "@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/Editar_field/Editar_materiales/Editar_materiales.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/Editar_field/Editar_materiales/Editar_materiales.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/Editar_field/Editar_materiales/Editar_materiales.css?vue&type=style&index=0&id=c38e1b26&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c38e1b26",
  null
  
)

export default component.exports