import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
import ReasignacionTransporte from '@/components/Layout/Menu/Reasignacion_transporte/ReasignacionTransporte.vue'
import CargasNoAsignadas from '@/components/Layout/Menu/AdministradorFlota/Cargas_no_asignadas/CargasNoAsignadas.vue'
import ImagenesView from '@/components/Layout/Menu/Common/Imagenes/ImagenesView.vue'
import FiltroAdminFlota from '@/components/Layout/Menu/Filtros/Filtro_AdminFlota/FiltroAdminFlota.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AdministradorFlota',
  components: {
    ReasignacionTransporte,
    CargasNoAsignadas,
    ImagenesView,
    FiltroAdminFlota
    // BarChart
  },
  props: {
    flagUpdateCargasNoAsignadas: null,
    flagUpdate: null
  },
  data: function () {
    return {
      fields: [
        {
          key: 'reasignacion',
          label: 'Reasignacion',
          sortable: false
        },
        {
          key: 'nro_cam',
          label: 'Nro Cam',
          sortable: false
        },
        {
          key: 'trans1',
          label: 'Vuelta 1',
          sortable: false
        },
        {
          key: 'trans2',
          label: 'Vuelta 2',
          sortable: false
        },
        {
          key: 'trans3',
          label: 'Vuelta 3',
          sortable: false
        },
        {
          key: 'trans4',
          label: 'Vuelta 4',
          sortable: false
        },
        {
          key: 'trans5',
          label: 'Vuelta 5',
          sortable: false
        },
        {
          key: 'conductor',
          label: 'Conductor',
          sortable: false,
          // Variant applies to the whole column, including the header and footer
          variant: ''
        },
        {
          key: 'patente',
          label: 'Patente',
          sortable: false,
          // Variant applies to the whole column, including the header and footer
          variant: ''
        },
        {
          key: 'cliente',
          label: 'Cliente',
          sortable: false,
          // Variant applies to the whole column, including the header and footer
          variant: ''
        },
        {
          key: 'admin',
          label: 'Admin Flota',
          sortable: false,
          // Variant applies to the whole column, including the header and footer
          variant: ''
        },
        {
          key: 'imagenes',
          label: 'Imagenes',
          sortable: false,
          // Variant applies to the whole column, including the header and footer
          variant: ''
        }
      ],
      items: [
        // { isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        // { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        // { isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        // { isActive: true, nro_ca.: 39, trans1: 'trans1', trans2: 'trans2', trans3: 'trans3', trans4: 'trans4', trans5: 'trans5', conductor: 'liam', ayudante: 'luisa', patente: 'patente' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      editarModal: {
        id: 'editar-modal',
        title: 'Editar',
        content: ''
      },
      imagenesModal: {
        id: 'imagenes-modal',
        title: 'Imagenes',
        content: ''
      },
      KPITransportes: {
        en_ruta: 0,
        entregadas: 0,
        pendientes_salir: 0,
        anuladas: 0,
        sin_conductor: 0,
        sin_carga: 0,
        carga_no_asignada: 0
      },
      listaTransportes: [],
      listaCamionConTransporte: [],
      listaStatusTransportes: [],
      contadorCamionSinCarga: 0,
      listaTransportesPorVueltas: [],
      transportesPorVueltas1: [],
      transportesPorVueltas2: [],
      transportesPorVueltas3: [],
      transportesPorVueltas4: [],
      transportesPorVueltas5: [],
      dataFiltroReporte: {},
      filtroEstadoEnable: true,
      filtroCamionEnable: true,
      filtroConductorEnable: true,
      filtroClienteEnable: true,
      periodoFiltro: {}
      // flagUpdate: false
    }
  },
  watch: {
    dataFiltroReporte: function (val) {
      console.log('observador dataFiltroReporte')
      console.log(this.dataFiltroReporte)
      this.items = []
      this.listaTransportes = []
      this.listaStatusTransportes = []
      this.transportesNoAsignados.length = 0
      this.listaTransportesPorVueltas = []
      this.transportesPorVueltas1 = []
      this.transportesPorVueltas2 = []
      this.transportesPorVueltas3 = []
      this.transportesPorVueltas4 = []
      this.transportesPorVueltas5 = []
      this.KPITransportes = {
        en_ruta: 0,
        entregadas: 0,
        pendientes_salir: 0,
        anuladas: 0,
        sin_conductor: 0,
        sin_carga: 0
      }
      const periodo = {}
      periodo.fechaActualPrimeraHora = this.dataFiltroReporte.fechaDesde.replace(' ', 'T') + '.000+00:00'
      periodo.fechaActualUltimaHora = this.dataFiltroReporte.fechaHasta.replace(' ', 'T') + '.000+00:00'
      console.log(periodo)
      this.periodoFiltro = periodo
      this.init(periodo)
      this.totalRows = this.items.length
    },
    KPITransportes: function (val) {
      console.log('entra en observador KPITransportes')
      if (this.KPITransportes !== '') {
        // console.log(this.KPITransportes)
        // this.$emit('dataKPITransportes', this.KPITransportes)
      }
      // this.KPITransportes = ''
    },
    flagUpdate: function (val) {
      console.log('entra en observador flagUpdate')
      if (this.flagUpdate) {
        this.items = []
        this.listaTransportes = []
        this.listaStatusTransportes = []
        this.transportesNoAsignados.length = 0
        this.listaTransportesPorVueltas = []
        this.transportesPorVueltas1 = []
        this.transportesPorVueltas2 = []
        this.transportesPorVueltas3 = []
        this.transportesPorVueltas4 = []
        this.transportesPorVueltas5 = []
        this.KPITransportes = {
          en_ruta: 0,
          entregadas: 0,
          pendientes_salir: 0,
          anuladas: 0,
          sin_conductor: 0,
          sin_carga: 0,
          carga_no_asignada: 0
        }
        const date = new Date()
        const fechaActualPrimeraHora = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')}T${
                '00'}:${
                  '00'}:${
                    '00'}.000+00:00`
        const fechaActualUltimaHora = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')}T${
                '23'}:${
                  '59'}:${
                    '59'}.000+00:00`
        console.log(fechaActualPrimeraHora)
        console.log(fechaActualUltimaHora)
        const periodo = {}
        periodo.fechaActualPrimeraHora = fechaActualPrimeraHora
        periodo.fechaActualUltimaHora = fechaActualUltimaHora
        this.init(periodo)
        this.hideModalmodalEditar()
        // this.flagUpdate = false
      }
    },
    items: function (val) {
      console.log('entra en observador items')
      this.totalRows = this.items.length
      console.log(this.items)
    }
    // flagUpdateCargasNoAsignadas: function (val) {
    //   this.items = []
    //   this.listaTransportes = []
    //   this.listaStatusTransportes = []
    //   // this.transportesNoAsignados.length = 0
    //   this.KPITransportes = {
    //     en_ruta: 0,
    //     entregadas: 0,
    //     pendientes_salir: 0,
    //     anuladas: 0,
    //     sin_conductor: 0,
    //     sin_carga: 0
    //   }
    //   this.init()
    //   console.log('entra en observador flagUpdateCargasNoAsignadas')
    //   this.totalRows = this.items.length
    //   console.log(this.items)
    // }
  },
  mounted () {
    this.items = []
    this.listaTransportes = []
    this.listaStatusTransportes = []
    // this.transportesNoAsignados.length = 0
    this.KPITransportes = {
      en_ruta: 0,
      entregadas: 0,
      pendientes_salir: 0,
      anuladas: 0,
      sin_conductor: 0,
      sin_carga: 0,
      carga_no_asignada: 0
    }
    const date = new Date()
    const fechaActualPrimeraHora = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')}T${
            '00'}:${
              '00'}:${
                '00'}.000+00:00`
    const fechaActualUltimaHora = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')}T${
            '23'}:${
              '59'}:${
                '59'}.000+00:00`
    console.log(fechaActualPrimeraHora)
    console.log(fechaActualUltimaHora)
    const periodo = {}
    periodo.fechaActualPrimeraHora = fechaActualPrimeraHora
    periodo.fechaActualUltimaHora = fechaActualUltimaHora
    this.init(periodo)
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    hideModalmodalEditar () {
      this.$refs[this.editarModal.id].hide()
    },
    hideModalmodalImagenes () {
      this.$refs[this.imagenesModal.id].hide()
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants')) {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'transportes')
      XLSX.writeFile(wb, 'transportes.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    editar (item, index, button) {
      // this.editarModal.title = `Row index: ${index} `
      this.editarModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editarModal.id, button)
    },
    imagenes (item, index, button) {
      this.imagenesModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.imagenesModal.id, button)
    },
    getCamionAnuladas () {
      console.log('getCamionAnuladas')
    },
    getCargaSinConductor () {
      console.log('getCargaSinConductor')
      for (const camiones in this.items) {
        if (this.items[camiones].conductor === '' || this.items[camiones].conductor === 'zz_SinConductor' || this.items[camiones].conductor === null || this.items[camiones].conductor === undefined) {
          this.KPITransportes.sin_conductor = this.KPITransportes.sin_conductor + 1
        }
      }
      this.$emit('dataKPITransportes', this.KPITransportes)
    },
    getCamionSinCarga () {
      console.log('getCamionSinCarga')
      // console.log(this.items)
      const unicos = this.listaCamionConTransporte.filter((valor, indice) => {
        return this.listaCamionConTransporte.indexOf(valor) === indice
      })
      this.KPITransportes.sin_carga = this.camiones.length - unicos.length
      this.$emit('dataKPITransportes', this.KPITransportes)
    },
    getCargaNoAsignada () {
      const nroCargasNoAsignadas = this.transportesNoAsignados.length
      this.KPITransportes.carga_no_asignada = nroCargasNoAsignadas
      this.$emit('dataKPITransportes', this.KPITransportes)
    },
    getStatus () {
      for (const item in this.items) {
        // console.log(item)
        if (this.items[item].conductor !== undefined) {
          // console.log('item:')
          // console.log(this.items[item])
          switch (this.items[item].estado1) {
            case 'pendiente a salir':
              this.items[item]._cellVariants.trans1 = 'info'
              this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
              this.$emit('dataKPITransportes', this.KPITransportes)
              break
            case 'en ruta':
              this.items[item]._cellVariants.trans1 = 'warning'
              this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
              this.$emit('dataKPITransportes', this.KPITransportes)
              break
            case 'entregada':
              this.items[item]._cellVariants.trans1 = 'success'
              this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
              this.$emit('dataKPITransportes', this.KPITransportes)
              break
            case 'no realizado':
              break
          }
          switch (this.items[item].estado2) {
            case 'pendiente a salir':
              this.items[item]._cellVariants.trans2 = 'info'
              this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
              break
            case 'en ruta':
              this.items[item]._cellVariants.trans2 = 'warning'
              this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
              break
            case 'entregada':
              this.items[item]._cellVariants.trans2 = 'success'
              this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
              break
            case 'no realizado':
              break
          }
          switch (this.items[item].estado3) {
            case 'pendiente a salir':
              this.items[item]._cellVariants.trans3 = 'info'
              this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
              break
            case 'en ruta':
              this.items[item]._cellVariants.trans3 = 'warning'
              this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
              break
            case 'entregada':
              this.items[item]._cellVariants.trans3 = 'success'
              this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
              break
            case 'no realizado':
              break
          }
          switch (this.items[item].estado4) {
            case 'pendiente a salir':
              this.items[item]._cellVariants.trans4 = 'info'
              this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
              break
            case 'en ruta':
              this.items[item]._cellVariants.trans4 = 'warning'
              this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
              break
            case 'entregada':
              this.items[item]._cellVariants.trans4 = 'success'
              this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
              break
            case 'no realizado':
              break
          }
          switch (this.items[item].estado5) {
            case 'pendiente a salir':
              this.items[item]._cellVariants.trans5 = 'info'
              this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
              break
            case 'en ruta':
              this.items[item]._cellVariants.trans5 = 'warning'
              this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
              break
            case 'entregada':
              this.items[item]._cellVariants.trans5 = 'success'
              this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
              break
            case 'no realizado':
              break
          }
        }
      }
      const unicos = this.listaTransportes.filter((valor, indice) => {
        return this.listaTransportes.indexOf(valor) === indice
      })
      this.listaTransportes = unicos
      for (const transportes in this.listaTransportes) {
        this.listaTransportesStore.push(this.listaTransportes[transportes])
      }
      // for (const transportes in unicos) {
      //   this.getCountActivities(unicos[transportes]).then((res) => {
      //     this.listaStatusTransportes.push(res)
      //     for (const item in this.items) {
      //       console.log(item)
      //       if (this.items[item].conductor !== undefined) {
      //         console.log('item:')
      //         console.log(this.items[item])
      //         switch (this.items[item].estado1) {
      //           case 'pendiente a salir':
      //             this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
      //             break
      //           case 'en ruta':
      //             this.items[item]._cellVariants.trans1 = 'warning'
      //             this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
      //             // this.$emit('dataKPITransportes', this.KPITransportes)
      //             break
      //           case 'entregada':
      //             this.items[item]._cellVariants.trans1 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //           case 'no realizado':
      //             break
      //         }
      //         switch (this.items[item].estado2) {
      //           case 'pendiente a salir':
      //             this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
      //             break
      //           case 'en ruta':
      //             this.items[item]._cellVariants.trans1 = 'warning'
      //             this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
      //             break
      //           case 'entregada':
      //             this.items[item]._cellVariants.trans1 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //           case 'no realizado':
      //             break
      //         }
      //       }
      //       if (this.items[item].trans1 === res.transporte) {
      //         switch (res.status) {
      //           case 0:
      //             this.items[item]._cellVariants.trans1 = ''
      //             if (this.items[item].estado1 === 'no realizado') {
      //               this.items[item]._cellVariants.trans1 = 'danger'
      //               this.KPITransportes.anuladas = this.KPITransportes.anuladas + 1
      //             } else {
      //               this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
      //             }
      //             break
      //           case 1:
      //             if (this.items[item].estado1 === 'no realizado') {
      //               this.items[item]._cellVariants.trans1 = 'danger'
      //               this.KPITransportes.anuladas = this.KPITransportes.anuladas + 1
      //             } else {
      //               this.items[item]._cellVariants.trans1 = 'warning'
      //               this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
      //             }
      //             break
      //           case 2:
      //             this.items[item]._cellVariants.trans1 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //           default:
      //             this.items[item]._cellVariants.trans1 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //         }
      //         console.log(this.items[item].estado1)
      //         this.$emit('dataKPITransportes', this.KPITransportes)
      //       } else if (this.items[item].trans2 === res.transporte) {
      //         switch (res.status) {
      //           case 0:
      //             this.items[item]._cellVariants.trans2 = ''
      //             if (this.items[item].estado2 === 'no realizado') {
      //               this.items[item]._cellVariants.trans2 = 'danger'
      //               this.KPITransportes.anuladas = this.KPITransportes.anuladas + 1
      //             } else {
      //               this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
      //             }
      //             break
      //           case 1:
      //             if (this.items[item].estado2 === 'no realizado') {
      //               this.items[item]._cellVariants.trans2 = 'danger'
      //               this.KPITransportes.anuladas = this.KPITransportes.anuladas + 1
      //             } else {
      //               this.items[item]._cellVariants.trans2 = 'warning'
      //               this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
      //             }
      //             break
      //           case 2:
      //             this.items[item]._cellVariants.trans2 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //           default:
      //             this.items[item]._cellVariants.trans2 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //         }
      //         this.$emit('dataKPITransportes', this.KPITransportes)
      //       } else if (this.items[item].trans3 === res.transporte) {
      //         switch (res.status) {
      //           case 0:
      //             this.items[item]._cellVariants.trans3 = ''
      //             if (this.items[item].estado3 === 'no realizado') {
      //               this.items[item]._cellVariants.trans3 = 'danger'
      //               this.KPITransportes.anuladas = this.KPITransportes.anuladas + 1
      //             } else {
      //               this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
      //             }
      //             break
      //           case 1:
      //             this.items[item]._cellVariants.trans3 = 'warning'
      //             if (this.items[item].estado3 === 'no realizado') {
      //               this.items[item]._cellVariants.trans3 = 'danger'
      //             }
      //             this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
      //             break
      //           case 2:
      //             this.items[item]._cellVariants.trans3 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //           default:
      //             this.items[item]._cellVariants.trans3 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //         }
      //         this.$emit('dataKPITransportes', this.KPITransportes)
      //       } else if (this.items[item].trans4 === res.transporte) {
      //         switch (res.status) {
      //           case 0:
      //             this.items[item]._cellVariants.trans4 = ''
      //             if (this.items[item].estado4 === 'no realizado') {
      //               this.items[item]._cellVariants.trans4 = 'danger'
      //               this.KPITransportes.anuladas = this.KPITransportes.anuladas + 1
      //             } else {
      //               this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
      //             }
      //             break
      //           case 1:
      //             this.items[item]._cellVariants.trans4 = 'warning'
      //             if (this.items[item].estado4 === 'no realizado') {
      //               this.items[item]._cellVariants.trans4 = 'danger'
      //             }
      //             this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
      //             break
      //           case 2:
      //             this.items[item]._cellVariants.trans4 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //           default:
      //             this.items[item]._cellVariants.trans4 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //         }
      //         this.$emit('dataKPITransportes', this.KPITransportes)
      //       } else if (this.items[item].trans5 === res.transporte) {
      //         switch (res.status) {
      //           case 0:
      //             this.items[item]._cellVariants.trans5 = ''
      //             if (this.items[item].estado5 === 'no realizado') {
      //               this.items[item]._cellVariants.trans5 = 'danger'
      //               this.KPITransportes.anuladas = this.KPITransportes.anuladas + 1
      //             } else {
      //               this.KPITransportes.pendientes_salir = this.KPITransportes.pendientes_salir + 1
      //             }
      //             break
      //           case 1:
      //             this.items[item]._cellVariants.trans5 = 'warning'
      //             if (this.items[item].estado5 === 'no realizado') {
      //               this.items[item]._cellVariants.trans5 = 'danger'
      //             }
      //             this.KPITransportes.en_ruta = this.KPITransportes.en_ruta + 1
      //             break
      //           case 2:
      //             this.items[item]._cellVariants.trans5 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //           default:
      //             this.items[item]._cellVariants.trans5 = 'success'
      //             this.KPITransportes.entregadas = this.KPITransportes.entregadas + 1
      //             break
      //         }
      //         // this.item = itemsAux
      //         this.$emit('dataKPITransportes', this.KPITransportes)
      //       }
      //       this.totalRows = this.items.length
      //     }
      //   })
      // }
      // for (const transportes in this.listaTransportes) {
      //   this.listaTransportesStore.push(this.listaTransportes[transportes])
      // }
      // console.log(this.items)
    },
    async getCountActivities (transporteId) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/vueltas/countActivities`,
          data: {
            transporte: transporteId
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log(response.data)
          return response.data
        } else {
          console.log('por else ayudantes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch ayudantes')
        router.push({ path: '/' })
        return false
      }
    },
    async getTransportesPorCambasis (datos) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/vueltas/getTransportesCamBasis` + '?cam_basis=' + datos.cam_basis + '&vuelta=' + datos.vuelta,
          data: {
            fechaDesde: datos.fechaDesde,
            fechaHasta: datos.fechaHasta
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log(response.data)
          return response.data
        } else {
          console.log('por else getTransportesCamBasis')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getTransportesCamBasis')
        router.push({ path: '/' })
        return false
      }
    },
    Actualizar () {
      this.items = []
      this.listaTransportes = []
      this.listaStatusTransportes = []
      // this.transportesNoAsignados.length = 0
      this.KPITransportes = {
        en_ruta: 0,
        entregadas: 0,
        pendientes_salir: 0,
        anuladas: 0,
        sin_conductor: 0,
        sin_carga: 0,
        carga_no_asignada: 0
      }
      const date = new Date()
      const fechaActualPrimeraHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')}T${
              '00'}:${
                '00'}:${
                  '00'}.000+00:00`
      const fechaActualUltimaHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')}T${
              '23'}:${
                '59'}:${
                  '59'}.000+00:00`
      console.log(fechaActualPrimeraHora)
      console.log(fechaActualUltimaHora)
      const periodo = {}
      periodo.fechaActualPrimeraHora = fechaActualPrimeraHora
      periodo.fechaActualUltimaHora = fechaActualUltimaHora
      this.init(periodo)
    },
    init (periodos) {
      this.conductores = []
      this.getConductores().then((res) => {
        console.log('cargado conductores')
      })
      this.getAyudantes().then((res) => {
        console.log('cargado ayudantes')
      })
      this.getCamiones().then((res) => {
        this.transportesNoAsignados.length = 0
        console.log('cargado camiones')
        // const date = new Date()
        // const fechaActualPrimeraHora = `${
        //   date.getFullYear().toString().padStart(4, '0')}-${
        //     (date.getMonth() + 1).toString().padStart(2, '0')}-${
        //       date.getDate().toString().padStart(2, '0')}T${
        //         '00'}:${
        //           '00'}:${
        //             '00'}.000+00:00`
        // const fechaActualUltimaHora = `${
        //   date.getFullYear().toString().padStart(4, '0')}-${
        //     (date.getMonth() + 1).toString().padStart(2, '0')}-${
        //       date.getDate().toString().padStart(2, '0')}T${
        //         '23'}:${
        //           '59'}:${
        //             '59'}.000+00:00`
        // console.log(fechaActualPrimeraHora)
        // console.log(fechaActualUltimaHora)
        // const periodo = periodos
        // periodo.fechaActualPrimeraHora = fechaActualPrimeraHora
        // periodo.fechaActualUltimaHora = fechaActualUltimaHora
        this.getTransportes(periodos).then((res) => {
          console.log('cargado transporte')
          const camiones = this.camiones
          for (const camion in camiones) {
            const vueltas = {}
            vueltas.nro_cam = camiones[camion].cam_basis
            vueltas.patente = camiones[camion].matricula
            vueltas.admin = camiones[camion].administrador.nombre
            for (const transporte in this.transportes) {
              // if (this.transportes[transporte].conductor === 'zz_SinConductor') {
              //   this.transportesNoAsignados.push(this.transportes[transporte])
              // }
              this.listaTransportes.push(this.transportes[transporte].transporte)
              if (camiones[camion].cam_basis === this.transportes[transporte].cam_basis.cam_basis) {
                this.listaCamionConTransporte.push(vueltas.nro_cam)
                // console.log('--------------------')
                vueltas['trans' + this.transportes[transporte].vuelta] = this.transportes[transporte].transporte
                vueltas['idtrans' + this.transportes[transporte].vuelta] = this.transportes[transporte].id
                vueltas['conductor' + this.transportes[transporte].vuelta] = this.transportes[transporte].conductor
                vueltas['ucase' + this.transportes[transporte].vuelta] = this.transportes[transporte].ucase | 0
                vueltas['cliente' + this.transportes[transporte].vuelta] = this.transportes[transporte].cliente
                // vueltas['fecha' + this.transportes[transporte].vuelta] = this.transportes[transporte].fecha.substring(0, 10)
                vueltas['fecha' + this.transportes[transporte].vuelta] = this.transportes[transporte].fecha.substring(0, 19).replace('T', ' ')
                vueltas.conductor = this.transportes[transporte].conductor
                vueltas.cliente = this.transportes[transporte].cliente
                vueltas['estado' + this.transportes[transporte].vuelta] = this.transportes[transporte].estado
                vueltas._cellVariants = { trans1: '', trans2: '', trans3: '', trans4: '', trans5: '' }
                // vueltas.cantidadTransportes = this.cantidadTransportesPorVuelta(vueltas.nro_cam, this.transportes[transporte].vuelta)
                // crea arreglo con lista de transportes por cada vuelta, esto para filtrar en listas de editar y reasignacion de transportes (para que no se pueda reasiganar un transporte a un camion que ya tiene un transporte en esa vuelta, ejemplo pasar un transporte 1 a un camion que ya lo posea)
                const transporteVuelta = {}
                switch (this.transportes[transporte].vuelta) {
                  case 1:
                    transporteVuelta.cam_basis = vueltas.nro_cam
                    transporteVuelta.transporte = this.transportes[transporte].transporte
                    this.transportesPorVueltas1.push(transporte)
                    break
                  case 2:
                    transporteVuelta.cam_basis = vueltas.nro_cam
                    transporteVuelta.transporte = this.transportes[transporte].transporte
                    this.transportesPorVueltas2.push(transporteVuelta)
                    break
                  case 3:
                    transporteVuelta.cam_basis = vueltas.nro_cam
                    transporteVuelta.transporte = this.transportes[transporte].transporte
                    this.transportesPorVueltas3.push(transporteVuelta)
                    break
                  case 4:
                    transporteVuelta.cam_basis = vueltas.nro_cam
                    transporteVuelta.transporte = this.transportes[transporte].transporte
                    this.transportesPorVueltas4.push(transporteVuelta)
                    break
                  case 5:
                    transporteVuelta.cam_basis = vueltas.nro_cam
                    transporteVuelta.transporte = this.transportes[transporte].transporte
                    this.transportesPorVueltas5.push(transporteVuelta)
                    break
                }
                if (camiones[camion].cam_basis === 5999) {
                  const vueltaNoAsignada = {}
                  vueltaNoAsignada.nro_cam = camiones[camion].cam_basis
                  vueltaNoAsignada.patente = camiones[camion].matricula
                  vueltaNoAsignada.admin = camiones[camion].administrador.nombre
                  vueltaNoAsignada['trans' + this.transportes[transporte].vuelta] = this.transportes[transporte].transporte
                  vueltaNoAsignada['ucase' + this.transportes[transporte].vuelta] = this.transportes[transporte].ucase
                  vueltaNoAsignada['fecha' + this.transportes[transporte].vuelta] = this.transportes[transporte].fecha.substring(0, 10)
                  vueltaNoAsignada.conductor = this.transportes[transporte].conductor
                  vueltaNoAsignada.cliente = this.transportes[transporte].cliente
                  vueltaNoAsignada['estado' + this.transportes[transporte].vuelta] = this.transportes[transporte].estado
                  vueltaNoAsignada._cellVariants = { trans1: '', trans2: '', trans3: '', trans4: '', trans5: '' }
                  this.transportesNoAsignados.push(vueltaNoAsignada)
                  // console.log('es 1 5999: ')
                  // console.log(this.transportes[transporte].transporte)
                  // console.log(vueltaNoAsignada)
                  // break
                }
                // vueltas._cellVariants.trans2 = ''
                // vueltas._cellVariants.trans3 = ''
                // vueltas._cellVariants.trans4 = ''
                // vueltas._cellVariants.trans5 = ''
                // vueltas._cellVariants = { trans1: 'danger' }
                // this.getAyudantes(this.transportes[transporte].transporte).then((res) => {
                //   vueltas['status' + this.transportes[transporte].vuelta] = res
                //   console.log(res)
                // })
                // console.log(this.transportes[transporte])
              }
            }
            // console.log('vueltas')
            // console.log(vueltas)
            if (camiones[camion].cam_basis !== 5999) {
              this.items.push(vueltas)
            }
            // this.items.push(vueltas)
          }
          // this.items = this.transportes
          this.listaTransportesPorVueltas.push(this.transportesPorVueltas1)
          this.listaTransportesPorVueltas.push(this.transportesPorVueltas2)
          this.listaTransportesPorVueltas.push(this.transportesPorVueltas3)
          this.listaTransportesPorVueltas.push(this.transportesPorVueltas4)
          this.listaTransportesPorVueltas.push(this.transportesPorVueltas5)
          // Se aplican filtros y se calculan los kpi
          this.Filtros()
          this.getCamionSinCarga()
          this.getStatus()
          this.getCargaSinConductor()
          for (const noAsignador in this.items) {
            if (this.items[noAsignador].conductor === 'zz_SinConductor' && this.items[noAsignador].conductor) {
              // const noAsignadoObject = {}
              // noAsignadoObject.conductor = this.items[noAsignador].conductor
              // noAsignadoObject.conductor = this.items[noAsignador].nro_cam
              // noAsignadoObject.transporte = this.items[noAsignador].transporte
              // noAsignadoObject.transporte = this.items[noAsignador].transporte
              this.transportesNoAsignados.push(this.items[noAsignador])
            }
          }
          this.getCargaNoAsignada()
        })
      })
      this.totalRows = this.items.length
      // this.$emit('dataKPITransportes', this.KPITransportes)
    },
    Filtros () {
      if (this.dataFiltroReporte.conductor !== undefined) {
        // let itemsHaFlitrar = this.items
        // this.items = []
        console.log('tiene filtro de conductor')
        this.items = this.items.filter(conductor => conductor.conductor === this.dataFiltroReporte.conductor)
        // this.items = itemsHaFlitrar
      }
      if (this.dataFiltroReporte.cliente !== undefined) {
        console.log('tiene filtro de cliente')
        this.items = this.items.filter(cliente => cliente.cliente === this.dataFiltroReporte.cliente)
      }
      if (this.dataFiltroReporte.camion !== undefined) {
        this.items = this.items.filter(camion => camion.nro_cam === this.dataFiltroReporte.camion)
        console.log('tiene filtro de camion')
      }
    },
    agregarCantidadTransportesPorVuelta () {
      console.log(this.items)
      // for (this.items) {
      // }
    },
    cantidadTransportesPorVuelta (camBasis, vuelta) {
      let fechaActualPrimeraHora = ''
      let fechaActualUltimaHora = ''
      if (this.periodoFiltro === {}) {
        const date = new Date()
        fechaActualPrimeraHora = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')} ${
                '00'}:${
                  '00'}:${
                    '00'}`
        fechaActualUltimaHora = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')} ${
                '23'}:${
                  '59'}:${
                    '59'}`
      } else {
        fechaActualPrimeraHora = this.periodoFiltro.fechaActualPrimeraHora
        fechaActualUltimaHora = this.periodoFiltro.fechaActualUltimaHora
      }
      // fechaActualPrimeraHora = '2022-09-01 00:00:00'
      // fechaActualUltimaHora = '2022-09-01 23:00:00'
      console.log(fechaActualPrimeraHora)
      console.log(fechaActualUltimaHora)
      const datosTransporte = {}
      datosTransporte.fechaDesde = fechaActualPrimeraHora
      datosTransporte.fechaHasta = fechaActualUltimaHora
      datosTransporte.cam_basis = camBasis
      datosTransporte.vuelta = vuelta
      console.log(datosTransporte)
      this.getTransportesPorCambasis(datosTransporte).then((res) => {
        console.log('cantidadTransportesPorVuelta cargado')
        console.log(res.length)
        return res.length
      })
    },
    infoVuelta (datos, vuelta) {
      console.log('info vuelta')
      let fechaActualPrimeraHora = ''
      let fechaActualUltimaHora = ''
      if (this.periodoFiltro.fechaActualPrimeraHora === undefined || this.periodoFiltro.fechaActualUltimaHora === undefined) {
        console.log('sin filtro')
        const date = new Date()
        fechaActualPrimeraHora = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')} ${
                '00'}:${
                  '00'}:${
                    '00'}`
        fechaActualUltimaHora = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')} ${
                '23'}:${
                  '59'}:${
                    '59'}`
      } else {
        console.log('con filtro')
        console.log(this.periodoFiltro)
        fechaActualPrimeraHora = this.periodoFiltro.fechaActualPrimeraHora
        fechaActualUltimaHora = this.periodoFiltro.fechaActualUltimaHora
      }
      console.log(fechaActualPrimeraHora)
      console.log(fechaActualUltimaHora)
      const datosTransporte = {}
      datosTransporte.fechaDesde = fechaActualPrimeraHora
      datosTransporte.fechaHasta = fechaActualUltimaHora
      datosTransporte.cam_basis = datos.nro_cam
      datosTransporte.vuelta = vuelta
      console.log(datos)
      console.log(datosTransporte)
      this.getTransportesPorCambasis(datosTransporte).then((res) => {
        console.log(res)
        for (const transporte in res) {
          let variant = ''
          switch (res[transporte].estado) {
            case 'pendiente a salir':
              variant = ''
              break
            case 'en ruta':
              variant = 'warning'
              break
            case 'entregada':
              variant = 'success'
              break
            case 'no realizado':
              variant = 'danger'
              break
          }
          this.$bvToast.toast(`Transporte: ${res[transporte].transporte}, Conductor: ${res[transporte].conductor}`, {
            title: `Estado: ${res[transporte].estado} Vuelta: ${res[transporte].vuelta}`,
            autoHideDelay: 5000,
            appendToast: true,
            variant: variant
          })
        }
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
