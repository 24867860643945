import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import EditarCamiones from '@/components/Layout/Menu/Administracion/Administracion_camiones/Editar_camiones/EditarCamiones.vue'
// import CrearCamiones from '@/components/Layout/Menu/Administracion/Administracion_camiones/Crear_camiones/CrearCamiones.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AdministracionConductores',
  components: {
    // EditarCamiones,
    // CrearCamiones
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: false
        },
        {
          key: 'rut',
          label: 'Rut',
          sortable: false
        },
        {
          key: 'full_name',
          label: 'Nombre',
          sortable: true
        },
        {
          key: 'person_id',
          label: 'Id Buk',
          sortable: false
        },
        {
          key: 'role_name',
          label: 'Rol',
          sortable: false
        }
      ],
      itemsAux: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      emitEditAyudantes: '',
      editarAyudante: {
        id: 'editarAyudante-modal',
        title: 'Editar',
        content: ''
      },
      CrearAyudante: {
        id: 'crearAyudante-modal',
        title: 'Crear',
        content: ''
      },
      ayudanteNombreFilter: [
        {
          value: null,
          text: '- Seleccionar Nombre'
        }
      ],
      selectedConductorNombre: null,
      ayudanteRutFilter: [
        {
          value: null,
          text: '- Seleccionar Rut'
        }
      ],
      selectedConductorRut: null,
      file: null,
      arrayBuffer: null,
      filelist: null,
      arrayRespuesta: [],
      arrayFormat: []
    }
  },
  watch: {
    emitEditAyudante: function (val) {
      console.log('observador emitEditAyudante')
    },
    selectedConductorNombre: function (val) {
      console.log('observador selectedConductorNombre')
      this.items = this.itemsAux
      if (this.selectedConductorNombre != null) {
        this.items = this.items.filter(ayudante => ayudante.full_name === this.selectedConductorNombre)
      }
    },
    selectedConductorRut: function (val) {
      console.log('observador selectedConductorRut')
      this.items = this.itemsAux
      if (this.selectedConductorRut != null) {
        this.items = this.items.filter(ayudante => ayudante.rut === this.selectedConductorRut)
      }
    }
  },
  mounted () {
    this.getConductoresBuk().then((res) => {
      this.items = this.conductoresBuk
      this.totalRows = this.items.length
      for (const p in this.items) {
        this.ayudanteNombreFilter.push({
          value: this.items[p].full_name,
          text: this.items[p].full_name
        })
        this.ayudanteRutFilter.push({
          value: this.items[p].rut,
          text: this.items[p].rut
        })
      }
      this.ayudanteNombreFilter.sort((a, b) => a.text.localeCompare(b.text))
      this.ayudanteRutFilter.sort((a, b) => a.text.localeCompare(b.text))
      this.itemsAux = this.items
    })
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getCamiones', 'createCamiones'
    ]),
    ...mapActions('Patentes', [
      'getPatentes', 'insertPatentes'
    ]),
    ...mapActions('Ayudantes', [
      'getAyudantes', 'insertAyudantes', 'getAyudantesBuk'
    ]),
    ...mapActions('UsuariosBuk', [
      'getConductoresBuk', 'getAyudantesBuk'
    ]),
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    editarAyudanteModal (item, index, button) {
      this.editarAyudante.title = 'Editar Ayudante: ' + item.nombre
      this.editarAyudante.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editarAyudante.id, button)
    },
    crearAyudanteModal (item, index, button) {
      // this.CrearCamion.title = `Row index: ${index}`
      this.CrearAyudante.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.CrearAyudante.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filtroitems () {
      this.items = this.itemsAux
      if (this.selectedConductorNombre !== undefined && this.selectedConductorNombre !== null) {
        console.log('tiene filtro de nombre')
        this.items = this.items.filter(ayudante => ayudante.full_name === this.selectedConductorNombre)
      }
      if (this.selectedConductorRut !== undefined && this.selectedConductorRut !== null) {
        console.log('tiene filtro de rut')
        this.items = this.items.filter(ayudante => ayudante.rut === this.selectedConductorRut)
      }
    },
    hideModaleditarAyudante () {
      this.$refs[this.editarAyudante.id].hide()
    },
    hideModalcrearAyudante () {
      this.$refs[this.editarAyudante.id].hide()
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants')) {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'conductores')
      XLSX.writeFile(wb, 'conductores.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    addfile (event) {
      console.log(event)
      this.file = event.target.files[0]
      const fileReader = new FileReader()
      try {
        fileReader.readAsArrayBuffer(this.file)
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        console.log(workbook)
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        this.arrayRespuesta = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        console.log(this.arrayRespuesta)
        this.filelist = []
        console.log(this.filelist)
        // const arrayFormat = []
        for (const array in this.arrayRespuesta) {
          const aux = {}
          aux.nombre = this.arrayRespuesta[array].nombre
          aux.rut = this.arrayRespuesta[array].rut
          aux.id_buk = this.arrayRespuesta[array].id_buk
          this.arrayFormat.push(aux)
        }
        console.log(this.arrayFormat)
        // this.cargarExcel(arrayFormat)
      }
    },
    cargarExcel (datos) {
      this.insertAyudantes(this.arrayFormat).then((res) => {
        this.items = this.patentes
        this.totalRows = this.items.length
        this.arrayFormat = []
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    },
    // makeToast (variant = null) {
    //   this.$bvToast.toast('Toast body content', {
    //     title: `Variant ${variant || 'default'}`,
    //     variant: variant,
    //     solid: true
    //   })
    // },
    testToast () {
      const toast = {}
      toast.title = 'Carga Exitosa'
      toast.variant = 'success'
      toast.body = ''
      this.makeToast(toast)
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('Patentes', ['patentes']),
    ...mapState('Ayudantes', ['ayudantes']),
    ...mapState('Ayudantes', ['ayudantesBuk']),
    ...mapState('UsuariosBuk', ['conductoresBuk']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
